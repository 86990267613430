import dayjs from 'dayjs';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
const currentDate = dayjs();

export const getTodayDates = () => {
  const dateFrom = currentDate.startOf('day').format(DATE_FORMAT);
  const dateTo = currentDate.subtract(1, 'minute').format(DATE_FORMAT);

  return `${dateFrom}/${dateTo}`;
};

export const getYesterdayDates = () => {
  const dateFrom = currentDate.subtract(1, 'day').startOf('day')
    .format(DATE_FORMAT);
  const dateTo = currentDate.subtract(1, 'day').endOf('day')
    .format(DATE_FORMAT);

  return `${dateFrom}/${dateTo}`;
};

export const getLastDates = (daysAgo: number) => {
  const dateFrom = currentDate
    .subtract(daysAgo - 1, 'day')
    .startOf('day')
    .format(DATE_FORMAT);
  const dateTo = currentDate.subtract(1, 'minute').format(DATE_FORMAT);

  return `${dateFrom}/${dateTo}`;
};
