import React, {ForwardedRef, FunctionComponent, useEffect, useRef} from 'react';

type Props = {
  onIntersect: (data: any) => void;
  payload?: any;
  rootMargin?: string;
  rootRef: ForwardedRef<HTMLDivElement>;
};

export const IntersectionObserve: FunctionComponent<Props> = (props) => {
  const {onIntersect, payload, children, rootMargin, rootRef} = props;

  const screenWidth = window.innerWidth;

  const ref = useRef<null | Element>(null);

  useEffect(() => {
    let observer: null | IntersectionObserver = null;
    if (!rootRef) return;

    if (window) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.intersectionRatio > 0) {
            onIntersect(payload);
          }
        },
        {
          root: typeof rootRef === 'function' ? null : rootRef.current,
          rootMargin,
          threshold: screenWidth < 1612 ? 0.28 : 0.4,
        },
      );

      ref.current && observer.observe(ref.current);
    }

    return () => {
      observer && ref.current && observer.unobserve(ref.current);
    };
  }, [payload]);

  return (
    <>
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
            //@ts-ignore
            ref,
          })
          : child,
      )}
    </>
  );
};
