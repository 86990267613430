import {
  Menu,
  Login,
  NotFound,
  Reports,
  Orders,
  Incidents,
  UserAgreement,
  ProductDetails,
  RolesManagement,
  BranchesManagement,
  OrdersHistory,
  Rating,
  ChangesHistory,
} from '@pages';

export const ROLES = {
  OWNER: 13,
  MANAGER: 31,
  OPERATOR: 29,
};

export const LOGIN_ROUTE = {
  path: '/login',
  exact: true,
  component: Login,
};

export const AGREEMENT_ROUTE = {
  path: '/user-agreement.html',
  exact: true,
  component: UserAgreement,
};

export const PRIVATE_ROUTES = [
  {
    path: '/',
    exact: true,
    component: Orders,
  },
  {
    path: '/orders-history',
    exact: true,
    component: OrdersHistory,
  },
  {
    path: '/branches-management',
    exact: true,
    component: BranchesManagement,
    access: [ROLES.OWNER, ROLES.MANAGER],
  },
  {
    path: '/reports',
    exact: true,
    component: Reports,
    access: [ROLES.OWNER, ROLES.MANAGER],
  },
  {
    path: '/orders',
    exact: true,
    component: Orders,
  },
  {
    path: '/menu',
    exact: true,
    component: Menu,
    access: [ROLES.OWNER, ROLES.MANAGER],
  },
  {
    path: '/menu/:storeId/product/:productId',
    exact: true,
    component: ProductDetails,
    access: [ROLES.OWNER, ROLES.MANAGER],
  },
  {
    path: '/orders/incident/:orderId',
    exact: true,
    component: Incidents,
  },
  {
    path: '/roles-management',
    exact: true,
    component: RolesManagement,
    access: [ROLES.OWNER, ROLES.MANAGER],
  },
  {
    path: '/rating',
    exact: true,
    component: Rating,
  },
  {
    path: '/changes-history',
    exact: true,
    component: ChangesHistory,
  },
  {
    path: '*',
    component: NotFound,
  },
];
