import React, {useEffect} from 'react';
import styles from './page.module.scss';
import {BranchesList} from './ui/branches-list';
import {BranchDetails} from './ui/branch-details';
import {pageMounted, pageUnmounted} from '../model';
import {useUnit} from 'effector-react/scope';

export const BranchesManagement = () => {
  const {handlePageMounted, handlePageUnmounted} = useUnit({
    handlePageMounted: pageMounted,
    handlePageUnmounted: pageUnmounted,
  });

  useEffect(() => {
    handlePageMounted();

    return () => handlePageUnmounted();
  }, []);

  return (
    <div className={styles.Container}>
      <BranchesList />
      <BranchDetails />
    </div>
  );
};
