import {useUnit} from 'effector-react';
import styles from './styles.module.scss';
import * as model from '../../../../model';
import {Icon, Skeleton, Typography} from '@express-24/theseus-ui';
import {useTranslation} from 'react-i18next';

export const StopListCard = () => {
  const {
    handleStopListCardClick,

    stopListProductsCount,

    isStopListOpen,
    isStopListEmpty,
    isStopListPending,
    isStopListAlreadyFetched,
  } = useUnit({
    handleStopListCardClick: model.stopListOpened,

    stopListProductsCount: model.$stopListProductsCount,

    isStopListOpen: model.$isStopListOpen,
    isStopListEmpty: model.$isStopListEmpty,
    isStopListPending: model.$isStopListPending,
    isStopListAlreadyFetched: model.$isStopListAlreadyFetched,
  });
  const {t} = useTranslation();

  if (isStopListEmpty) return null;

  if (isStopListPending && !isStopListAlreadyFetched) {
    return (
      <div className={styles.StopListCardWrapper}>
        <Skeleton height={86}
          radius={12}
          width="auto"
        />
      </div>
    );
  }

  return (
    <div className={styles.StopListCardWrapper}>
      <div className={styles.StopListCard}
        onClick={handleStopListCardClick}
        data-is-selected={isStopListOpen}
      >
        <Icon size={20}
          name="stop"
        />
        <div className={styles.StopListCardContent}>
          <Typography level="text">{t('menu.stop_list')}</Typography>
          <Typography level="sub-text"
            kind="secondary"
          >
            {t('menu.absent_products')}
          </Typography>
        </div>
        <div className={styles.StopListCardCounter}>
          <Typography level="sub-text"
            kind="secondary"
          >
            {stopListProductsCount}
          </Typography>
          <Icon size={16}
            name="chevron-right"
          />
        </div>
      </div>
    </div>
  );
};
