import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './styles.module.scss';
import {Button, Icon, Input, Tab, TabPanel, Typography} from '@express-24/theseus-ui';
import {useUnit} from 'effector-react';
import {
  $activeMenuType,
  $incidentStoreParams,
  $productsForReplacement,
  $searchProductData,
  $searchProductInput,
  $sourceMenuData,
  $subMenu,
  activeMenuTypeUpdated,
  drawerClosed,
  goneToSubMenu,
  searchProductUnmounted,
  subMenuItemClicked,
  searchProductInputUpdated,
} from '../../model';
import {EmptyState} from 'widgets/empty-state';
import {ProductList} from '../product-list';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const DrawerContent = () => {
  const [isSearchInputFocused, setSearchInputFocused] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>();
  const drawerHeaderRef = useRef<HTMLDivElement>(null);
  const sectionsContainerRef = useRef<HTMLDivElement>(null);
  const productCartRef = useRef<HTMLDivElement>(null);

  const {
    sourceMenuData,
    incidentStoreParams,
    activeMenuType,
    productsForReplacement,
    subMenu,
    searchProductInput,
    searchProductData,
    handleDrawerClosed,
    handleSubMenuItemClick,
    handleGoToSubMenu,
    handleSearchProductInput,
    handleSearchProductUnmounted,
    handleActiveMenuTypeUpdated,
  } = useUnit({
    sourceMenuData: $sourceMenuData,
    incidentStoreParams: $incidentStoreParams,
    activeMenuType: $activeMenuType,
    productsForReplacement: $productsForReplacement,
    subMenu: $subMenu,
    searchProductInput: $searchProductInput,
    searchProductData: $searchProductData,
    handleDrawerClosed: drawerClosed,
    handleSubMenuItemClick: subMenuItemClicked,
    handleGoToSubMenu: goneToSubMenu,
    handleSearchProductInput: searchProductInputUpdated,
    handleSearchProductUnmounted: searchProductUnmounted,
    handleActiveMenuTypeUpdated: activeMenuTypeUpdated,
  });
  const {t} = useTranslation();

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.onfocus = () => setSearchInputFocused(true);
    }
  }, []);

  const onGoToProductsClick = () => {
    handleSearchProductUnmounted();
    setSearchInputFocused(false);
  };

  const headerTitleView = useMemo(() => {
    if (isSearchInputFocused) {
      return (
        <button className={styles.GoMenuBack}
          onClick={onGoToProductsClick}
        >
          <Icon name="chevron-left"
            size={16}
          />
          {t('back_to_products')}
        </button>
      );
    }

    if (subMenu) {
      return (
        <button className={styles.GoMenuBack}
          onClick={handleGoToSubMenu}
        >
          <Icon name="chevron-left"
            size={16}
          />
          {t('back_to_categories')}
        </button>
      );
    }

    return (
      <Typography level="h3"
        semibold
      >
        {t('orders.choose_replacement_product')}
      </Typography>
    );
  }, [isSearchInputFocused, subMenu, i18n.language]);

  const searchResultView = useMemo(() => {
    if (!isSearchInputFocused) return;

    if (searchProductData) {
      if (searchProductData.sections.length) {
        return (
          <ProductList
            sourceData={searchProductData}
            isSearchInputFocused={isSearchInputFocused}
            onSectionIntersect={handleActiveMenuTypeUpdated}
            ref={sectionsContainerRef}
            productCartRef={productCartRef}
            drawerHeaderRef={drawerHeaderRef}
          />
        );
      }

      return (
        <div className={styles.NoSearchData}>
          <EmptyState
            illustration="noProducts"
            title={t('orders.product_not_found')}
            description={t('orders.product_not_found_tip')}
          />
        </div>
      );
    }

    return (
      <div className={styles.NoSearchData}>
        <EmptyState illustration="noProducts"
          description={t('orders.enter_product_name')}
        />
      </div>
    );
  }, [isSearchInputFocused, searchProductData, sectionsContainerRef.current, i18n.language]);

  const handleScrollToSection = (id: string) => {
    const sectionsParent = sectionsContainerRef.current;

    if (!sectionsParent) return;

    const sectionElem = sectionsParent.querySelector<HTMLDivElement>(`article[data-id='${id}']`);

    if (!sectionElem) return;

    setTimeout(() => {
      const drawerHeaderHeight = drawerHeaderRef.current ? drawerHeaderRef.current.offsetHeight : 0;
      const marginBetweenDrawerHeaderAndProducts = 20;
      const scrollValue =
        sectionsParent.scrollTop +
        sectionElem.getBoundingClientRect().top -
        (drawerHeaderHeight + marginBetweenDrawerHeaderAndProducts);

      const scrollConfig: ScrollToOptions = {
        top: scrollValue,
        behavior: 'smooth',
      };

      sectionsParent.scrollTo(scrollConfig);
    }, 50);
  };

  const tabsView = useMemo(() => {
    if (isSearchInputFocused) return;
    if (!sourceMenuData) return;

    if (sourceMenuData.isNested) return;

    return (
      <div className={styles.Tabs}>
        <TabPanel filled
          fit="multiline"
          selectSize="small"
          selected={0}
          onSelect={handleScrollToSection}
        >
          {sourceMenuData.sections.map(({name, id, products}) => {
            if (!products.length) return null;

            return (
              <Tab filled
                size="small"
                name={`${id}`}
                key={id}
              >
                {name}
              </Tab>
            );
          })}
        </TabPanel>
      </div>
    );
  }, [isSearchInputFocused, sourceMenuData, activeMenuType]);

  const productContentView = useMemo(() => {
    if (isSearchInputFocused) return;
    if (!sourceMenuData) return;

    if (sourceMenuData.isNested) {
      return (
        <div className={styles.SubMenu}>
          <Typography level="h4"
            semibold
          >
            {t('categories')}
          </Typography>
          {sourceMenuData.sections.map((section) => {
            return (
              <div key={section.id}
                className={styles.SubMenuItem}
                onClick={() => onSubItemClick(section.id)}
              >
                {section.name}
                <Icon name="chevron-right"
                  size={16}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <ProductList
          sourceData={sourceMenuData}
          isSearchInputFocused={isSearchInputFocused}
          onSectionIntersect={handleActiveMenuTypeUpdated}
          ref={sectionsContainerRef}
          productCartRef={productCartRef}
          drawerHeaderRef={drawerHeaderRef}
        />
      );
    }
  }, [isSearchInputFocused, drawerHeaderRef.current, sectionsContainerRef.current, sourceMenuData, i18n.language]);

  const productCartView = useMemo(() => {
    if (!productsForReplacement) return;
    if (!productsForReplacement.length) return;

    return (
      <div className={styles.ProductCart}
        ref={productCartRef}
      >
        <Button size="large"
          onClick={handleDrawerClosed}
          fluid
        >
          {`${t('orders.offer_to_replacement')} – ${productsForReplacement.length}`}
        </Button>
      </div>
    );
  }, [productsForReplacement, i18n.language]);

  const onSubItemClick = (parentId: number) => {
    if (!incidentStoreParams) return;
    const {storeId, branchId} = incidentStoreParams;

    const config = {
      storeId,
      params: {branchId, parentId},
    };

    handleSubMenuItemClick(config);
  };

  const onSearchProductInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!incidentStoreParams) return;
    const {storeId, branchId} = incidentStoreParams;

    const config = {
      storeId,
      params: {branchId, search: e.target.value},
    };

    handleSearchProductInput(config);
  };

  return (
    <>
      <div className={styles.DrawerHeader}
        ref={drawerHeaderRef}
      >
        <div className={styles.DrawerTitle}>
          {headerTitleView}
          <div className={styles.DrawerClose}
            onClick={handleDrawerClosed}
          >
            <Icon name="cross"
              size={20}
            />
          </div>
        </div>
        <div className={styles.SearchInput}>
          <Input
            value={searchProductInput?.params.search || ''}
            placeholder={t('orders.search_by_menu')}
            onChange={onSearchProductInputChange}
            // @ts-ignore
            ref={searchInputRef}
            prefix={(
              <Icon name="search"
                size={16}
              />
            )}
            fluid
          />
        </div>
        {tabsView}
      </div>
      {searchResultView}
      {productContentView}
      {productCartView}
    </>
  );
};
