import {createEvent, createStore, createEffect, sample, guard, combine} from 'effector';
import {createEmployee, Employee, EmployeesContract, fetchEmployees, updateEmployee} from '@api/v2';
import {attachSession} from '@entities/session';
import {
  ActivityParams,
  BranchesOptionConfig,
  CreateEmployeeParams,
  InputErrors,
  UpdateInfo,
  UpdateParams,
} from './model.types';
import {$stores} from '@entities/attached-stores';
import {notification} from '@express-24/theseus-ui';
import i18n from 'i18next';

export const ROLE_NAMES = {
  29: 'operator',
  31: 'manager',
};

export const ROLE_OPTIONS = [
  {
    value: 29,
    label: 'operator',
  },
  {
    value: 31,
    label: 'manager',
  },
];

export const pageMounted = createEvent();
export const pageUnmounted = createEvent();
export const employeeSelected = createEvent<number>();
export const activityChanged = createEvent<ActivityParams>();
export const branchSelected = createEvent<number>();
export const employeeInfoEdited = createEvent<UpdateInfo>();
export const editFormSubmitted = createEvent();
export const createEmployeeModalOpened = createEvent();
export const createEmployeeModalClosed = createEvent();

export const loginChanged = createEvent<string>();
export const passwordChanged = createEvent<string>();
export const nameChanged = createEvent<string>();
export const roleChanged = createEvent<number>();
export const attachedBranchesChanged = createEvent<number[]>();
export const phoneNumberChanged = createEvent<string>();
export const createEmployeeFormSubmitted = createEvent();

export const $employees = createStore<EmployeesContract | null>(null);
export const $selectedEmployeeId = createStore<number | null>(null);
export const $selectedBranchId = createStore<number | null>(null);
export const $temporaryUserInfo = createStore<Employee | null>(null);
export const $editInputErrors = createStore<InputErrors | null>(null);
export const $isCreateEmployeeModalOpen = createStore<boolean>(false);

export const $login = createStore<string | null>(null);
export const $password = createStore<string>('');
export const $name = createStore<string | null>(null);
export const $roleId = createStore<number | null>(null);
export const $attachedBranchesIds = createStore<number[] | null>(null);
export const $phoneNumber = createStore<string | null>(null);
export const $createInputErrors = createStore<InputErrors | null>(null);

export const $createEmployeeFormValues = combine(
  $login,
  $password,
  $name,
  $roleId,
  $attachedBranchesIds,
  $phoneNumber,
  (login, password, name, roleId, attachedBranchesIds, phone) => {
    if (!login) return null;
    if (!password) return null;
    if (!name) return null;
    if (!roleId) return null;
    if (!attachedBranchesIds) return null;

    const formValues = {
      login,
      password,
      name,
      roleId,
      attachedBranchesIds,
    };

    if (!(typeof phone === 'string')) formValues['phone'] = undefined;

    if (typeof phone === 'string') formValues['phone'] = phone;

    return formValues;
  },
);

export const $selectedEmployee = combine($employees, $selectedEmployeeId, (employees, selectedEmployeeId) => {
  if (!employees) return null;
  if (!selectedEmployeeId) return null;

  const selectedEmployee = employees.find((employee) => employee.id === selectedEmployeeId);

  if (!selectedEmployee) return null;

  return {
    ...selectedEmployee,
    password: '',
  };
});

export const $editedUserInfo = combine($selectedEmployee, $temporaryUserInfo, (selectedEmployee, temporaryUserInfo) => {
  if (!selectedEmployee) return null;
  if (!temporaryUserInfo) return null;

  const editedInfo = {};

  for (const info in temporaryUserInfo) {
    if (temporaryUserInfo[info] === selectedEmployee[info]) continue;
    editedInfo[info] = temporaryUserInfo[info];
  }

  editedInfo['id'] = selectedEmployee.id;
  return editedInfo;
});

export const $inputStatuses = $editInputErrors.map((inputErrors) => {
  if (!inputErrors) return {};

  const statuses = {};

  for (const input in inputErrors) {
    statuses[input] = 'error';
  }

  return statuses;
});

export const $createInputStatuses = $createInputErrors.map((inputErrors) => {
  if (!inputErrors) return {};

  const statuses = {};

  for (const input in inputErrors) {
    statuses[input] = 'error';
  }

  return statuses;
});

export const $isFormEdited = $editedUserInfo.map((editedInfo) => {
  if (!editedInfo) return false;
  if (Object.keys(editedInfo).length === 1) return false;

  return true;
});

export const $attachedBranches = $stores.map((stores) => {
  if (!stores) return null;

  const branches = [];

  for (let i = 0; i < stores.length; i++) {
    for (let j = 0; j < stores[i].branches.length; j++) {
      branches.push({
        label: stores[i].branches[j].name,
        caption: stores[i].name,
        value: stores[i].branches[j].id,
      });
    }
  }

  if (branches.length === 0) {
    return null;
  }

  return branches;
});

export const $filteredEmployees = combine($selectedBranchId, $employees, (branchId, employees) => {
  if (!branchId) return null;
  if (!employees) return null;

  const filteredEmployees: EmployeesContract = [];

  for (const employee of employees) {
    const isExist = employee.attachedBranchesIds.find((attachedBranchId) => attachedBranchId === branchId);
    if (isExist) filteredEmployees.push(employee);
  }

  return filteredEmployees;
});

export const fetchEmployeesApiFx = createEffect(fetchEmployees);
export const updateEmployeeApiFx = createEffect(updateEmployee);
export const createEmployeeApiFx = createEffect(createEmployee);
export const showEditSuccessNotificationFx = createEffect(() => {
  notification.success({
    title: i18n.t('roles_management.employee_success_update'),
    duration: 2000,
  });
});
export const showCreateEmployeeError = createEffect((errorText: string) => {
  notification.error({
    title: errorText,
    duration: 5000,
  });
});

export const fetchEmployeesFx = attachSession({
  effect: fetchEmployeesApiFx,
  mapParams: (_, authorization) => {
    return {
      headers: {
        authorization,
      },
    };
  },
});

export const updateEmployeeFx = attachSession({
  effect: updateEmployeeApiFx,
  mapParams: ({params, data}: UpdateParams, authorization) => {
    return {
      headers: {
        authorization,
      },
      params,
      data,
    };
  },
});

export const createEmployeeFx = attachSession({
  effect: createEmployeeApiFx,
  mapParams: (data: CreateEmployeeParams, authorization) => {
    return {
      headers: {
        authorization,
      },
      data,
    };
  },
});

sample({
  clock: pageMounted,
  target: fetchEmployeesFx,
});

sample({
  clock: activityChanged,
  fn: (activityParams) => {
    return {
      params: {
        userId: activityParams.id,
      },
      data: {
        isActive: activityParams.isActive,
      },
    };
  },
  target: updateEmployeeFx,
});

sample({
  clock: $selectedEmployee.updates,
  fn: (employee) => {
    if (!employee) return null;

    return {
      ...employee,
      phone: employee.phone ? employee.phone : '',
    };
  },
  target: $temporaryUserInfo,
});

sample({
  clock: updateEmployeeFx.done,
  target: fetchEmployeesFx,
});

sample({
  source: guard({
    clock: editFormSubmitted,
    source: $editedUserInfo,
    filter: (editedUserInfo): editedUserInfo is Employee => editedUserInfo !== null,
  }),
  fn: (editedUserInfo) => {
    const editingUserId = editedUserInfo.id;
    const editingInfo = {
      ...editedUserInfo,
      id: undefined,
    };

    return {
      params: {
        userId: editingUserId,
      },
      data: {
        ...editingInfo,
        password: editingInfo.password ? editingInfo.password : undefined,
      },
    };
  },
  target: updateEmployeeFx,
});

sample({
  clock: updateEmployeeFx.doneData,
  target: showEditSuccessNotificationFx,
});

sample({
  clock: createEmployeeFx.doneData,
  target: fetchEmployeesFx,
});

sample({
  source: guard({
    clock: createEmployeeFormSubmitted,
    source: $createEmployeeFormValues,
    filter: (formValues): formValues is CreateEmployeeParams => formValues !== null,
  }),
  fn: (formValues) => {
    return {
      ...formValues,
      phone: formValues.phone && formValues.phone.replace(/\s/g, ''),
    };
  },
  target: createEmployeeFx,
});

sample({
  source: guard({
    clock: $attachedBranches.updates,
    filter: (branches): branches is BranchesOptionConfig[] => branches !== null,
  }),
  fn: (branches) => branches[0].value,
  target: branchSelected,
});

sample({
  source: createEmployeeFx.fail,
  // @ts-ignore
  filter: (failData) => Boolean(failData.error.response.data.error),
  // @ts-ignore
  fn: (failData) => failData.error.response.data.error,
  target: showCreateEmployeeError,
});

$employees.on(fetchEmployeesFx.doneData, (_, {data}) => {
  return data.map((employee) => {
    return {
      ...employee,
      password: '',
    };
  });
});

$selectedEmployeeId.on(employeeSelected, (_, id) => id);
$selectedBranchId.on(branchSelected, (_, id) => id);
$temporaryUserInfo.on(employeeInfoEdited, (employeeInfo, editedInfo) => {
  if (!employeeInfo) return null;
  if (!editedInfo) return employeeInfo;

  const passwordRegExp = new RegExp(/^[a-zA-Z0-9._-]+$/);
  const isValid = editedInfo.password && passwordRegExp.test(editedInfo.password);

  const validatedInfo = {
    ...editedInfo,
    password: isValid ? editedInfo.password : employeeInfo.password,
  };

  if (!editedInfo.password) validatedInfo.password = '';

  return {
    ...employeeInfo,
    ...validatedInfo,
  };
});

$editInputErrors
  .on(updateEmployeeFx.failData, (_, failData) => {
    //@ts-ignore
    return failData.response.data.errors;
  })
  .on(employeeSelected, () => null);

$createInputErrors
  .on(createEmployeeFx.failData, (_, failData) => {
    //@ts-ignore
    return failData.response.data.errors;
  })
  .on(createEmployeeModalClosed, () => null);

$isCreateEmployeeModalOpen
  .on(createEmployeeModalOpened, () => true)
  .on(createEmployeeModalClosed, () => false)
  .on(createEmployeeFx.doneData, () => false);

$login.on(loginChanged, (_, login) => login).on(createEmployeeModalClosed, () => null);
$password
  .on(passwordChanged, (password, newPassword) => {
    const regExp = new RegExp(/^[a-zA-Z0-9._-]+$/);
    const isValid = regExp.test(newPassword);

    if (!newPassword) return '';

    if (!isValid) return password;

    return newPassword;
  })
  .on(createEmployeeModalClosed, () => '');

$name.on(nameChanged, (_, name) => name).on(createEmployeeModalClosed, () => null);
$roleId.on(roleChanged, (_, roleId) => roleId).on(createEmployeeModalClosed, () => null);
$attachedBranchesIds.on(attachedBranchesChanged, (_, branches) => branches).on(createEmployeeModalClosed, () => null);
$phoneNumber.on(phoneNumberChanged, (_, phoneNumber) => phoneNumber).on(createEmployeeModalClosed, () => null);
