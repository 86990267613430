const BACKEND_ENV = process.env.RAZZLE_BACKEND_ENV;
const DEV = BACKEND_ENV !== 'PRODUCTION';

const BACKEND_HOST: any = {
  PRODUCTION: process.env.RAZZLE_BACKEND_WS_HOST_PRODUCTION,
};

if (DEV) {
  BACKEND_HOST.DEVELOPMENT = process.env.RAZZLE_BACKEND_WS_HOST_DEVELOPMENT;
}

const PROTOCOL = 'wss';
//@ts-ignore
const BASE_URL = BACKEND_HOST[BACKEND_ENV];

export const WSS_URL = `${PROTOCOL}://${BASE_URL}/connection/websocket`;
