import {combine, createEffect, createEvent, createStore, guard, sample} from 'effector';
import {BranchesOption, CorrectedReviews, RatingFilterValue} from './model.types';
import {fetchOrder, fetchReviews, OrderContract, ReviewParams, StoresContract} from '@api/v2';
import {$stores} from '@entities/attached-stores';
import {attachSession} from '@entities/session';
import {SlideImage} from 'yet-another-react-lightbox';
import {createGate} from 'effector-react';

const LIMIT = 10;
const DEFAULT_PAGE = 1;
const REVIEW_TYPE_MAP = {
  all: {
    ratingFrom: 1,
    ratingTo: 5,
  },
  positive: {
    ratingFrom: 4,
    ratingTo: 5,
  },
  negative: {
    ratingFrom: 1,
    ratingTo: 3,
  },
};

export const reviewsSearched = createEvent();
export const pageChanged = createEvent<number>();
export const storeIdChanged = createEvent<number>();
export const ratingChanged = createEvent<RatingFilterValue>();
export const branchesIdsChanged = createEvent<number[]>();
export const orderDetailsOpened = createEvent<number>();
export const orderDetailsClosed = createEvent();
export const imagesSelected = createEvent<SlideImage[]>();
export const imagesOpened = createEvent();
export const imagesClosed = createEvent();

export const $selectedStoreId = createStore<number | null>(null);
export const $reviews = createStore<CorrectedReviews | null>(null);
export const $selectedBranchesIds = createStore<number[] | null>(null);
export const $selectedRatingRange = createStore<RatingFilterValue>('all');
export const $orderDetails = createStore<OrderContract | null>(null);
export const $page = createStore<number>(DEFAULT_PAGE);
export const $isOrderDetailsOpen = createStore<boolean>(false);
export const $selectedImages = createStore<SlideImage[] | null>(null);
export const $isImagesOpen = createStore<boolean>(false);
export const $isSearchButtonDisabled = createStore<boolean>(false);

export const ratingGate = createGate();

export const $selectedStore = combine($stores, $selectedStoreId, (stores, storeId) => {
  if (!stores) return null;
  if (!storeId) return null;

  const selectedStore = stores.find((store) => store.id === storeId);

  if (!selectedStore) return null;

  return selectedStore;
});

export const $reviewFilterValues = combine(
  $selectedBranchesIds,
  $selectedRatingRange,
  $selectedStore,
  $page,
  (branchesIds, reviewType, storeId, page) => {
    if (!storeId) return null;
    if (!reviewType) return null;
    if (!branchesIds) return null;
    if (!branchesIds.length) return null;

    return {
      ...REVIEW_TYPE_MAP[reviewType],
      branchIds: branchesIds,
      page,
      limit: LIMIT,
    };
  },
);

export const $selectedStoreBranches = $selectedStore.map((selectedStore) => {
  if (!selectedStore) return null;
  const selectedStoreBranches: BranchesOption[] = [];

  for (const branch of selectedStore.branches) {
    selectedStoreBranches.push({
      value: branch.id,
      label: branch.name,
    });
  }

  return selectedStoreBranches;
});

export const $filteredStores = $stores.map((stores) => {
  if (!stores) return null;

  return stores.map((store) => {
    return {
      value: store.id,
      label: store.name,
    };
  });
});

export const fetchReviewsApiFx = createEffect(fetchReviews);
export const fetchOrderApiFx = createEffect(fetchOrder);

export const fetchReviewsFx = attachSession({
  effect: fetchReviewsApiFx,
  mapParams: (params: ReviewParams, authorization) => {
    return {
      params,
      headers: {
        authorization,
      },
    };
  },
});

export const fetchOrderFx = attachSession({
  effect: fetchOrderApiFx,
  mapParams: (orderId: number, authorization) => ({
    params: {
      orderId: orderId,
    },
    headers: {
      authorization,
    },
  }),
});

sample({
  clock: orderDetailsOpened,
  target: fetchOrderFx,
});

sample({
  clock: guard({
    clock: [reviewsSearched, $page.updates, ratingGate.open],
    source: $reviewFilterValues,
    filter: (filterValues): filterValues is ReviewParams => filterValues !== null,
  }),
  target: fetchReviewsFx,
});

sample({
  clock: reviewsSearched,
  fn: () => DEFAULT_PAGE,
  target: $page,
});

sample({
  clock: guard({
    source: $stores,
    filter: (stores): stores is StoresContract => stores !== null,
  }),
  fn: (stores) => stores[0].id,
  target: storeIdChanged,
});

sample({
  clock: guard({
    clock: $selectedStoreBranches.updates,
    source: $selectedStoreBranches,
    filter: (branches): branches is BranchesOption[] => branches !== null,
  }),
  fn: (branches) => branches.map((branch) => branch.value),
  target: branchesIdsChanged,
});

$page.on(pageChanged, (_, page) => page);
$reviews.on(fetchReviewsFx.doneData, (_, {data}) => {
  return {
    ...data,
    list: data.list.map((review) => {
      return {
        ...review,
        review: {
          ...review.review,
          images: review.review.images.map((image) => {
            return {
              type: 'image',
              src: image,
            };
          }),
        },
      };
    }),
  };
});
$selectedStoreId.on(storeIdChanged, (_, storeId) => storeId);
$selectedBranchesIds.on(branchesIdsChanged, (_, branchesIds) => branchesIds).on(storeIdChanged, () => null);
$selectedRatingRange.on(ratingChanged, (_, ratingRange) => ratingRange);
$orderDetails.on(fetchOrderFx.doneData, (_, {data}) => data).on(orderDetailsClosed, () => null);
$isOrderDetailsOpen.on(orderDetailsOpened, () => true).on(orderDetailsClosed, () => false);
$selectedImages.on(imagesSelected, (_, images) => images).on(imagesClosed, () => null);
$isImagesOpen
  .on(imagesOpened, () => true)
  .on(imagesClosed, () => false)
  .on($selectedImages.updates, (_, images) => Boolean(images));
$isSearchButtonDisabled
  .on(fetchReviewsFx.done, () => true)
  .on($reviewFilterValues.updates, (_, filters) => !filters)
  .on(fetchReviewsFx.pending, () => true);
