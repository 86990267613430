import {createStore, createEvent, sample, combine} from 'effector';
import {ProductDetailsCombinations, ProductDetailsContract, ProductDetailsProperties} from '@api/v2';
import {AddProductProp, ProductOption} from './model.types';

export const productChanged = createEvent<ProductDetailsContract>();

export const $product = createStore<ProductDetailsContract | null>(null);
export const $isDialogOpen = createStore(false);
export const $selectedOptionByPropertyId = createStore<Record<number, number>>({});
export const $productCount = createStore(1);
export const $properties = createStore<ProductDetailsProperties | []>([]);
export const $combinations = createStore<ProductDetailsCombinations | []>([]);

export const dialogOpened = createEvent();
export const dialogClosed = createEvent();
export const dialogExited = createEvent();
export const productCountChanged = createEvent<number>();
export const optionSelected = createEvent<ProductOption>();

export const $quantityInStock = $product.map((product) => {
  if (!product) return 0;

  return product.amount.value;
});

export const $variant = combine($selectedOptionByPropertyId, $combinations, (hashMap, combinations) => {
  const accumulatedProduct = combinations.find((combination) => {
    return combination.properties.every((property) => {
      return hashMap[property.propertyId] === property.optionId;
    });
  });

  return accumulatedProduct || null;
});

export const $hasOptions = $product.map((product) => {
  if (!product) return false;

  return product.combinations.length > 0;
});

export const $nextUnselectedRequiredOptionId = combine(
  $hasOptions,
  $variant,
  $selectedOptionByPropertyId,
  $properties,
  (hasOptions, variant, map, properties) => {
    if (!hasOptions) return null;
    if (variant) return null;

    const option = properties.find(({id}) => {
      return !Object.prototype.hasOwnProperty.call(map, id);
    });

    if (!option) return null;

    return option.id;
  },
);

export const $price = combine($product, $variant, (product, accumulatedProduct) => {
  if (!product) return;

  const unified = accumulatedProduct ?? product;

  if (!unified) return null;

  return unified.price;
});

export const $unifiedProduct = combine($product, $variant, $productCount, (product, variant, count) => {
  const props: AddProductProp[] = [];

  if (variant && product) {
    variant.properties.forEach((variantItem) => {
      product.properties.forEach((productItem) => {
        if (variantItem.propertyId === productItem.id) {
          const option = productItem.options.find((item) => item.id === variantItem.optionId);

          if (option) {
            props.push({value: option.name});
          }
        }
      });
    });

    return {
      id: variant.productId,
      menuProductId: product.id,
      name: product.name,
      cover: product.cover,
      price: variant.price,
      amount: variant.amount,
      count,
      props,
    };
  }

  if (product) {
    return {
      id: product.id,
      name: product.name,
      cover: product.cover,
      price: product.price,
      amount: product.amount,
      count,
    };
  }

  return null;
});

$isDialogOpen.on(dialogOpened, () => true).reset(dialogClosed);

$product.on(productChanged, (_, product) => product).reset(dialogExited);

$productCount.on(productCountChanged, (_, count) => count).reset(dialogExited);

$properties.on($product, (_, product) => product?.properties);

$combinations.on($product, (_, product) => product?.combinations);

$selectedOptionByPropertyId
  .on(optionSelected, (hashMap, {propertyId, optionId}) => ({
    ...hashMap,
    [propertyId]: optionId,
  }))
  .reset(dialogExited);

sample({
  clock: productChanged,
  target: dialogOpened,
});
