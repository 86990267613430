import {createEffect, sample} from 'effector';
import {createSession} from '@api/v2';
import {tokenChanged} from '@entities/session';
import {history} from '@shared/history';

const createSessionFx = createEffect(createSession);

const redirectToHomePageFx = createEffect(() => {
  history.replace('/');
});

sample({
  clock: createSessionFx.done,
  fn: ({result}) => result.data,
  target: [tokenChanged, redirectToHomePageFx],
});

export {createSessionFx};
