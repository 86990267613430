import {createEffect, sample} from 'effector';
import {$paymentTypesOptions} from '@entities/payment-types';
import {attachSession} from '@entities/session';
import {fetchPaymentTypes} from '@api/v2';

export const fetchPaymentTypesApiFx = createEffect(fetchPaymentTypes);

export const fetchPaymentTypesFx = attachSession({
  effect: fetchPaymentTypesApiFx,
  mapParams: (_, authorization) => ({
    headers: {
      authorization,
    },
  }),
});

sample({
  clock: fetchPaymentTypesFx.doneData,
  fn: ({data}) => {
    return data.map(({name, id}) => ({
      label: name,
      value: id,
    }));
  },
  target: $paymentTypesOptions,
});
