import {useUnit} from 'effector-react';
import {$changesHistory, $page, fetchChangesHistoryFx, pageChanged, pageMounted, pageUnmounted} from '../model';
import styles from './page.module.scss';
import {Loader, Pagination, Space, Table, Tag, Typography} from '@express-24/theseus-ui';
import {useEffect, useMemo} from 'react';
import dayjs from 'dayjs';
import {EmptyState} from '@widgets/empty-state';
import {Filter} from './ui';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const ChangesHistory = () => {
  const {
    handlePageMount,
    handlePageUnmount,
    handlePageChange,

    page,
    changesHistory,

    isHistoryPending,
  } = useUnit({
    handlePageMount: pageMounted,
    handlePageUnmount: pageUnmounted,
    handlePageChange: pageChanged,

    page: $page,
    changesHistory: $changesHistory,

    isHistoryPending: fetchChangesHistoryFx.pending,
  });
  const {t} = useTranslation();

  useEffect(() => {
    handlePageMount();

    return () => handlePageUnmount();
  }, []);

  const tableView = useMemo(() => {
    if (!changesHistory) return;
    if (!changesHistory.count) return;

    return (
      <Table
        source={changesHistory.list}
        indexKey="date"
        columns={[
          {
            title: t('user'),
            indexKey: 'user',
            render: (change) => change.user.name,
          },
          {
            title: t('branch'),
            indexKey: 'branch',
            render: ({branch}) => {
              if (!branch) return '-';

              return branch.name;
            },
          },
          {
            title: t('change_type'),
            indexKey: 'action',
            render: (change) => change.action.type,
          },
          {
            title: t('action_type'),
            indexKey: 'action',
            render: (change) => {
              return (
                <div className={styles.Tag}>
                  <Tag color={change.action.color}
                    textColor="#F3F3F4"
                  >
                    {change.action.name}
                  </Tag>
                </div>
              );
            },
          },
          {
            title: t('note'),
            indexKey: 'action',
            render: (change) => {
              if (!change.action.note) return '-';
              return change.action.note;
            },
          },
          {
            title: t('date_time'),
            indexKey: 'date',
            render: (change) => dayjs(change.date).format(DATE_FORMAT),
          },
        ]}
      />
    );
  }, [changesHistory, isHistoryPending, i18n.language]);

  const emptyView = useMemo(() => {
    if (isHistoryPending) return;
    if (changesHistory !== null) {
      if (changesHistory.count) return;
    }

    return (
      <Space padding={150}>
        <EmptyState
          illustration="noReviews"
          title={t('changes_history.no_history')}
          description={t('changes_history.no_history_description')}
        />
      </Space>
    );
  }, [changesHistory, isHistoryPending, i18n.language]);

  const loadingView = useMemo(() => {
    if (changesHistory !== null) return;
    if (!isHistoryPending) return;

    return (
      <div className={styles.LoaderWrapper}>
        <Loader size={42}
          strokeWidth={2}
        />
      </div>
    );
  }, [isHistoryPending]);

  const paginationView = useMemo(() => {
    if (!changesHistory) return;
    if (changesHistory.count < 11) return;

    return (
      <div className={styles.Pagination}>
        <Pagination
          onChange={({pageNumber}) => handlePageChange(pageNumber)}
          pageSize={10}
          contentLength={changesHistory.count}
          selected={page}
        />
      </div>
    );
  }, [page, isHistoryPending]);

  return (
    <div className={styles.ChangesHistory}>
      <Typography level="h1"
        className={styles.Title}
      >
        {t('sections.changes_history')}
      </Typography>
      <Filter />
      {loadingView}
      {tableView}
      {emptyView}
      {paginationView}
    </div>
  );
};
