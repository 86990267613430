import {DownLoadFileConfig} from '@shared/lib/download-file/types';

export const downloadFile = ({file, name, extension}: DownLoadFileConfig) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.${extension}`);
  document.body.appendChild(link);
  link.click();
};
