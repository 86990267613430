import {createEffect, createEvent, createStore, sample, scopeBind} from 'effector';
import {attachSession} from '@entities/session';
import {closeSessionFx} from '@features/logout';
import {createGate} from 'effector-react';

export const $isMenuOpened = createStore<boolean>(false);
export const $isLangDropdownOpened = createStore<boolean>(false);
export const $isOfflineAlertShown = createStore<boolean>(false);
export const $isLowInternetConnectionAlertShown = createStore<boolean>(false);

export const headerGate = createGate();

export const menuOpened = createEvent();
export const menuClosed = createEvent();
export const langDropdownToggle = createEvent();
export const userLoggedOut = createEvent();
const internetConnectionLost = createEvent();
const internetConnectionEnabled = createEvent();
const internetConnectionDowned = createEvent();
const internetConnectionUpped = createEvent();
const internetConnectionChanged = createEvent<string>();

const subscribeInternetConnectionAvailabilityFx = createEffect(() => {
  const internetConnectionEnabledBound = scopeBind(internetConnectionEnabled);
  window.addEventListener('online', () => internetConnectionEnabledBound());

  const internetConnectionLostBound = scopeBind(internetConnectionLost);
  window.addEventListener('offline', () => internetConnectionLostBound());
});

const subscribeInternetConnectionSpeedFx = createEffect(() => {
  const internetConnectionChangedBound = scopeBind(internetConnectionChanged);
  navigator.connection.addEventListener('change', () => {
    internetConnectionChangedBound(navigator.connection.effectiveType);
  });

  internetConnectionChangedBound(navigator.connection.effectiveType);
});

const logoutFx = attachSession({
  effect: closeSessionFx,
  mapParams: (_, authorization) => ({
    headers: {
      authorization,
    },
  }),
});

sample({
  clock: headerGate.open,
  target: [subscribeInternetConnectionAvailabilityFx, subscribeInternetConnectionSpeedFx],
});

sample({
  clock: userLoggedOut,
  target: logoutFx,
});

sample({
  clock: userLoggedOut,
  target: menuClosed,
});

sample({
  clock: internetConnectionChanged,
  filter: (speed) => speed === '2g',
  target: internetConnectionDowned,
});

sample({
  clock: internetConnectionChanged,
  filter: (speed) => speed !== '2g',
  target: internetConnectionUpped,
});

$isMenuOpened.on(menuOpened, () => true).on(menuClosed, () => false);
$isLangDropdownOpened.on(langDropdownToggle, (isOpen) => !isOpen);
$isOfflineAlertShown.on(internetConnectionLost, () => true).on(internetConnectionEnabled, () => false);
$isLowInternetConnectionAlertShown.on(internetConnectionDowned, () => true).on(internetConnectionUpped, () => false);
