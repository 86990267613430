import React from 'react';
import styles from './page.module.scss';
import {Button, Icon} from '@express-24/theseus-ui';
import logo from './assets/logo.svg';

export const UserAgreement = () => {
  return (
    <>
      <header className={styles.Header}
        id="agreement-up"
      >
        <img src={logo}
          alt="logo"
        />
      </header>
      <div className={styles.Container}>
        <h1 className={styles.Title}>Пользовательское соглашение</h1>

        <p>
          Настоящее Мерчантское соглашение регулирует отношения между ООО «EXPRESS24 APP» (далее именуемое – Express24),
          и Мерчантом-Вендором (далее «Мерчант») по использованию сервиса Express 24, в качестве Мерчанта.
        </p>
        <p>
          Перед тем как начать использовать сервис Express24, Мерчант обязан ознакомиться с настоящим Мерчантским
          соглашением и присоединиться к нему.
        </p>
        <p>
          Мерчант присоединяется к настоящему Мерчантскому Соглашению путем совершения одного из следующих действий:
        </p>
        <ul>
          <li>Нажатие кнопки «ПРИНИМАЮ» при регистрации на сайте или приложении Express24 Merchant.</li>
        </ul>
        <p>
          Настоящее Мерчантское соглашение приравнивается к договору, составленному в письменной форме. Принимая
          настоящее Мерчантское Соглашение, Мерчант выражает полное и безоговорочное согласие со всеми его условиями, в
          том числе, в части предоставления согласия Express24 на обработку персональных данных Мерчанта на условиях,
          указанных в разделе 8 настоящего Мерчантского соглашения.
        </p>

        <h3 className={styles.SubTitle}>1. Предмет Мерчантского Соглашения</h3>
        <p>
          <b>1.1</b> Express24 Merchant предлагает Мерчанту доступ к сервису получения заказа от клиента на условиях,
          предусмотренных настоящим Мерчантским соглашением.
        </p>
        <p>
          <b>1.2</b> Настоящее соглашение может быть изменено Express24 в одностороннем порядке без предварительного
          уведомления. Новая редакция Соглашения вступает в силу с момента ее опубликования в приложении/на сайте, если
          иное не предусмотрено новой редакцией Соглашения.
        </p>
        <p>
          <b>1.3</b> Мерчант вправе отказаться от принятия изменений Соглашения, осуществленных Express24, что означает
          отказ Мерчанта от использования сервиса Express24, в качестве Мерчанта.
        </p>

        <h3 className={styles.SubTitle}>2. Описание услуг</h3>
        <p>
          <b>2.1</b> Express24 Merchant является единой информационной системой заказов, которая предлагает Мерчантам
          широкие возможности для быстрого получения заказа, а также принимает от Клиентов денежные средства в счет
          оплаты заказов. Обязательства по реализации продуктов, согласно условиям оформленного заказа Клиентом,
          возникают непосредственно у Мерчанта.
        </p>
        <p>
          <b>2.2</b> Указанные услуги носят информационный характер и оказываются Мерчанту безвозмездно.
        </p>
        <p>
          <b>2.3</b> Мерчант соглашается с тем, что все услуги предоставляются «как есть» и что Express24 не несет
          ответственность за качество поставляемых продуктов, за задержки, сбои, неверную или несвоевременную доставку,
          а также за сбои в работе платежных систем.
        </p>
        <p>
          <b>2.4</b> Для того, чтобы воспользоваться услугами Express24 в качестве Мерчанта, Мерчанту необходимо иметь
          устройство с возможностью доступа в Интернет.
        </p>
        <p>
          <b>2.5</b> Мерчант самостоятельно получает Заказ в приложении или на сайте Express24 Merchant.
        </p>
        <p>
          <b>2.6</b> Мерчант принимает платеж за заказ одним из доступных способов:
        </p>
        <ul>
          <li>
            <b>2.6.1</b> непосредственно при получении заказа от службы доставки (Партнера Express24) наличными
            денежными средствами или иными способами, предоставленными Партнером Express24;
          </li>
          <li>
            <b>2.6.2</b> on-line оплата в приложении Express24 банковской картой ONLINE-карты UZCARD/HUMO через
            платежную систему ООО «INSPIRED» (т.м. Payme). Все дополнительные расходы по перечислению денежных средств
            за заказ несёт Мерчант. Представленная к оплате сумма является стоимостью Заказа и Услуг (таких как
            доставка, упаковка и т.д.). Безопасность обработки Интернет-платежей гарантирует ООО «INSPIRED» (т.м.
            Payme). Все операции с платежными картами происходят в соответствии с требованиями платежных систем на
            основе действующего законодательства Республики Узбекистан.{' '}
            <a href="https://uzcard.uz/about-us">https://uzcard.uz/about-us</a>. (
            <a href="https://humocard.uz/ru/about/">https://humocard.uz/ru/about/</a>) При передаче информации
            используется специальные технологии безопасности карточных онлайн-платежей, обработка данных ведется на
            безопасном высокотехнологичном сервере процессинговой компании. При наличии вопросов по совершенному
            платежу, Мерчант может обратиться в службу поддержки клиентов по телефону{' '}
            <a href="tel:998712026500">+998 71 202-65-00</a> (для Ташкента и Ташкентской Области). Мерчант имеет
            возможность привязать банковскую карту к своему Личному кабинету.
          </li>
        </ul>
        <p>
          <b>2.7</b> В случае отказа Мерчанта от Заказа, либо в случае нарушения Партнерами Express24 условий реализации
          продукции, в том числе, но не ограничиваясь: в случае одностороннего отказа от реализации продукции, нарушения
          срока доставки или несоответствия качества продукции и т.п. Мерчант обязан в течение 24 часов с момента
          наступления времени доставки/получения Заказа сообщить службе поддержки Express24, либо по e-mail адресу{' '}
          <a href="mailto:info@express24.uz">info@express24.uz</a> о своём отказе выполнения Заказа, для дальнейшего
          возмещения денежных средств.
        </p>
        <p>
          <b>2.8</b> В случае нарушения условий реализаций продукций указанного в п.2.7. настоящего соглашения, а также
          не принятий в течений 3 (трех) минут поступившего нового заказа Мерчанту через сервис Express24. Express24
          вправе отменить/аннулировать, поступивший заказ Мерчанту.
        </p>

        <h3 className={styles.SubTitle}>3. Обязательства по использованию сервиса</h3>
        <p>
          <b>3.1</b> Мерчант соглашается предоставить достоверную, полную и актуальную информацию по вопросам,
          предлагаемым в форме заказа и/или форме регистрации.
        </p>
        <p>
          <b>3.2</b> Если Мерчант предоставляет неверную/некорректную информацию или у Express24 есть серьезные
          основания полагать, что предоставленная им информация неверна, неполна или неточна, Express24 имеет право
          приостановить либо отменить регистрацию Мерчанта и отказать ему в использовании сервиса.
        </p>
        <p>
          <b>3.3</b> Express24 оставляет за собой право отказать Мерчанту в применении сервиса Express24 без уведомления
          Мерчанта и/или без объяснения причин.
        </p>
        <p>
          <b>3.4</b> Express24 вправе проводить рекламные акции.
        </p>

        <h3 className={styles.SubTitle}>4. Регистрация Мерчанта</h3>
        <p>
          <b>4.1</b> Мерчант соглашается пройти процедуру регистрации на сайте и в приложении, заполнив форму
          регистрации и выразив согласие с условиями Соглашения путем подтверждения пункта «Я принимаю условия
          Соглашения».
        </p>
        <p>
          <b>4.2</b> По завершении процесса регистрации Мерчант получает логин и пароль для доступа в Личный кабинет.
        </p>
        <p>
          <b>4.3</b> Мерчант несет ответственность за безопасность своего логина и пароля, а также за все, что будет
          сделано на сайте и в приложении Express24 под его логином и паролем.
        </p>
        <p>
          <b>4.4</b> Мерчант соглашается с тем, что он обязан немедленно уведомить Express24 о любом случае
          неавторизованного (не разрешенного) доступа со своим логином и паролем и/или о любом нарушении безопасности.
        </p>

        <h3 className={styles.SubTitle}>5. Условия использования материалов, размещенных на Express24</h3>
        <p>
          <b>5.1</b> Приложение и сайт Express24 содержит материалы, охраняемые авторским правом, товарные знаки и иные
          охраняемые законом материалы, включая, но не ограничиваясь: тексты, фотографии, графические изображения.
        </p>
        <p>
          <b>5.2</b> При этом все содержание приложения и сайта охраняется авторским правом как произведение, созданное
          коллективным творческим трудом в соответствии с законодательством Республики Узбекистан об авторском праве и
          смежных правах.
        </p>
        <p>
          <b>5.3</b> Express24 принадлежит авторское право на использование содержания приложения/сайта (в том числе,
          право на подбор, расположение, систематизацию и преобразование данных, содержащихся на сайте Express24, а
          также на сами исходные данные), кроме случаев, отдельно отмеченных в содержании опубликованных на сайте
          материалов.
        </p>
        <p>
          <b>5.4</b> Мерчант не имеет права вносить изменения, публиковать, передавать третьим лицам, участвовать в
          продаже или уступке, создавать производные продукты или иным образом использовать, частично или полностью,
          содержание приложения и сайта.
        </p>
        <p>
          <b>5.5</b> Мерчант обязуется использовать приложение и сайт только в законных целях.
        </p>
        <p>
          <b>5.6</b> Мерчант обязуется не размещать в приложении/на сайте и не направлять куда-либо через/посредством
          приложения любые материалы следующего характера:
        </p>
        <ul>
          <li>
            нарушающие законодательство, содержащие угрозы и оскорбления, дискредитирующие других лиц, нарушающие права
            граждан на частную жизнь или публичный порядок, носящие характер непристойности; нарушающие в той или иной
            степени честь и достоинство, права и охраняемые законом интересы других лиц;
          </li>
          <li>
            способствующие или содержащие призывы к разжиганию религиозной, расовой или межнациональной розни,
            содержащие попытки разжигания вражды или призывы к насилию;
          </li>
          <li>
            а также иные материалы, которые побуждают других лиц на противоправное поведение, влекущее уголовную,
            гражданско-правовую и иную ответственность или каким-либо образом нарушающее положения законодательства.
          </li>
        </ul>
        <p>
          <b>5.7</b> Мерчант обязуется не размещать в приложении/на сайте и не направлять через/посредством сайта
          материалы, являющиеся рекламой каких-либо товаров или услуг, без получения предварительного явно выраженного
          согласия Express24.
        </p>
        <p>
          <b>5.8</b> Мерчант обязуется не использовать сайт/приложение для рекламы или иного стимулирования сбыта любых
          товаров и услуг в любой форме, включая, но не ограничиваясь, стимулирование Мерчантов к подписке на другую
          систему интерактивного обслуживания, являющуюся конкурентом Express24.
        </p>
        <p>
          <b>5.9</b> Мерчант обязуется не загружать, размещать или иным образом использовать на сайте/в приложении
          какие-либо материалы, охраняемые законодательством об интеллектуальной собственности (в том числе, авторским
          правом, законодательством о товарных знаках), и иные охраняемые законодательством материалы без получения
          выраженного разрешения обладателя прав на охраняемый материал. При этом бремя доказывания того, что размещение
          на Сайте/в приложении Мерчантом материалов не нарушает авторские, смежные и иные права третьих лиц на
          размещаемые материалы, а также ответственность незаконное размещение лежит на Пользователе.
        </p>
        <p>
          <b>5.10</b> Express24 не несет ответственность за отзывы, опубликованные Мерчантом.
        </p>
        <p>
          <b>5.11</b> Express24 оставляет за собой право размещать комментарии к отзыву Мерчанта. Express24 оставляет за
          собой право не размещать и удалять отзывы Мерчанта, нарушающие законодательство и наносящие ущерб законным
          интересам третьих лиц (в том числе, носящие оскорбительный характер либо порочащие честь, достоинство и
          репутацию таких лиц).
        </p>

        <h3 className={styles.SubTitle}>6. Ответственность</h3>
        <p>
          <b>6.1.</b> Express24 не несет ответственности за соблюдение/несоблюдение Партнеров своих обязательств перед
          Мерчантом, а также за достоверность информации, предоставленные Партнерами сервиса. Express24 является лишь
          информационным связующим звеном между Мерчантом и Партнерами.
        </p>
        <p>
          <b>6.2.</b> В случае нанесения ущерба Express24 по вине Мерчанта самим Мерчантом или другими, третьими лицами,
          Мерчаент обязуется погасить сумму ущерба с первого требования Express24.
        </p>

        <h3 className={styles.SubTitle}>7. Права собственности Express24</h3>
        <p>
          <b>7.1.</b> Мерчант признает и соглашается с тем, что сервис Express24 и все необходимые программы, связанные
          с ним, содержат конфиденциальную информацию, которая защищена законами об интеллектуальной собственности и
          прочими международными законами. Мерчант соглашается не модифицировать, не продавать, не распространять этот
          контент и программы, целиком либо по частям.
        </p>

        <h3 className={styles.SubTitle}>8. Конфиденциальность</h3>
        <p>
          <b>8.1.</b> При регистрации на сайте/в приложении Express24 Мерчант предоставляет следующие данные: имя, адрес
          электронной почты и/или номер контактного телефона.
        </p>
        <p>
          <b>8.2.</b> В целях исполнения настоящего Мерчантского соглашения Express24 развивает, оптимизирует и внедряет
          новый функционал сервиса (включая продукты информационного, рекламного, развлекательного и иного характера), в
          т.ч. с участием аффилированных лиц и/или партнеров. Для обеспечения реализации указанных целей, а также в
          целях информирования Мерчантов о своих услугах, продвижения Express24 товаров и услуг, проведения электронных
          и sms опросов, Получения Мерчантом персонализированной (таргетированной) рекламы, контроля маркетинговых
          акций, клиентской поддержки, организации доставки товара Мерчантам, проведения розыгрышей призов среди
          Мерчантов, контроля удовлетворенности Мерчанта и качества услуг, оказываемых службами доставки, проверки,
          исследования и анализа таких данных Мерчант при регистрации/оформлении заказа соглашается и поручает Express24
          осуществлять с соблюдением применимого законодательства обработку данных, в т.ч. результатов
          автоматизированной обработки таких данных в виде целочисленных и/или текстовых значений и идентификаторов, их
          передачу аффилированным лицам и/или партнерам во исполнение такого поручения на обработку, а также
          осуществлять сбор (получение) данных Мерчанта и иных связанных с Мерчантом данных от аффилированных лиц и/или
          партнеров. Express24 имеет право распоряжаться статистической информацией, связанной с функционированием
          сайта/приложения.
        </p>
        <p>
          <b>8.3.</b> Под данными, связанными с Мерчантом, понимается информация о технических средствах (устройствах) и
          способах технологического взаимодействия с сервисом Express24 и/или сервисами аффилированных лиц и/или
          партнеров (в т. ч. IP-адрес хоста, вид операционной системы, тип браузера, географическое положение, данные о
          провайдере и иное), об активности Мерчанта, а также иные данные, получаемые указанными способами.
        </p>
        <p>
          <b>8.4.</b> Под обработкой данных понимается любое действие (операция) или совокупность действий (операций),
          совершаемых с использованием средств автоматизации или без использования таких средств с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
          сопоставление, извлечение, использование, передача аффилированным лицам и/или партнерам Express24,
          обезличивание, блокирование, удаление, уничтожение.
        </p>
        <p>
          <b>8.5.</b> Express24 имеет право отправлять Мерчанту от своего имени самостоятельно или с привлечением
          технических партнеров информационные, в том числе сервисные и рекламные сообщения, на электронную почту
          Мерчанта, мобильный телефон (смс, телефонные звонки) или через используемые им сервисы партнеров Express24
          (социальные сети, мессенджеры и иные). Мерчант вправе отказаться от получения рекламной и другой информации
          без объяснения причин отказа. Сервисные сообщения, информирующие Мерчанта о заказе и этапах его обработки,
          отправляются автоматически и не могут быть отклонены Мерчантом.
        </p>
        <p>
          <b>8.6.</b> Express24 вправе использовать технологию «cookies». «Cookies» не содержат конфиденциальную
          информацию, и Express24 вправе передавать информацию о «cookies» Партнерам, агентам и третьим лицам, имеющим
          заключенные с Express24 договоры, для исполнения обязательств перед Мерчантом и для целей статистики и
          оптимизации рекламных сообщений.
        </p>
        <p>
          <b>8.7.</b> Express24 получает информацию об ip-адресе посетителя Сайта. Данная информация не используется для
          установления личности посетителя.
        </p>
        <p>
          <b>8.8.</b> Express24 не несет ответственности за сведения, предоставленные Мерчантом на Сайте в общедоступной
          форме.
        </p>
        <p>
          <b>8.9.</b> Express24 вправе осуществлять записи телефонных разговоров с Мерчантом. При этом Express24
          обязуется предотвращать попытки несанкционированного доступа к информации, полученной в ходе телефонных
          переговоров, и/или передачу ее третьим лицам, не имеющим непосредственного отношения к исполнению заказов.
        </p>
        <p>
          <b>8.10.</b> Принимая данное Соглашение, Мерчант предоставляет Express24, в соответствии с требованиями
          законодательства о персональных данных, бессрочное согласие на обработку своих персональных данных (в том
          числе, но не исключая: учетные данные, номер сотового телефона, ФИО, регистрационные данные, местоположение а
          также любые другие персональные данные необходимые для использования сервиса Express24), включая сбор, запись,
          систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
          данных, совершаемых с использованием средств автоматизации или без использования таких средств, с целью
          исполнения настоящего Соглашения. Настоящим Мерчант также дает согласие на передачу любых персональных данных,
          предоставленных им Express24 по настоящему Соглашению, третьим лицам в целях исполнения настоящего Соглашения.
        </p>
        <p>
          <b>8.11.</b> Express24 гарантирует Мерчанту принятие всех необходимых мер по обеспечению конфиденциальности
          персональных данных Мерчанта в соответствии с требованиями действующего законодательства Республики
          Узбекистан. Согласие Мерчанта на обработку (в том числе передачу третьим лицам) его персональных данных
          является бессрочным, при этом согласие на обработку персональных данных может быть отозвано Мерчантом в любое
          время, путем отправки электронного письма по адресу info@express24.uz
        </p>

        <h3 className={styles.SubTitle}>9. Общие положения</h3>
        <p>
          <b>9.1.</b> Настоящее Соглашение регулируется нормами действующего законодательства РУз.
        </p>
        <p>
          <b>9.2.</b> Все возможные споры по поводу Соглашения разрешаются согласно нормам действующего законодательства
          РУз.
        </p>
        <p>
          <b>9.3.</b> Ввиду безвозмездности условий настоящего соглашения нормы о защите прав потребителей не могут быть
          применимы к отношениям между Мерчантом и Express24.
        </p>
        <p>
          <b>9.4.</b> Ничто в Соглашении не может пониматься как установление между Мерчантом и Express24 агентских
          отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо
          каких-то иных отношений, прямо не предусмотренных настоящим Соглашением.
        </p>
        <p>
          <b>9.5.</b> По вопросам, связанным с исполнением Соглашения, необходимо обращаться по адресу местонахождения
          Express24: Республика Узбекистан, г. Ташкент, ул. Урикзор 7а, ООО «EXPRESS24 APP».
        </p>

        <div className={styles.GoUpButton}>
          <a href="#agreement-up">
            <Button kind="primary"
              size="large"
              icon={<Icon name="chevron-up" />}
            />
          </a>
        </div>
      </div>
    </>
  );
};
