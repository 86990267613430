import dayjs from 'dayjs';
import {Fragment} from 'react';
import {MenuProduct} from '@api/v2';
import {useUnit} from 'effector-react';
import {history} from '@shared/history';
import {useMemo, useState} from 'react';
import styles from './styles.module.scss';
import * as model from '../../../../model';
import {compileCdnUrl} from '@shared/lib/compile-cdn-url';
import noCoverProduct from '../../../assests/no-cover-product.sprite.svg';
import {Icon, Image, Loader, Ratio, Toggle, Typography} from '@express-24/theseus-ui';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

type Props = {
  kind?: 'search';
  product: MenuProduct;
};

export const ProductCard = ({product, kind}: Props) => {
  if (!product) return null;

  const {
    handleActivityChange,
    handleActivityModalOpen,

    selectedStoreId,
    selectedBranchId,
    selectedSubmenuId,
    productLoadingStateByIds,
  } = useUnit({
    handleActivityChange: model.productSwitchToggleRequested,
    handleActivityModalOpen: model.productActivityModalOpenRequested,

    selectedStoreId: model.$selectedStoreId,
    selectedBranchId: model.$selectedBranchId,
    selectedSubmenuId: model.$selectedSubmenuId,
    productLoadingStateByIds: model.$productLoadingStateByIds,
  });
  const {t} = useTranslation();

  const [isBroken, setBroken] = useState(false);

  const handleProductCardClick = (productId: number) => {
    history.push({
      pathname: `/menu/${selectedStoreId}/product/${productId}`,
      search: `branchId=${selectedBranchId}`,
    });
  };

  const handleSwitchToggle = ({id, checked}: {id: number; checked: boolean}) => {
    if (!checked) {
      return handleActivityModalOpen(id);
    }

    handleActivityChange({
      productId: id,
      checked: checked,
    });
  };

  const availabilityCaptionView = useMemo(() => {
    const {availability} = product;
    const {isAvailable, unavailableTill} = availability;

    if (isAvailable) return null;

    const isDateValid = dayjs(unavailableTill).isValid();

    if (!isAvailable && isDateValid) {
      const date = dayjs(unavailableTill).format('DD.MM.YY, hh:mm');

      return (
        <Typography align="right"
          level="sub-text"
          kind="secondary"
        >
          {t('off_date', {date})}
        </Typography>
      );
    }

    if (!isAvailable && !unavailableTill) {
      return (
        <Typography align="right"
          kind="warning"
          level="sub-text"
        >
          {t('disabled')}
        </Typography>
      );
    }
  }, [product, i18n.language]);

  const coverView = useMemo(() => {
    const url = Array.isArray(product.cover) ? product.cover[0] : product.cover;

    const isCoverInvalid = !product || isBroken;

    if (isCoverInvalid) {
      return (
        <Ratio ratio={[48, 48]}>
          <Image fit="cover"
            src={noCoverProduct}
          />
        </Ratio>
      );
    }

    return (
      <Ratio ratio={[48, 48]}>
        <Image fit="cover"
          src={compileCdnUrl(url, 300)}
          onError={() => setBroken(true)}
        />
      </Ratio>
    );
  }, [product, isBroken]);

  return (
    <Fragment>
      <div key={product.id}
        onClick={() => handleProductCardClick(product.id)}
      >
        <div
          key={product.id}
          data-kind={kind}
          className={styles.Wrapper}
          data-is-caption={Boolean(availabilityCaptionView)}
        >
          {productLoadingStateByIds[product.id] ? (
            <div className={styles.SwitchLoader}>
              <Loader size={24}
                strokeWidth={3}
              />
            </div>
          ) : (
            <div className={styles.Switch}
              onClick={(event) => event.stopPropagation()}
            >
              <Toggle
                onChange={({currentTarget}) =>
                  handleSwitchToggle({
                    id: product.id,
                    checked: currentTarget.checked,
                  })
                }
                checked={product.availability.isAvailable}
              />
            </div>
          )}
          <div className={styles.Content}>
            <div className={styles.ContentCover}>{coverView}</div>
            <div className={styles.ContentDetails}>
              <Typography level="text"
                lineClamp={1}
                className={styles.ContentTitle}
              >
                {product.name}
              </Typography>
              <Typography bold
                level="text"
              >
                {product.price.sold.toLocaleString()} {product.price.currency}
              </Typography>
            </div>
          </div>
          <div className={styles.Chevron}>
            <Icon size={20}
              name="chevron-right"
            />
          </div>

          {kind !== 'search' && <div className={styles.Caption}>{availabilityCaptionView}</div>}
        </div>
      </div>
    </Fragment>
  );
};
