import {CdnPath, DimensionConfig, DimensionValue} from './types';

const toDefaultConfig = (dimensions: DimensionValue | DimensionConfig): DimensionConfig => {
  if (typeof dimensions === 'number') {
    return {
      width: dimensions,
      height: dimensions,
    };
  }

  return dimensions;
};

export const compileCdnUrl = (path: CdnPath, dimensions: DimensionValue | DimensionConfig): string => {
  if (!path) return '';
  const params = {
    width: ':w',
    height: ':h',
  };

  const {width, height} = toDefaultConfig(dimensions);

  return path.replace(params.width, String(Math.round(width))).replace(params.height, String(Math.round(height)));
};
