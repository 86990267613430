import {useUnit} from 'effector-react';
import {history} from '@shared/history';
import styles from './styles.module.scss';
import {FallbackLoader} from '../../atoms';
import * as model from '../../../../model';
import {ProductCard} from '../../molecules';
import {EmptyState} from '@widgets/empty-state';
import {useEffect, useMemo, useRef} from 'react';
import {Typography} from '@express-24/theseus-ui';
import {ActivityModal} from '@widgets/activity-modal/ui';
import {ProductsSortModal} from '../products-sort-modal';
import {$isProductsLoadingIdsEmpty} from '../../../../model';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const ProductsList = () => {
  const {
    handleSortClick,
    handleActivityModalClose,
    handleProductActivityChange,

    storeId,
    branchId,
    products,
    selectedProductId,

    isMenuNested,
    isSearchActive,
    isProductsLoading,
    isActivityModalOpen,
    isProductsLoadingIdsEmpty,
  } = useUnit({
    handleSortClick: model.productsSortModalOpenRequested,
    handleProductActivityChange: model.productSwitchToggleRequested,
    handleActivityModalClose: model.productActivityModalCloseRequested,

    products: model.$products,
    storeId: model.$selectedStoreId,
    branchId: model.$selectedBranchId,
    selectedProductId: model.$selectedProductId,

    isMenuNested: model.$isMenuNested,
    isSearchActive: model.$isSearchActive,
    isSortPending: model.$isProductsSortPending,
    isProductsLoading: model.$isProductsPending,
    isActivityModalOpen: model.$isProductActivityModalOpen,
    isProductsLoadingIdsEmpty: model.$isProductsLoadingIdsEmpty,
  });
  const {t} = useTranslation();
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sectionRef.current) return;

    sectionRef.current.scrollTop = 0;
  }, [products]);

  const handleProductDisable = (selectedTime: string) => {
    if (!selectedProductId) return;

    handleProductActivityChange({
      checked: false,
      productId: selectedProductId,
      unavailableTill: selectedTime,
    });
  };

  const emptyView = useMemo(() => {
    if (!products) {
      return (
        <div className={styles.EmptyViewWrapper}>
          <EmptyState
            illustration="noOrders"
            title={t('menu.no_selected_category')}
            description={t('menu.no_selected_category_tip')}
          />
        </div>
      );
    }

    if (!products.list.length) {
      return (
        <div className={styles.EmptyViewWrapper}>
          <EmptyState illustration="noBranches"
            title={t('menu.no_products')}
            description={t('menu.contact_manager')}
          />
        </div>
      );
    }
  }, [products, i18n.language]);

  const loadingView = useMemo(() => {
    if (!products) return null;
    if (!isProductsLoading) return null;

    return <FallbackLoader />;
  }, [isProductsLoading]);

  const contentView = useMemo(() => {
    if (!products) return null;

    return (
      <>
        <div className={styles.SectionHeader}>
          <Typography semibold
            level="h5"
            lineClamp={2}
          >
            {products.title}
          </Typography>
          <div onClick={handleSortClick}
            className={styles.SortButton}
          >
            <Typography level="text">{t('actions.sort')}</Typography>
          </div>
        </div>
        <div ref={sectionRef}
          className={styles.SectionList}
          data-is-nested={!isSearchActive && isMenuNested}
        >
          {products.list.map((product) => (
            <ProductCard key={product.id}
              product={product}
            />
          ))}
        </div>
      </>
    );
  }, [products, i18n.language]);

  const ACTIVITY_OPTIONS = [
    {
      value: 'next_60m',
      name: t('for_minutes', {minutes: 60}),
    },
    {
      value: 'next_period',
      name: t('next_period'),
    },
    {
      value: 'unknown',
      name: t('undefined_period'),
      caption: t('disable_in_client'),
    },
  ];

  return (
    <div className={styles.Section}>
      {emptyView}

      {loadingView}

      {contentView}

      <ActivityModal
        visible={isActivityModalOpen}
        onSubmit={handleProductDisable}
        options={ACTIVITY_OPTIONS}
        onClose={handleActivityModalClose}
        loading={!isProductsLoadingIdsEmpty}
      />

      <ProductsSortModal />
    </div>
  );
};
