import {createEffect, sample} from 'effector';
import {fetchStores} from '@api/v2';
import {attachSession} from '@entities/session';
import {attachedStoresChanged} from '@entities/attached-stores';

export const fetchStoresApiFx = createEffect(fetchStores);

export const fetchStoresFx = attachSession({
  effect: fetchStoresApiFx,
  mapParams: (_, authorization) => ({
    headers: {
      authorization,
    },
  }),
});

sample({
  source: fetchStoresFx.doneData,
  fn: ({data: stores}) => stores,
  target: attachedStoresChanged,
});
