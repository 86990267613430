import {DropResult} from 'react-beautiful-dnd';
import {attachSession} from '@entities/session';
import {createEffect, createEvent, createStore, guard, sample} from 'effector';
import {MenuSections, MenuUpdateParams, sortMenu, updateMenu, sortProducts} from '@api/v2';
import {
  StoreId,
  Products,
  SubmenuSortCredentials,
  MenuSortRequestConfig,
  ProductsSortRequestConfig,
} from './model.types';

const handleSort = <T>(originalList: Array<T>, {destination, source}: DropResult) => {
  if (!originalList) return;
  if (!destination) return;
  if (destination.index === source.index) return;

  const dragStartIndex = source.index;
  const dragEndIndex = destination.index;

  const result = Array.from(originalList);
  const [removed] = result.splice(dragStartIndex, 1);
  result.splice(dragEndIndex, 0, removed);

  return result;
};

export const menuSortEnabled = createEvent();
export const menuSortDisabled = createEvent();
export const menuSortRequested = createEvent<StoreId>();
export const menuSorted = createEvent<DropResult>();
export const menuSortInitialized = createEvent();
export const menuSortAccomplished = createEvent();
export const submenuSortEnabled = createEvent();
export const submenuSortDisabled = createEvent();
export const submenuSortRequested = createEvent<SubmenuSortCredentials>();
export const submenuSorted = createEvent<DropResult>();
export const submenuSortInitialized = createEvent();
export const submenuSortAccomplished = createEvent();
export const productsSortModalOpened = createEvent();
export const productsSortModalClosed = createEvent();
export const productSortRequested = createEvent<StoreId>();
export const productsSorted = createEvent<DropResult>();
export const productsSortInitialized = createEvent();
export const productsSortAccomplished = createEvent();

export const $orderedProducts = createStore<Products>([]);
export const $orderedMenuSections = createStore<MenuSections | null>(null);
export const $orderedSubmenuSections = createStore<MenuSections | null>(null);

export const $isMenuSortActive = createStore<boolean>(false);
export const $isMenuSortPending = createStore<boolean>(false);
export const $isSubmenuSortActive = createStore<boolean>(false);
export const $isSubmenuSortPending = createStore<boolean>(false);
export const $isProductsSortPending = createStore<boolean>(false);
export const $isProductsSortModalOpened = createStore<boolean>(false);

export const sortProductsFx = attachSession({
  effect: createEffect(sortProducts),
  mapParams: ({path, data}: ProductsSortRequestConfig, authorization) => ({
    path,
    data,
    headers: {
      authorization,
    },
  }),
});

export const sortMenuFx = attachSession({
  effect: createEffect(sortMenu),
  mapParams: ({path, data}: MenuSortRequestConfig, authorization) => ({
    path,
    data,
    headers: {
      authorization,
    },
  }),
});

export const sortSubmenuFx = attachSession({
  effect: createEffect(updateMenu),
  mapParams: ({path, data}: MenuUpdateParams, authorization) => ({
    path,
    data,
    headers: {
      authorization,
    },
  }),
});

$orderedProducts.on(productsSorted, (products, dropResult) => handleSort(products, dropResult));

$orderedMenuSections.on(menuSorted, (menu, dropResult) => {
  if (!menu) return null;

  return handleSort(menu, dropResult);
});

$orderedSubmenuSections.on(submenuSorted, (submenu, dropResult) => {
  if (!submenu) return null;

  return handleSort(submenu, dropResult);
});

$isMenuSortActive.on(menuSortEnabled, () => true).on(menuSortDisabled, () => false);

$isSubmenuSortActive.on(submenuSortEnabled, () => true).on(submenuSortDisabled, () => false);

$isMenuSortPending.on(menuSortInitialized, () => true).on(menuSortAccomplished, () => false);

$isSubmenuSortPending.on(submenuSortInitialized, () => true).on(submenuSortAccomplished, () => false);

$isProductsSortPending.on(productsSortInitialized, () => true).on(productsSortAccomplished, () => false);

$isProductsSortModalOpened
  .on(productsSortModalOpened, () => true)
  .on([productsSortModalClosed, sortProductsFx.done], () => false);

sample({
  source: guard({
    source: $orderedMenuSections,
    filter: (menu): menu is MenuSections => menu !== null,
  }),
  clock: menuSortRequested,
  fn: (menu, storeId) => ({
    path: {
      storeId,
    },
    data: {
      sort: menu.map(({id}) => id),
    },
  }),
  target: sortMenuFx,
});

sample({
  source: guard({
    source: $orderedSubmenuSections,
    filter: (submenu): submenu is MenuSections => submenu !== null,
  }),
  clock: submenuSortRequested,
  fn: (menu, {storeId, menuId}) => ({
    path: {
      menuId,
      storeId,
    },
    data: {
      sort: menu.map(({id}) => id),
    },
  }),
  target: sortSubmenuFx,
});

sample({
  source: guard({
    source: $orderedProducts,
    filter: (products): products is Products => products !== null,
  }),
  clock: productSortRequested,
  fn: (products, storeId) => ({
    path: {
      storeId,
    },
    data: {
      sort: products.map(({id}) => id),
    },
  }),
  target: sortProductsFx,
});

sample({
  clock: menuSortRequested,
  target: menuSortInitialized,
});

sample({
  clock: sortMenuFx.fail,
  target: menuSortAccomplished,
});

sample({
  clock: menuSortAccomplished,
  target: menuSortDisabled,
});

sample({
  clock: submenuSortRequested,
  target: submenuSortInitialized,
});

sample({
  clock: sortSubmenuFx.fail,
  target: submenuSortAccomplished,
});

sample({
  clock: submenuSortAccomplished,
  target: submenuSortDisabled,
});

sample({
  clock: productSortRequested,
  target: productsSortInitialized,
});

sample({
  clock: sortProductsFx.fail,
  target: productsSortAccomplished,
});
