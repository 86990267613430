import {combine, createEffect, createEvent, createStore, guard, sample} from 'effector';
import {fetchOrder, fetchOrderHistory, OrderContract, OrderHistoryContract} from '@api/v2';
import {attachSession} from '@entities/session';
import {$stores, $storesOptions, StoreOption} from '@entities/attached-stores';
import {FilterValues, OrderHistoryParams, PaginationData} from './model.types';
import {getTodayDates} from '@shared/lib/dayjs';

const initialFiltersValue = {
  period: getTodayDates(),
  dateFrom: '',
  dateTo: '',
  orderId: '',
};

export const pageMounted = createEvent();
export const pageUnmounted = createEvent();
export const filterValueChanged = createEvent<FilterValues>();
export const storeSelected = createEvent<number | null>();
export const buttonSearchClicked = createEvent<OrderHistoryParams>();
export const orderDetailsClosed = createEvent();
export const orderDetailsOpened = createEvent<number>();
export const paginationChanged = createEvent<PaginationData>();
export const resetFiltersClicked = createEvent();

export const $filterValues = createStore<FilterValues>(initialFiltersValue);
export const $selectedStoreId = createStore<number | null>(null);
export const $orderHistory = createStore<OrderHistoryContract | null>(null);
export const $isOrderDetailsOpened = createStore<boolean>(false);
export const $orderDetails = createStore<OrderContract | null>(null);
export const $paginationData = createStore<PaginationData>({pageNumber: 1, offset: 0});

const getPeriod = (filterValues: FilterValues) => {
  if (filterValues.dateFrom && filterValues.dateTo) {
    return {
      dateFrom: filterValues.dateFrom,
      dateTo: filterValues.dateTo,
    };
  }

  if (filterValues.period) {
    const [dateFrom, dateTo] = filterValues.period.split('/');

    return {dateFrom, dateTo};
  }

  return {dateFrom: undefined, dateTo: undefined};
};

export const $orderHistoryParams = combine($filterValues, $paginationData, (filterValues, paginationData) => {
  if (!filterValues) return null;

  const {dateFrom, dateTo} = getPeriod(filterValues);

  return {
    dateFrom,
    dateTo,
    branchesIds: filterValues.branchesIds,
    paymentTypesIds: filterValues.paymentTypes,
    statusesIds: filterValues.status ? [filterValues.status] : undefined,
    orderTypesIds: filterValues.orderTypes,
    offset: paginationData.offset,
    orderId: filterValues.orderId ? filterValues.orderId : undefined,
  };
});

export const $filteredBranches = combine($selectedStoreId, $stores, (selectedStoreId, stores) => {
  if (!stores) return [];

  const currentStore = stores.find((item) => item.id === selectedStoreId);

  if (!currentStore) return [];

  return currentStore.branches.map((item) => ({
    label: item.name,
    caption: item.name,
    value: item.id,
  }));
});

$filterValues.on(filterValueChanged, (prevValues, newValue) => {
  return {
    ...prevValues,
    ...newValue,
  };
});

export const fetchOrderHistoryApiFx = createEffect(fetchOrderHistory);
export const fetchOrderApiFx = createEffect(fetchOrder);

export const fetchOrderFx = attachSession({
  effect: fetchOrderApiFx,
  mapParams: (orderId: number, authorization) => ({
    params: {
      orderId: orderId,
    },
    headers: {
      authorization,
    },
  }),
});

export const fetchOrderHistoryFx = attachSession({
  effect: fetchOrderHistoryApiFx,
  mapParams: ({storeId, params}: OrderHistoryParams, authorization) => ({
    storeId,
    params,
    headers: {
      authorization,
    },
  }),
});

sample({
  clock: storeSelected,
  target: $selectedStoreId,
});

sample({
  source: guard({
    source: $storesOptions,
    clock: pageMounted,
    filter: (data): data is StoreOption[] => Boolean(data),
  }),
  fn: (data) => data[0].value,
  target: storeSelected,
});

sample({
  clock: buttonSearchClicked,
  target: fetchOrderHistoryFx,
});

sample({
  clock: fetchOrderHistoryFx.doneData,
  fn: ({data}) => data,
  target: $orderHistory,
});

sample({
  clock: orderDetailsOpened,
  target: fetchOrderFx,
});

sample({
  clock: paginationChanged,
  target: $paginationData,
});

sample({
  clock: storeSelected,
  fn: () => ({
    branchesIds: undefined,
  }),
  target: filterValueChanged,
});

$isOrderDetailsOpened
  .on(orderDetailsOpened, () => true)
  .on(orderDetailsClosed, () => false)
  .on(pageUnmounted, () => false);

$orderDetails.on(fetchOrderFx.doneData, (_, {data}) => data).on(orderDetailsClosed, () => null);

$filterValues.on(resetFiltersClicked, () => initialFiltersValue);
