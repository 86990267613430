import {useMemo} from 'react';
import {useUnit} from 'effector-react';
import {Button, Row, Space, Counter, Typography} from '@express-24/theseus-ui';
import styles from './styles.module.scss';
import * as model from '../../../../model';
import {ProductCombination, ProductForReplacement} from '../../../../model/model.types';
import {ProductDetailsContract} from '@api/v2';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

type FooterProps = {
  hasUnselectedOptions: boolean;
  onAddClick: () => void;
  product: ProductDetailsContract;
  onChooseOptionClick: () => void;
  cart: ProductForReplacement[] | null;
  productCombinations: ProductCombination[] | null;
};

export const ProductDialogFooter = ({
  hasUnselectedOptions,
  onAddClick,
  cart,
  product,
  onChooseOptionClick,
  productCombinations,
}: FooterProps) => {
  const {handleCountChange, count, quantityInStock, selectedOptionByPropertyIdMap, price} = useUnit({
    handleCountChange: model.productCountChanged,
    count: model.$productCount,
    quantityInStock: model.$quantityInStock,
    selectedOptionByPropertyIdMap: model.$selectedOptionByPropertyId,
    price: model.$price,
  });
  const {t} = useTranslation();

  const quantityInCart = useMemo(() => {
    if (!cart) return 0;

    const currentProductData = cart.find((item) => product.id === (item.menuProductId || item.id));

    return currentProductData ? currentProductData.count : 0;
  }, [cart]);

  const isCombinationAvailable = useMemo(() => {
    if (!productCombinations) return false;
    if (!Object.keys(selectedOptionByPropertyIdMap).length) return false;

    return !!productCombinations.find((combination) => {
      return JSON.stringify(combination) === JSON.stringify(selectedOptionByPropertyIdMap);
    });
  }, [productCombinations, selectedOptionByPropertyIdMap]);

  const areAllPropertiesSelected = useMemo(() => {
    if (!productCombinations) return false;
    if (!productCombinations.length) return false;

    return Object.keys(productCombinations[0]).length === Object.keys(selectedOptionByPropertyIdMap).length;
  }, [productCombinations, selectedOptionByPropertyIdMap]);

  const modifiedQuantityInStock = quantityInStock - quantityInCart;

  const priceView = useMemo(() => {
    if (!price) return null;

    const modifiedPrice = (price.sold * count).toLocaleString() + ' ' + price.currency;

    return (
      <Typography level="sub-text"
        semibold
      >
        {modifiedPrice}
      </Typography>
    );
  }, [price, count]);

  const combinationAccessibility = useMemo(() => {
    if (isCombinationAvailable) return;
    if (!areAllPropertiesSelected) return;

    return (
      <Typography level="sub-text"
        kind="warning"
      >
        {t('orders.unavailable_combination')}
      </Typography>
    );
  }, [isCombinationAvailable, areAllPropertiesSelected, i18n.language]);

  const fewInStockLabel = useMemo(() => {
    const minProductCount = 5;

    if (quantityInStock > minProductCount) return;

    return (
      <Typography level="sub-text"
        kind="warning"
      >
        {t('left')} {modifiedQuantityInStock}
      </Typography>
    );
  }, [quantityInStock, modifiedQuantityInStock, i18n.language]);

  return (
    <div className={styles.Footer}>
      <>
        <Row justify="space-between">
          <Row isVerticalAxis={true}>
            {fewInStockLabel}
            {combinationAccessibility}
          </Row>
          {priceView}
        </Row>
        <Space margin={8} />
        <Row justify="space-between"
          align="center"
          wrap="nowrap"
        >
          <Counter
            kind="secondary"
            size="large"
            count={modifiedQuantityInStock <= 0 ? 0 : count}
            min={1}
            max={modifiedQuantityInStock}
            onUpdate={handleCountChange}
          />
          <Space margin={8} />
          {hasUnselectedOptions ? (
            <Button fluid
              size="large"
              onClick={onChooseOptionClick}
            >
              {t('orders.choose_options')}
            </Button>
          ) : (
            <Button
              fluid
              size="large"
              onClick={onAddClick}
              disabled={!isCombinationAvailable && areAllPropertiesSelected}
            >
              {t('actions.add')}
            </Button>
          )}
        </Row>
      </>
    </div>
  );
};
