import styles from './styles.module.scss';
import {Image, Ratio, Skeleton} from '@express-24/theseus-ui';
import {compileCdnUrl} from '@shared/lib/compile-cdn-url';
import {useEffect, useMemo, useState} from 'react';

type CoverProps = {
  src: string | string[];
  fallback?: () => void;
};

export const ProductCover = ({src, fallback}: CoverProps) => {
  const [isLoaded, setLoaded] = useState(false);
  const [isBroken, setBroken] = useState(false);

  useEffect(() => {
    if (!isBroken && src.length !== 0) return;

    if (fallback) {
      fallback();
    }
  }, [isBroken, src]);

  const coverView = useMemo(() => {
    if (src.length === 0) return null;
    if (isBroken) return null;

    const url = Array.isArray(src) ? src[0] : src;

    return (
      <Ratio ratio={[440, 244]}>
        <Image
          fluid
          src={compileCdnUrl(url, {width: 732, height: 1500})}
          fit="cover"
          onError={() => setBroken(true)}
          onLoad={() => setLoaded(true)}
        />
      </Ratio>
    );
  }, [src, isBroken]);

  return (
    coverView && (
      <div className={styles.Cover}>
        <div className={styles.Image}
          data-is-loaded={isLoaded}
        >
          {coverView}
        </div>
        {!isLoaded && (
          <Ratio ratio={[440, 244]}>
            <div className={styles.Skeleton}>
              <Skeleton fluid />
            </div>
          </Ratio>
        )}
      </div>
    )
  );
};
