import {createEffect, createEvent, createStore, sample, guard} from 'effector';
import {BranchDetailsContract, fetchBranchDetails, updateBranchActivity} from '@api/v2';
import {attachSession} from '@entities/session';
import {BranchActivityParams, BranchActivitySwitcherParams, BranchDetailsParams} from './model.types';
import {$storesOptions, StoreOption} from '@entities/attached-stores';
import {fetchStoresFx} from '@features/user/attached-stores';
import i18n from 'i18next';

export const pageMounted = createEvent();
export const pageUnmounted = createEvent();
export const storeSelected = createEvent<number | null>();
export const branchSelected = createEvent<BranchDetailsParams>();
export const branchActivityPeriodChosen = createEvent<string>();
export const branchActivityChangingConfirmed = createEvent<BranchActivityParams>();
export const branchActivityModalOpened = createEvent<number>();
export const branchActivityModalClosed = createEvent();
export const branchActivitySwitcherChanged = createEvent<BranchActivitySwitcherParams>();

export const $selectedStoreId = createStore<number | null>(null);
export const $selectedBranchId = createStore<number | null>(null);
export const $branchDetails = createStore<BranchDetailsContract | null>(null);
export const $isBranchActivityModalVisible = createStore<boolean>(false);
export const $selectedBranchActivityPeriod = createStore<string>('next_30m');
export const $currentBranchActivityId = createStore<number | null>(null);

export const $branchSchedule = $branchDetails.map((branchDetails) => {
  if (!branchDetails) return null;

  const schedule: Array<{day: string; time: Array<{start: string; end: string}>}> = [];

  for (const day in branchDetails.schedule) {
    schedule.push({
      day: i18n.t(`branches_management.${day}`),
      time: branchDetails.schedule[day],
    });
  }

  return schedule;
});

export const fetchBranchDetailsApiFx = createEffect(fetchBranchDetails);
export const updateBranchActivityApiFx = createEffect(updateBranchActivity);

export const fetchBranchDetailsFx = attachSession({
  effect: fetchBranchDetailsApiFx,
  mapParams: ({storeId, branchId}: BranchDetailsParams, authorization) => ({
    storeId,
    branchId,
    headers: {
      authorization,
    },
  }),
});

export const updateBranchActivityFx = attachSession({
  effect: updateBranchActivityApiFx,
  mapParams: ({storeId, branchId, body}: BranchActivityParams, authorization) => ({
    storeId,
    branchId,
    body,
    headers: {
      authorization,
    },
  }),
});

sample({
  source: guard({
    source: $storesOptions,
    clock: pageMounted,
    filter: (data): data is StoreOption[] => Boolean(data),
  }),
  fn: (data) => data[0].value,
  target: storeSelected,
});

sample({
  clock: storeSelected,
  target: $selectedStoreId,
});

sample({
  clock: branchSelected,
  fn: (data) => data.branchId,
  target: $selectedBranchId,
});

sample({
  clock: branchSelected,
  target: fetchBranchDetailsFx,
});

sample({
  clock: fetchBranchDetailsFx.doneData,
  fn: ({data}) => data,
  target: $branchDetails,
});

sample({
  clock: branchActivityPeriodChosen,
  target: $selectedBranchActivityPeriod,
});

sample({
  clock: branchActivityChangingConfirmed,
  source: updateBranchActivityFx.pending,
  filter: (isUpdating) => !isUpdating,
  fn: (_, data) => data,
  target: updateBranchActivityFx,
});

sample({
  clock: branchActivitySwitcherChanged,
  source: updateBranchActivityFx.pending,
  filter: (isUpdating, {targetBranchActivity}) => {
    if (isUpdating) return false;
    return targetBranchActivity;
  },
  fn: (_, {storeId, branchId}) => ({
    storeId,
    branchId,
    body: {
      activity: {
        isActive: true,
      },
    },
  }),
  target: updateBranchActivityFx,
});

sample({
  clock: branchActivitySwitcherChanged,
  filter: ({targetBranchActivity}) => !targetBranchActivity,
  fn: ({branchId}) => branchId,
  target: branchActivityModalOpened,
});

sample({
  clock: branchActivityModalOpened,
  target: $currentBranchActivityId,
});

sample({
  clock: updateBranchActivityFx.doneData,
  target: [fetchStoresFx, branchActivityModalClosed],
});

$isBranchActivityModalVisible.on(branchActivityModalOpened, () => true).on(branchActivityModalClosed, () => false);
$selectedBranchActivityPeriod.on(branchActivityModalClosed, () => 'next_30m');
