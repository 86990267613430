import {useMemo} from 'react';
import {useUnit} from 'effector-react';
import {history} from '@shared/history';
import styles from './styles.module.scss';
import * as model from '../../../../model';
import {EmptyState} from '@widgets/empty-state';
import {Typography} from '@express-24/theseus-ui';
import {MenuCell} from '../../molecules/menu-cell';
import {FallbackLoader, MenuLoader} from '../../../ui';
import {Droppable, Draggable, DragDropContext} from 'react-beautiful-dnd';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const SubmenuList = () => {
  const {
    handleSortSubmit,
    handleSortEnable,
    handleSortDragEnd,
    handleMenuSwitchToggle,

    menu,
    menuName,
    selectedMenuId,
    selectedBranchId,
    selectedSubmenuId,

    isMenuNested,
    isSortActive,
    isSortPending,
    isMenuPending,
    isMenuAlreadyFetched,
  } = useUnit({
    handleSortSubmit: model.submenuSortSubmitted,
    handleSortDragEnd: model.submenuSortDragEnded,
    handleSortEnable: model.submenuSortEnableRequested,
    handleMenuSwitchToggle: model.submenuSwitchToggleRequested,

    menuName: model.$selectedMenuName,
    menu: model.$orderedSubmenuSections,
    selectedMenuId: model.$selectedMenuId,
    selectedBranchId: model.$selectedBranchId,
    selectedSubmenuId: model.$selectedSubmenuId,

    isMenuNested: model.$isMenuNested,
    isSortActive: model.$isSubmenuSortActive,
    isSortPending: model.$isSubmenuSortPending,
    isMenuPending: model.$isSubmenuRequestPending,
    isMenuAlreadyFetched: model.$isSubmenuAlreadyFetched,
  });
  const {t} = useTranslation();

  const handleMenuClick = (submenuId: number) => {
    if (isSortActive) return;
    if (!selectedMenuId) return;

    history.replace({
      pathname: '/menu',
      search: `branchId=${selectedBranchId}&menuId=${selectedMenuId}&submenuId=${submenuId}`,
    });
  };

  const sortLoaderView = useMemo(() => {
    if (!isSortPending) return null;

    return <FallbackLoader />;
  }, [isSortPending]);

  const contentLoadingView = useMemo(() => {
    if (isSortPending) return null;
    if (!isMenuPending) return null;
    if (isMenuAlreadyFetched) return null;

    return <MenuLoader />;
  }, [isSortPending, isMenuPending, isMenuAlreadyFetched]);

  const sortButtonView = useMemo(() => {
    if (!menu) return null;
    if (!menu.length) return null;

    if (isSortActive) {
      return (
        <div onClick={handleSortSubmit}
          className={styles.SortButton}
          data-is-sort-active={isSortActive}
        >
          <Typography level="text">{t('actions.save')}</Typography>
        </div>
      );
    }

    return (
      <div onClick={handleSortEnable}
        className={styles.SortButton}
      >
        <Typography level="text">{t('actions.sort')}</Typography>
      </div>
    );
  }, [isSortActive, menu, i18n.language]);

  const contentView = useMemo(() => {
    if (!menu) return null;
    if (isMenuPending && !isMenuAlreadyFetched) return null;

    if (!menu.length) {
      return (
        <div className={styles.EmptyView}>
          <EmptyState
            illustration="noBranches"
            title={t('menu.no_categories')}
            description={t('menu.contact_manager')}
          />
        </div>
      );
    }

    return (
      <>
        <div className={styles.Header}>
          <Typography semibold
            level="h5"
            lineClamp={2}
          >
            {menuName}
          </Typography>

          {sortButtonView}
        </div>
        <DragDropContext onDragEnd={handleSortDragEnd}>
          <Droppable droppableId="submenu">
            {({innerRef, placeholder}) => (
              <div ref={innerRef}
                className={styles.MenuWrapper}
              >
                {menu.map((section, index) => (
                  <Draggable
                    index={index}
                    key={section.id}
                    isDragDisabled={!isSortActive}
                    draggableId={section.id.toString()}
                  >
                    {(provided, snapshot) => (
                      <div {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <div key={section.id}
                          onClick={() => handleMenuClick(section.id)}
                        >
                          <MenuCell
                            isSubmenu={true}
                            section={section}
                            isSortActive={isSortActive}
                            dragging={snapshot.isDragging}
                            onSwitchToggle={handleMenuSwitchToggle}
                            selected={section.id === selectedSubmenuId}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  }, [
    menu,
    menuName,
    sortButtonView,
    selectedSubmenuId,
    isSortActive,
    isMenuPending,
    isMenuAlreadyFetched,
    i18n.language,
  ]);

  if (!isMenuNested) return null;
  if (!selectedMenuId) return null;

  return (
    <div className={styles.Container}>
      {sortLoaderView}

      {contentLoadingView}

      {contentView}
    </div>
  );
};
