import {identifyUserFx} from '@features/user/identify';
import {createEvent, createStore, sample} from 'effector';
import {sessionHydrationStarted, sessionCreated} from '@entities/session';
import {fetchStoresFx} from '@features/user/attached-stores';
import {NotificationContract} from '../../pages/orders/model';
import {notificationReceived} from '@features/live-notifications';
import {fetchPaymentTypesFx} from '@features/payment-types';

export const hasAccess = (needGroups: number[] | undefined, userGroup: number) => {
  if (!needGroups) return true;
  return needGroups.includes(userGroup);
};

export const appInitialized = createEvent();
export const notificationModalClosed = createEvent();

export const $notification = createStore<NotificationContract | null>(null);
export const $isNotificationModalOpen = createStore<boolean>(false);

sample({
  clock: appInitialized,
  target: sessionHydrationStarted,
});

sample({
  source: sessionCreated,
  target: [identifyUserFx, fetchStoresFx, fetchPaymentTypesFx],
});

$notification.on(notificationReceived, (_, notification) => notification);
$isNotificationModalOpen
  .on(notificationReceived, (_, notification) => notification.data.noty.showAsAlert)
  .on(notificationModalClosed, () => false);
