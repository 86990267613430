import React, {useMemo} from 'react';
import styles from './styles.module.scss';
import {useUnit} from 'effector-react';
import {
  $isOrderDetailsOpened,
  $orderDetails,
  $orderHistory,
  $paginationData,
  orderDetailsClosed,
  orderDetailsOpened,
  paginationChanged,
  fetchOrderFx,
} from '../../../../model';
import {Drawer, Space, Table, Pagination, Row, Dropdown} from '@express-24/theseus-ui';
import {EmptyState} from '@widgets/empty-state';
import {getOrderTypeTag, getPaymentTypeTag, getStatusTag} from '@shared/lib/generate-tags';
import {OrderDetails} from '@widgets/order-details';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const OrdersTable = () => {
  const {
    paginationData,
    isOrderDetailsOpened,
    orderHistory,
    handleOrderDetailsOpen,
    handleOrderDetailsClose,
    handlePaginationChange,
    orderDetails,
    isDetailsLoading,
  } = useUnit({
    paginationData: $paginationData,
    isOrderDetailsOpened: $isOrderDetailsOpened,
    orderHistory: $orderHistory,
    handleOrderDetailsOpen: orderDetailsOpened,
    handleOrderDetailsClose: orderDetailsClosed,
    handlePaginationChange: paginationChanged,
    orderDetails: $orderDetails,
    isDetailsLoading: fetchOrderFx.pending,
  });
  const {t} = useTranslation();

  const noHistoryView = useMemo(() => {
    if (!orderHistory) return;
    if (orderHistory.list.length) return;

    return (
      <div className={styles.NoHistory}>
        <EmptyState illustration="noUsers"
          title={t('orders_history.empty_history')}
        />
      </div>
    );
  }, [orderHistory, i18n.language]);

  const tableView = useMemo(() => {
    if (!orderHistory) return;
    if (!orderHistory.list.length) return;

    return (
      <div className={styles.Table}>
        <Table
          source={orderHistory.list}
          indexKey="id"
          onRowClick={(order) => handleOrderDetailsOpen(order.id)}
          columns={[
            {
              title: t('orders_history.order_number'),
              indexKey: 'id',
              render: (order) => `#${order.id}`,
            },
            {
              title: t('status'),
              indexKey: 'status',
              render: (order) => <Row justify="center">{getStatusTag(order.status.code, order.status.text)}</Row>,
            },
            {
              title: t('orders_history.order_view'),
              indexKey: 'orderType',
              render: (order) => <Row justify="center">{getOrderTypeTag(order.orderType.id)}</Row>,
            },
            {
              title: t('payment_type'),
              indexKey: 'payment',
              render: (order) => (
                <Row key={order.id}
                  justify="center"
                >
                  {getPaymentTypeTag(order.payment.type.id)}
                </Row>
              ),
            },
            {
              title: t('branch'),
              indexKey: 'store',
              render: (order) => order.store.branch.name,
            },
            {
              title: t('order_cost'),
              indexKey: 'payment',
              render: (order) => (
                <>
                  {order.payment.receipt.total.toLocaleString()} {t('sum')}
                </>
              ),
            },
            {
              title: t('order_accepted'),
              indexKey: 'date',
              render: (order) => {
                const acceptDate = order.date.accept;

                if (!acceptDate) return '-';
                return acceptDate;
              },
            },
            {
              title: t('orders_history.accepted_by'),
              indexKey: 'user',
              render: (order) => {
                if (!order.user) return '-';
                if (!order.user.login) return '-';
                if (!order.user.name) return order.user.login;

                return (
                  <Dropdown
                    trigger="hover"
                    content={(
                      <div className={styles.DropdownContent}>
                        {t('name')}: {order.user.name}
                      </div>
                    )}
                  >
                    <span className={styles.DropdownInfo}>{order.user.login}</span>
                  </Dropdown>
                );
              },
            },
          ]}
        />
      </div>
    );
  }, [orderHistory, i18n.language]);

  const paginationView = useMemo(() => {
    if (!orderHistory) return;
    if (!orderHistory.list.length) return;

    return (
      <Space padding={[32, 0, 20, 0]}>
        <Row justify="center">
          <Pagination
            contentLength={orderHistory.count}
            selected={paginationData.pageNumber}
            onChange={handlePaginationChange}
          />
        </Row>
      </Space>
    );
  }, [orderHistory]);

  return (
    <>
      <Space padding={[24, 0, 0, 0]}>
        {noHistoryView}
        {tableView}
        {paginationView}
      </Space>
      <Drawer visible={isOrderDetailsOpened}
        onOutsideClick={handleOrderDetailsClose}
        width="488px"
      >
        <OrderDetails
          orderDetails={orderDetails}
          isLoading={isDetailsLoading}
          handleOrderDetailsClose={handleOrderDetailsClose}
        />
      </Drawer>
    </>
  );
};
