import {createStore, createEvent, attach, sample, createEffect} from 'effector';
import cookies from 'js-cookie';
import {AccessToken, AttachSession, BasicFx, WsToken} from './model.types';
import {AuthorizationContract} from '@api/v2';

export const ACCESS_TOKEN_KEY = 'authentication-token';
export const WS_TOKEN_KEY = 'websocket-token';

export const tokenChanged = createEvent<AuthorizationContract>();
export const sessionClosed = createEvent();
export const sessionCreated = createEvent();
export const sessionHydrated = createEvent<AuthorizationContract>();
export const sessionHydrationStarted = createEvent();

export const $token = createStore<AccessToken | null>(null);
export const $wsToken = createStore<WsToken | null>(null);
export const $isAuthorized = $token.map((token) => {
  return token !== null;
});

export const attachSession = <FX extends BasicFx, P = void>({effect, mapParams}: AttachSession<FX, P>) => {
  const attached = attach({
    effect,
    source: $token,
    mapParams: (params: P, token) => {
      if (!token) {
        throw 'Unauthorized';
      }

      return mapParams(params, `Bearer ${token}`);
    },
  });

  sample({
    clock: attached.fail,
    //@ts-ignore
    filter: ({error}) => error.response.status === 401,
    target: sessionClosed,
  });

  return attached;
};

const saveSessionArtifactsFx = createEffect(({access, wsToken}: AuthorizationContract) => {
  if (!access) return;

  cookies.set(ACCESS_TOKEN_KEY, access, {
    expires: 365,
  });

  cookies.set(WS_TOKEN_KEY, wsToken, {
    expires: 365,
  });
});

export const hydrateSessionArtifactsFx = createEffect((): Promise<AuthorizationContract | null> => {
  return new Promise((resolve) => {
    const wsToken = cookies.get(WS_TOKEN_KEY);
    const token = cookies.get(ACCESS_TOKEN_KEY);
    const isExist = wsToken !== undefined && token !== undefined;

    if (isExist) {
      const artifacts = {
        wsToken,
        access: token,
      };

      return resolve(artifacts);
    }

    setTimeout(() => {
      resolve(null);
    }, 1500);
  });
});

export const cleanupSessionArtifactsFx = createEffect(() => {
  cookies.remove(ACCESS_TOKEN_KEY);
  cookies.remove(WS_TOKEN_KEY);
});

sample({
  source: sessionClosed,
  target: cleanupSessionArtifactsFx,
});

sample({
  source: sessionHydrationStarted,
  target: hydrateSessionArtifactsFx,
});

sample({
  source: hydrateSessionArtifactsFx.doneData,
  filter: (artifacts): artifacts is AuthorizationContract => Boolean(artifacts),
  target: sessionHydrated,
});

sample({
  source: tokenChanged,
  target: saveSessionArtifactsFx,
});

sample({
  source: $token.updates,
  filter: (token) => token !== null,
  target: sessionCreated,
});

$token.on(sessionClosed, () => null).on([tokenChanged, sessionHydrated], (_, artifacts) => artifacts.access);
$wsToken.on(sessionClosed, () => null).on([tokenChanged, sessionHydrated], (_, artifacts) => artifacts.wsToken);
