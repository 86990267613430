import axios from 'axios';
import i18n from 'i18next';

const BACKEND_ENV = process.env.RAZZLE_BACKEND_ENV;
const DEV = BACKEND_ENV !== 'PRODUCTION';

const BACKEND_HOST: any = {
  PRODUCTION: process.env.RAZZLE_BACKEND_HOST_PRODUCTION,
};

if (DEV) {
  BACKEND_HOST.DEVELOPMENT = process.env.RAZZLE_BACKEND_HOST_DEVELOPMENT;
}

const PROTOCOL = 'https';
//@ts-ignore
const BASE_URL = BACKEND_HOST[BACKEND_ENV];

const http = axios.create({
  baseURL: `${PROTOCOL}://${BASE_URL}/merchant/api`,
});

http.interceptors.request.use((config) => {
  config.headers['X-User-Language'] = i18n.language;

  return config;
});

export {http};
