import {debounce} from 'patronum/debounce';
import {fetchMenu, MenuSection} from '@api/v2';
import {attachSession} from '@entities/session';
import {SearchConfig, SearchRequestConfig} from './model.types';
import {sample, createEvent, createStore, createEffect} from 'effector';

export const searchRequested = createEvent<SearchConfig>();
export const searchDisabled = createEvent();

export const searchProductsFx = attachSession({
  effect: createEffect(fetchMenu),
  mapParams: ({path, params}: SearchRequestConfig, authorization) => ({
    path,
    params,
    headers: {
      authorization,
    },
  }),
});

export const $searchQuery = createStore<string | null>(null);
export const $foundProducts = createStore<Array<MenuSection> | null>(null);

export const $isSearchActive = createStore<boolean>(false);

$searchQuery.on(searchRequested, (_, {search}) => search).on(searchDisabled, () => null);

$foundProducts
  .on(searchProductsFx.doneData, (_, {data}) => data.sections)
  .on(searchRequested, (sections, {search}) => {
    if (search.length < 2) return null;

    return sections;
  })
  .on(searchDisabled, () => null);

$isSearchActive.on(searchRequested, (_, {search}) => search.length > 2).on(searchDisabled, () => false);

const searchChanged = debounce({
  source: searchRequested,
  timeout: 500,
});

sample({
  clock: searchChanged,
  fn: ({storeId, branchId, search}) => ({
    path: {
      storeId,
    },
    params: {
      search,
      branchId,
    },
  }),
  filter: ({search}) => search.length > 2,
  target: searchProductsFx,
});
