import React, {useEffect, useMemo} from 'react';
import styles from './styles.module.scss';
import {Button, DatePicker, Icon, Input, Row, Select, Space} from '@express-24/theseus-ui';
import {useUnit} from 'effector-react';
import {$storesOptions} from '@entities/attached-stores';
import {
  $filterValues,
  $orderHistoryParams,
  $paginationData,
  $selectedStoreId,
  buttonSearchClicked,
  filterValueChanged,
  resetFiltersClicked,
  storeSelected,
  $filteredBranches,
  fetchOrderHistoryFx,
} from '../../../../model';
import {$paymentTypesOptions} from '@entities/payment-types';
import {getTodayDates, getYesterdayDates, getLastDates} from '@shared/lib/dayjs';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

const MIN_STORES_COUNT_FOR_DISPLAYING_SELECT = 2;

export const Filters = () => {
  const {
    paymentTypesOptions,
    filteredBranches,
    paginationData,
    orderHistoryParams,
    storesOptions,
    filterValues,
    selectedStoreId,
    handleFilterValueChange,
    handleStoreSelect,
    handleButtonSearchClick,
    handleResetFiltersClick,
    isOrdersHistorySearchLoading,
  } = useUnit({
    paymentTypesOptions: $paymentTypesOptions,
    filteredBranches: $filteredBranches,
    paginationData: $paginationData,
    orderHistoryParams: $orderHistoryParams,
    storesOptions: $storesOptions,
    filterValues: $filterValues,
    selectedStoreId: $selectedStoreId,
    handleFilterValueChange: filterValueChanged,
    handleStoreSelect: storeSelected,
    handleButtonSearchClick: buttonSearchClicked,
    handleResetFiltersClick: resetFiltersClicked,
    isOrdersHistorySearchLoading: fetchOrderHistoryFx.pending,
  });
  const {t} = useTranslation();

  const DATE_OPTIONS = useMemo(() => {
    return [
      {
        value: getTodayDates(),
        label: t('orders_history.today'),
      },
      {
        value: getYesterdayDates(),
        label: t('orders_history.yesterday'),
      },
      {
        value: getLastDates(7),
        label: t('last_days', {days: 7}),
      },
      {
        value: getLastDates(14),
        label: t('last_days', {days: 14}),
      },
      {
        value: getLastDates(30),
        label: t('last_days', {days: 30}),
      },
      {
        value: 'custom',
        label: t('orders_history.choose_yourself'),
      },
    ];
  }, [i18n.language]);

  const ORDER_STATUSES_OPTIONS = [
    {
      value: 'F',
      label: t('orders_history.completed'),
    },
    {
      value: 'C',
      label: t('orders_history.canceled'),
    },
  ];

  const ORDER_TYPES_OPTIONS = [
    {
      value: 1,
      label: t('delivery'),
    },
    {
      value: 2,
      label: t('pre_order'),
    },
    {
      value: 3,
      label: t('takeaway'),
    },
  ];

  useEffect(() => {
    handleSubmitFilters();
  }, [paginationData.offset]);

  const handleSubmitFilters = () => {
    if (storesOptions) {
      const storeId = selectedStoreId || storesOptions[0].value;

      handleButtonSearchClick({storeId, params: orderHistoryParams});
    }
  };

  const datePickersView = useMemo(() => {
    if (filterValues.period !== 'custom') return;

    return (
      <>
        <Space padding={[0, 24, 24, 0]}>
          <DatePicker
            size="large"
            fluid
            showTime
            label={t('start_date')}
            onConfirm={(date) => handleFilterValueChange({dateFrom: date.format('YYYY-MM-DD HH:mm')})}
          />
        </Space>
        <Space padding={[0, 24, 24, 0]}>
          <DatePicker
            size="large"
            fluid
            showTime
            label={t('end_date')}
            onConfirm={(date) => handleFilterValueChange({dateTo: date.format('YYYY-MM-DD HH:mm')})}
          />
        </Space>
      </>
    );
  }, [filterValues, i18n.language]);

  const storeSelectView = useMemo(() => {
    if (!storesOptions) return;
    if (storesOptions.length < MIN_STORES_COUNT_FOR_DISPLAYING_SELECT) return;

    return (
      <Space padding={[0, 24, 24, 0]}>
        <Select
          onSelect={handleStoreSelect}
          selected={selectedStoreId}
          mode="single"
          label={t('branch')}
          renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
          options={storesOptions}
        />
      </Space>
    );
  }, [storesOptions, selectedStoreId, i18n.language]);

  return (
    <div className={styles.Filters}>
      <div className={styles.SearchInput}>
        <Input
          value={filterValues.orderId}
          onChange={(event) => handleFilterValueChange({orderId: event.target.value})}
          type="number"
          prefix={(
            <Icon strokeWidth={2}
              name="search"
              size={17.5}
            />
          )}
          placeholder={t('orders_history.search_by_order')}
          fluid
        />
      </div>
      <Space padding={[0, 24, 24, 24]}>
        <Select
          onSelect={(value) => handleFilterValueChange({period: value})}
          selected={filterValues.period}
          mode="single"
          label={t('orders_history.all_period')}
          options={DATE_OPTIONS}
          renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
        />
      </Space>
      {datePickersView}
      {storeSelectView}
      <Space padding={[0, 24, 24, 0]}>
        <Select
          onSelect={(value) => handleFilterValueChange({branchesIds: value})}
          selected={filterValues.branchesIds}
          mode="multiple"
          label={t('all_branches')}
          options={filteredBranches}
          renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
        />
      </Space>
      {paymentTypesOptions && (
        <Space padding={[0, 24, 24, 0]}>
          <Select
            onSelect={(value) => handleFilterValueChange({paymentTypes: value})}
            selected={filterValues.paymentTypes}
            mode="multiple"
            label={t('orders_history.all_payment_types')}
            options={paymentTypesOptions}
            renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
          />
        </Space>
      )}
      <Space padding={[0, 24, 24, 0]}>
        <Select
          onSelect={(value) => handleFilterValueChange({status: value})}
          selected={filterValues.status}
          mode="single"
          label={t('orders_history.all_statues')}
          options={ORDER_STATUSES_OPTIONS}
          renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
        />
      </Space>
      <Space padding={[0, 24, 24, 0]}>
        <Select
          onSelect={(value) => handleFilterValueChange({orderTypes: value})}
          selected={filterValues.orderTypes}
          mode="multiple"
          label={t('orders_history.all_order_types')}
          options={ORDER_TYPES_OPTIONS}
          renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
        />
      </Space>
      <Space padding={[0, 12, 0, 0]}>
        <Row>
          <div className={styles.SubmitButton}>
            <Button
              kind="primary"
              loading={isOrdersHistorySearchLoading}
              disabled={isOrdersHistorySearchLoading}
              icon={(
                <Icon name="search"
                  strokeWidth={2}
                />
              )}
              onClick={handleSubmitFilters}
            />
          </div>
          <Button kind="secondary"
            onClick={handleResetFiltersClick}
          >
            {t('actions.reset_filters')}
          </Button>
        </Row>
      </Space>
    </div>
  );
};
