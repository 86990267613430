import {
  $isMenuOpened,
  menuOpened,
  menuClosed,
  userLoggedOut,
  $isLangDropdownOpened,
  langDropdownToggle,
  $isOfflineAlertShown,
  $isLowInternetConnectionAlertShown,
  headerGate,
} from '../model';
import {useGate, useUnit} from 'effector-react';
import styles from './styles.module.scss';
import logo from './assets/logo.svg';
import burger from './assets/burger.svg';
import cross from './assets/cross.svg';
import lowConnection from './assets/low-connection.svg';
import info from './assets/info.svg';
import {usePreventScroll} from '@shared/lib/use-prevent-scroll';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {$selectedStore, $storesOptions, currentStoreChanged} from '@entities/attached-stores';
import {$roleId, $user, languageChanged} from '@entities/user';
import {Link, useLocation} from 'react-router-dom';
import {ProtectedBlock} from '@shared/lib/protected-block';
import {ROLES} from '@shared/routing';
import {Icon, Typography, List, Drawer, Dropdown} from '@express-24/theseus-ui';
import {useTranslation} from 'react-i18next';
import {languages} from 'shared/lib/internationalization';
import i18n from 'i18next';

export const Header = () => {
  const {
    isMenuOpened,
    isLangDropdownOpened,
    handleMenuOpen,
    handleMenuClose,
    handleLogOut,
    stores,
    user,
    selectedStore,
    roleId,
    handleLanguageChanged,
    handleLangDropdownToggle,
    isOfflineAlertShown,
    isLowInternetConnectionAlertShown,
  } = useUnit({
    stores: $storesOptions,
    user: $user,
    roleId: $roleId,
    selectedStore: $selectedStore,
    isMenuOpened: $isMenuOpened,
    isLangDropdownOpened: $isLangDropdownOpened,
    handleChangeStore: currentStoreChanged,
    handleLogOut: userLoggedOut,
    handleMenuOpen: menuOpened,
    handleMenuClose: menuClosed,
    handleLanguageChanged: languageChanged,
    handleLangDropdownToggle: langDropdownToggle,
    isOfflineAlertShown: $isOfflineAlertShown,
    isLowInternetConnectionAlertShown: $isLowInternetConnectionAlertShown,
  });
  const {pathname} = useLocation();
  const [blockScroll, allowScroll] = usePreventScroll();
  const [isChatOpen, setChatOpen] = useState<boolean>(false);
  const {t} = useTranslation();
  useGate(headerGate);

  useEffect(() => {
    if (isMenuOpened) {
      blockScroll();
    } else allowScroll();
  }, [isMenuOpened]);

  const handleOpenChat = useCallback(() => {
    //@ts-ignore
    const trengo = window.Trengo;

    trengo.Api.Widget.render();
    trengo.contact_data = {
      name: user && user.name,
      custom_fields: [
        {
          field_id: 347093,
          value:
            stores &&
            stores
              .filter((store) => store.activity.isActive)
              .map((store) => store.label)
              .join(', '),
        },
        {
          field_id: 353353,
          value: user && user.role.name,
        },
        {
          field_id: 354388,
          value: user && user.id,
        },
      ],
    };

    if (isChatOpen) {
      trengo.Api.Widget.close();
    } else {
      trengo.Api.Widget.open('chat');
    }
  }, [isChatOpen, user, stores]);

  useEffect(() => {
    //@ts-ignore
    const trengo = window.Trengo;
    trengo.on_open = () => {
      setChatOpen(true);
    };

    trengo.on_close = () => {
      setChatOpen(false);
    };
  }, []);

  const onLanguageChange = (locale: string) => {
    handleLanguageChanged(locale);
  };

  const languageDropdownView = useMemo(() => {
    const selectedLanguage = languages.find(({value}) => value === i18n.language);

    return (
      <div className={styles.NavigationControl}
        onClick={handleLangDropdownToggle}
      >
        <Dropdown
          trigger="click"
          options={languages}
          selected={i18n.language}
          onChange={onLanguageChange}
          onClose={handleLangDropdownToggle}
          visible={isLangDropdownOpened}
          placement="bottomRight"
        >
          <div className={styles.Credential}>
            <Icon size={24}
              name="globe"
              strokeWidth={1.5}
            />
            <Typography level="sub-text"
              kind="secondary"
            >
              {selectedLanguage ? selectedLanguage.label : t('language')}
            </Typography>
          </div>
        </Dropdown>
      </div>
    );
  }, [isLangDropdownOpened, i18n.language]);

  const orderLinkView = useMemo(() => {
    const isOpened = pathname === '/orders' || pathname === '/';
    return (
      <div className={styles.NavigationControl}
        data-selected={isOpened}
      >
        <Link to="/orders">
          <Icon name="list"
            filled={isOpened}
            strokeWidth={1.5}
            size={24}
          />
          <Typography level="sub-text"
            kind="secondary"
          >
            {t('sections.orders')}
          </Typography>
        </Link>
      </div>
    );
  }, [pathname, i18n.language]);

  const menuLinkView = useMemo(() => {
    const isOpened = pathname === '/menu';
    const iconName = isOpened ? 'menu-filled' : 'menu';

    return (
      <div data-selected={isOpened}
        className={styles.NavigationControl}
      >
        <Link to="/menu">
          <Icon size={24}
            name={iconName}
            strokeWidth={1.5}
            inheritColor={!isOpened}
          />
          <Typography level="sub-text"
            kind="secondary"
          >
            {t('sections.menu')}
          </Typography>
        </Link>
      </div>
    );
  }, [pathname, i18n.language]);

  const userNameView = user && (
    <Typography level="text"
      kind="secondary"
    >
      {`${user.name} (${user.role.name})`}
    </Typography>
  );

  const storeNameView = selectedStore && (
    <Typography level="h4"
      semibold
    >
      {selectedStore.name}
    </Typography>
  );

  return (
    <>
      <div className={styles.Header}>
        <div className={styles.BurgerMenu}
          onClick={handleMenuOpen}
        >
          <img src={burger}
            alt="burger"
          />
        </div>
        <div className={styles.Logo}>
          <img src={logo}
            alt="logo"
          />
        </div>
        <div className={styles.Navigation}>
          {isOfflineAlertShown && (
            <div className={styles.InternetConnection}>
              <div className={styles.InternetConnectionLostContainer}>
                <Dropdown
                  trigger="hover"
                  placement="bottomRight"
                  content={<div className={styles.InternetConnectionTooltip}>{t('no_internet_connection_tip')}</div>}
                >
                  {t('no_internet_connection')}
                </Dropdown>
              </div>
            </div>
          )}
          {isLowInternetConnectionAlertShown && (
            <div className={styles.InternetConnection}>
              <div className={styles.InternetConnectionContainer}>
                <img src={lowConnection}
                  alt="low connection"
                />
                <div className={styles.InternetConnectionStatus}>{t('low_internet_connection')}</div>
                <Dropdown
                  trigger="hover"
                  placement="bottomRight"
                  content={<div className={styles.InternetConnectionTooltip}>{t('low_internet_connection_tip')}</div>}
                >
                  <img src={info}
                    alt="info"
                  />
                </Dropdown>
              </div>
            </div>
          )}
          {orderLinkView}
          {menuLinkView}
          <div className={styles.NavigationControl}
            onClick={handleOpenChat}
          >
            <Icon name="chat"
              size={24}
              filled={isChatOpen}
            />
            <Typography level="sub-text"
              kind="secondary"
            >
              {t('chat')}
            </Typography>
          </div>
          {languageDropdownView}
        </div>

        <Drawer align="left"
          visible={isMenuOpened}
          onOutsideClick={handleMenuClose}
        >
          <div className={styles.MenuWrapper}>
            <div className={styles.Menu}>
              <div className={styles.MenuActions}>
                <img src={cross}
                  alt="close"
                  onClick={handleMenuClose}
                />
              </div>
              <div className={styles.MenuTitle}>
                {storeNameView}
                {userNameView}
              </div>
              <a
                href="https://express24.notion.site/Promo-info-5a244dea931240f7aade42e7e1cbea3d?pvs=4?"
                target="_blank"
                rel="noreferrer"
                className={styles.PromoBanner}
              >
                <div className={styles.PromoBannerTitle}>{t('promo_action')}</div>

                <div className={styles.PromoBannerMore}>
                  {t('more_info')}
                  <Icon name="chevron-right"
                    size={16}
                    strokeWidth={1.5}
                  />
                </div>
              </a>
              <div className={styles.MenuPages}>
                <List>
                  <Link to="/orders">
                    <List.Element
                      onClick={handleMenuClose}
                      title={t('sections.orders')}
                      icon={(
                        <Icon name="list"
                          size={20}
                          strokeWidth={1.5}
                        />
                      )}
                    />
                  </Link>
                  <ProtectedBlock roleId={roleId}
                    access={[ROLES.OWNER, ROLES.MANAGER]}
                  >
                    <Link to="/menu">
                      <List.Element
                        title={t('sections.menu')}
                        onClick={handleMenuClose}
                        icon={(
                          <Icon size={20}
                            name="menu"
                          />
                        )}
                      />
                    </Link>
                  </ProtectedBlock>
                  <ProtectedBlock access={[ROLES.OWNER, ROLES.MANAGER]}
                    roleId={roleId}
                  >
                    <Link to="/reports">
                      <List.Element
                        title={t('sections.reports')}
                        onClick={handleMenuClose}
                        icon={(
                          <Icon name="reports"
                            size={20}
                          />
                        )}
                      />
                    </Link>
                  </ProtectedBlock>
                  <Link to="/orders-history">
                    <List.Element
                      title={t('sections.orders_history')}
                      onClick={handleMenuClose}
                      icon={(
                        <Icon name="clock"
                          size={20}
                        />
                      )}
                    />
                  </Link>
                  <ProtectedBlock access={[ROLES.OWNER, ROLES.MANAGER]}
                    roleId={roleId}
                  >
                    <Link to="/branches-management">
                      <List.Element
                        onClick={handleMenuClose}
                        title={t('sections.branches_management')}
                        icon={(
                          <Icon name="store"
                            size={20}
                            strokeWidth={1.5}
                          />
                        )}
                      />
                    </Link>
                  </ProtectedBlock>
                  <ProtectedBlock access={[ROLES.OWNER, ROLES.MANAGER]}
                    roleId={roleId}
                  >
                    <Link to="/roles-management">
                      <List.Element
                        title={t('sections.roles_management')}
                        onClick={handleMenuClose}
                        icon={(
                          <Icon name="person"
                            size={20}
                            strokeWidth={1.5}
                          />
                        )}
                      />
                    </Link>
                  </ProtectedBlock>
                  <Link to="/rating">
                    <List.Element
                      title={t('sections.rating')}
                      onClick={handleMenuClose}
                      icon={(
                        <Icon size={20}
                          name="double-message"
                        />
                      )}
                    />
                  </Link>
                  <Link to="/changes-history">
                    <List.Element
                      title={t('sections.changes_history')}
                      onClick={handleMenuClose}
                      icon={(
                        <Icon size={20}
                          name="edit-history"
                        />
                      )}
                    />
                  </Link>
                </List>
              </div>
            </div>
            <div className={styles.Footer}>
              <List>
                <Link to="/user-agreement.html"
                  target="_blank"
                >
                  <List.Element title={t('sections.user_agreement')}
                    icon={(
                      <Icon name="agreement"
                        size={20}
                      />
                    )}
                  />
                </Link>
                <div className={styles.Logout}>
                  <List.Element
                    title={t('actions.logout')}
                    onClick={handleLogOut}
                    icon={(
                      <Icon name="logout"
                        size={20}
                      />
                    )}
                  />
                </div>
              </List>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
