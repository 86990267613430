export const getOnlyUpdatedKeys = (updatedData: any, fullData: any) => {
  const result = Array.isArray(updatedData) ? [] : {};

  for (const key in updatedData) {
    const updatedValue = updatedData[key];
    const currentValue = fullData[key];

    if (key === 'schedule') {
      result[key] = updatedValue;
    }

    // Если объект или массив
    if (updatedValue !== null && typeof updatedValue === 'object') {
      // Если это массив, то проверяем тупо глубоко и если есть изменения, то весь массив забираем
      if (Array.isArray(updatedValue)) {
        if (updatedValue === currentValue) {
          result[key] = updatedValue;
        }
        continue;
      }
      // Иначе это объект и берем только обновленные ключи
      const updatedValues = getOnlyUpdatedKeys(updatedValue, currentValue);
      if (Object.values(updatedValues).length > 0) {
        result[key] = updatedValue;
      }

      continue;
    }

    // Обычное сравнение примитивных данных
    if (updatedData[key] !== fullData[key]) {
      result[key] = updatedData[key];
    }
  }

  return result;
};
