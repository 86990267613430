import styles from './styles.module.scss';
import noBranches from './assets/no-branch.svg';
import noOrders from './assets/no-orders.svg';
import noReports from '../../pages/reports/page/assets/no-files.svg';
import noList from './assets/no-list.svg';
import noProducts from './assets/no-products.svg';
import noUsers from './assets/no-users.svg';
import noReviews from './assets/no-reviews.svg';

import {Typography} from '@express-24/theseus-ui';
import {FC} from 'react';
import {EmptyStateProps} from './types';

export const EmptyState: FC<EmptyStateProps> = ({title, description, illustration}) => {
  const source = {
    noOrders: noOrders,
    noBranches: noBranches,
    noProducts: noProducts,
    noReports: noReports,
    noList: noList,
    noUsers: noUsers,
    noReviews: noReviews,
  };

  return (
    <div className={styles.Empty}>
      <img src={source[illustration]}
        alt={illustration}
      />
      {title && (
        <Typography className={styles.Title}
          level="text"
          semibold
          kind="secondary"
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography className={styles.Description}
          level="sub-text"
          kind="secondary"
        >
          {description}
        </Typography>
      )}
    </div>
  );
};
