import {useCallback, useRef, useState} from 'react';
import {useUnit} from 'effector-react';
import * as model from '../../../../model';
import styles from './styles.module.scss';
import {ProductCover, ProductContent} from '../../molecules';
import {ProductOptionPicker, ProductDialogFooter} from '../index';
import {ProductCombination, ProductForReplacement} from '../../../../model/model.types';

type DialogViewProps = {
  onImageError: () => void;
  onAddClick: (productData: ProductForReplacement) => void;
  cart: ProductForReplacement[] | null;
  productCombinations: ProductCombination[] | null;
};

export const ProductDialogContent = ({onImageError, cart, onAddClick, productCombinations}: DialogViewProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  const [requiredOptions, setRequiredOptions] = useState<boolean>(false);

  const {product, selectedProduct, nextUnselectedRequiredOptionId} = useUnit({
    product: model.$product,
    selectedProduct: model.$unifiedProduct,
    nextUnselectedRequiredOptionId: model.$nextUnselectedRequiredOptionId,
  });

  const handleFooterButtonClick = () => {
    if (!nextUnselectedRequiredOptionId) return;
    if (!contentRef.current) return;
    if (!optionsContainerRef.current) return;

    const requiredOptionElem = optionsContainerRef.current.querySelector<HTMLDivElement>(
      `div[data-property-id='${nextUnselectedRequiredOptionId}']`,
    );

    if (!requiredOptionElem) return;

    const reqOptionBoundingRect = requiredOptionElem.getBoundingClientRect();

    contentRef.current.scrollBy({
      top: reqOptionBoundingRect.top,
      behavior: 'smooth',
    });

    setRequiredOptions(true);
  };

  const handleAddProduct = useCallback(() => {
    if (!selectedProduct) return;

    return onAddClick(selectedProduct);
  }, [selectedProduct]);

  return (
    product && (
      <div className={styles.Dialog}>
        <div className={styles.Content}
          ref={contentRef}
        >
          <ProductCover src={product.cover}
            fallback={onImageError}
          />
          <ProductContent title={product.name} />
          <ProductOptionPicker ref={optionsContainerRef}
            showRequiredOption={requiredOptions}
          />
        </div>
        <ProductDialogFooter
          product={product}
          onAddClick={handleAddProduct}
          onChooseOptionClick={handleFooterButtonClick}
          hasUnselectedOptions={nextUnselectedRequiredOptionId !== null}
          cart={cart}
          productCombinations={productCombinations}
        />
      </div>
    )
  );
};
