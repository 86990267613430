import {Button, Input, Select, Space, Typography} from '@express-24/theseus-ui';
import {useUnit} from 'effector-react';
import InputMask from 'react-input-mask';
import {
  $attachedBranchesIds,
  $password,
  $roleId,
  loginChanged,
  nameChanged,
  passwordChanged,
  phoneNumberChanged,
  ROLE_OPTIONS,
  roleChanged,
  attachedBranchesChanged,
  $attachedBranches,
  $createEmployeeFormValues,
  createEmployeeFormSubmitted,
  $createInputErrors,
  $createInputStatuses,
  createEmployeeFx,
} from '../../../../model';
import {useTranslation} from 'react-i18next';
import styles from '../../../page.module.scss';

export const CreateEmployeeForm = () => {
  const {
    password,
    handleChangeName,
    handleChangePassword,
    handleChangeLogin,
    handleChangeRoleId,
    handleChangeBranches,
    handleChangePhone,
    filteredBranches,
    selectedBranches,
    selectedRoleId,
    formValues,
    handleFormSubmit,
    inputErrors,
    inputStatuses,
    isCreateEmployeeFetching,
  } = useUnit({
    password: $password,
    selectedBranches: $attachedBranchesIds,
    filteredBranches: $attachedBranches,
    selectedRoleId: $roleId,
    handleChangeName: nameChanged,
    handleChangePassword: passwordChanged,
    handleChangeLogin: loginChanged,
    handleChangeRoleId: roleChanged,
    handleChangeBranches: attachedBranchesChanged,
    handleChangePhone: phoneNumberChanged,
    formValues: $createEmployeeFormValues,
    handleFormSubmit: createEmployeeFormSubmitted,
    inputErrors: $createInputErrors,
    inputStatuses: $createInputStatuses,
    isCreateEmployeeFetching: createEmployeeFx.pending,
  });
  const {t} = useTranslation();

  return (
    <div>
      <Space padding={20}>
        <Typography level="h5">{t('roles_management.add_employee')}</Typography>
      </Space>
      <div className={styles.CreateEmployeeForm}>
        <div className={styles.EmployeeForm}>
          {filteredBranches && (
            <Select
              mode="multiple"
              options={filteredBranches}
              selected={selectedBranches}
              onSelect={handleChangeBranches}
              label={t('choose_branch')}
              fluid
              renderOptionsContainer={(optionsView) => {
                return <div className={styles.RenderOptionsContainer}>{optionsView}</div>;
              }}
            />
          )}
          <Select
            mode="single"
            options={ROLE_OPTIONS.map((role) => ({...role, label: t(`roles_management.${role.label}`)}))}
            onSelect={handleChangeRoleId}
            selected={selectedRoleId}
            label={t('choose_role')}
            fluid
          />
          <Input
            label={t('name')}
            onChange={({target}) => handleChangeName(target.value)}
            status={inputStatuses['name']}
            message={inputErrors?.name}
            fluid
          />
          <Input
            label={t('login')}
            onChange={({target}) => handleChangeLogin(target.value)}
            status={inputStatuses['login']}
            message={inputErrors?.login}
            fluid
          />
          <InputMask mask="+999 99 999 99 99"
            onChange={({target}) => handleChangePhone(target.value)}
          >
            <Input
              fluid
              allowClear
              label={t('phone_number')}
              status={inputStatuses['phone']}
              message={inputErrors?.phone}
            />
          </InputMask>
          <Input
            label={t('password')}
            onChange={({target}) => handleChangePassword(target.value)}
            value={password}
            status={inputStatuses['password']}
            message={inputErrors?.password}
            fluid
          />
        </div>
        <Space padding={12} />
        <Button
          size="large"
          kind="primary"
          disabled={!formValues || isCreateEmployeeFetching}
          onClick={handleFormSubmit}
          loading={isCreateEmployeeFetching}
          fluid
        >
          {t('roles_management.add_employee')}
        </Button>
      </div>
    </div>
  );
};
