import React, {useEffect, useMemo} from 'react';
import styles from './page.module.scss';
import {Link, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react/scope';
import {OrderCard} from '../../ui/organisms/order-card';
import {Button, Icon, Modal, Typography, Alert, Drawer} from '@express-24/theseus-ui';
import {
  $isConfirmChangesBtnDisabled,
  $incidentOrder,
  $isDrawerOpened,
  $modifiedProductsOrder,
  $productsOrder,
  incidentOrderModified,
  productsOrderModified,
  pageMounted,
  drawerOpened,
  drawerClosed,
  $incidentStoreParams,
  $productsForReplacement,
  incidentChangesConfirmed,
  counterChangedProductForReplacement,
  pageUnmounted,
  $isAlertCanceledOpen,
  orderCanceledAlertClicked,
  addProductClicked,
  productForReplacementDeleted,
  $productCombinations,
} from './model';
import {DrawerContent} from './ui';
import {ProductDialog} from '@widgets/product-dialog';
import {ProductOrder} from './ui/product-order';
import {ProductForReplacement} from '@widgets/product-dialog/model/model.types';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

type IncidentParams = {
  orderId: string;
};

export const Incidents = () => {
  const {orderId} = useParams<IncidentParams>();

  const {
    productCombinations,
    isAlertCanceledOpen,
    productsForReplacement,
    incidentStoreParams,
    isConfirmChangesBtnDisabled,
    isDrawerOpened,
    incidentOrder,
    handleIncidentOrder,
    productsOrder,
    modifiedProductsOrder,
    handlePageMounted,
    handlePageUnmounted,
    handleProductsOrder,
    handleDrawerOpened,
    handleDrawerClosed,
    handleCounterChangedProductForReplacement,
    handleConfirmIncidentChanges,
    handleOrderCanceledAlertClicked,
    handleAddProductClicked,
    handleProductForReplacementDeleted,
  } = useUnit({
    productCombinations: $productCombinations,
    isAlertCanceledOpen: $isAlertCanceledOpen,
    productsForReplacement: $productsForReplacement,
    incidentStoreParams: $incidentStoreParams,
    isConfirmChangesBtnDisabled: $isConfirmChangesBtnDisabled,
    isDrawerOpened: $isDrawerOpened,
    incidentOrder: $incidentOrder,
    productsOrder: $productsOrder,
    modifiedProductsOrder: $modifiedProductsOrder,
    handleIncidentOrder: incidentOrderModified,
    handlePageMounted: pageMounted,
    handlePageUnmounted: pageUnmounted,
    handleProductsOrder: productsOrderModified,
    handleDrawerOpened: drawerOpened,
    handleDrawerClosed: drawerClosed,
    handleCounterChangedProductForReplacement: counterChangedProductForReplacement,
    handleConfirmIncidentChanges: incidentChangesConfirmed,
    handleOrderCanceledAlertClicked: orderCanceledAlertClicked,
    handleAddProductClicked: addProductClicked,
    handleProductForReplacementDeleted: productForReplacementDeleted,
  });
  const {t} = useTranslation();

  useEffect(() => {
    handlePageMounted(+orderId);

    return () => {
      handlePageUnmounted();
    };
  }, []);

  const orderView = useMemo(() => {
    if (!incidentOrder) return;

    return (
      <OrderCard key={incidentOrder.id}
        order={incidentOrder}
      />
    );
  }, [incidentOrder]);

  const onUpdateOrderCounter = (productId: number, quantity: number) => {
    if (!productsOrder) return;

    const currentProduct = productsOrder.products.find((product) => product.id === productId);

    if (!currentProduct) return;

    handleIncidentOrder({productId, quantity});
    handleProductsOrder({
      id: productId,
      quantity,
      initialQuantity: currentProduct.quantity,
    });
  };

  const onAbsenceProductClick = (productId: number) => {
    onUpdateOrderCounter(productId, 0);
  };

  const productsView = useMemo(() => {
    if (!productsOrder) return;
    if (!incidentOrder) return;

    return productsOrder.products.map((product, index: number) => {
      const currentModifiedOrderQuantity = incidentOrder.products[index].quantity;
      const isProductExist = currentModifiedOrderQuantity !== 0;

      return (
        <ProductOrder
          key={product.id}
          product={product}
          count={currentModifiedOrderQuantity}
          countInStock={product.quantity}
          onUpdate={({id, count}) => onUpdateOrderCounter(id, count)}
          warning={!isProductExist}
          currency={productsOrder.currency}
          max={product.quantity}
          dangerButtonConfig={{
            text: t('not_available'),
            action: () => onAbsenceProductClick(product.id),
            disabled: !isProductExist,
          }}
        />
      );
    });
  }, [productsOrder, incidentOrder, i18n.language]);

  const onUpdateProductForReplacement = (product: {id: number; count: number}) => {
    if (product.count === 0) {
      handleProductForReplacementDeleted(product.id);
    } else {
      handleCounterChangedProductForReplacement(product);
    }
  };

  const productsForReplacementView = useMemo(() => {
    if (!productsForReplacement) return;

    return productsForReplacement.map((product) => {
      return (
        <ProductOrder
          key={product.id}
          product={product}
          count={product.count}
          currency={product.price.currency}
          countInStock={product.amount.value}
          onUpdate={onUpdateProductForReplacement}
          max={product.amount.value}
          dangerButtonConfig={{
            text: t('delete_product'),
            action: () => handleProductForReplacementDeleted(product.id),
          }}
          stockLabel
        />
      );
    });
  }, [productsForReplacement, i18n.language]);

  const orderCanceledAlertView = useMemo(() => {
    if (!incidentOrder) return;

    const {id, payment} = incidentOrder;

    return (
      <Alert
        visible={isAlertCanceledOpen}
        title={t('orders.order_canceled', {orderId: id})}
        description={`${t('order_cost')}: ${payment.receipt.total.toLocaleString()} ${payment.currency}`}
        okText={t('orders.back_to_all_orders')}
        onOk={handleOrderCanceledAlertClicked}
        onOutsideClick={handleOrderCanceledAlertClicked}
      />
    );
  }, [incidentOrder, isAlertCanceledOpen, i18n.language]);

  const onOfferReplacementClick = () => {
    if (!incidentStoreParams) return;
    const {storeId, branchId} = incidentStoreParams;

    const config = {
      storeId,
      params: {branchId},
    };

    handleDrawerOpened(config);
  };

  const onConfirmChangesClick = () => {
    if (!modifiedProductsOrder) return;

    handleConfirmIncidentChanges({
      orderId: +orderId,
      body: {
        products: modifiedProductsOrder,
      },
    });
  };

  const onAddProductClick = (productData: ProductForReplacement) => {
    const combinationId = productData.id;
    let currentProductCart = null;

    if (productsForReplacement) {
      currentProductCart = productsForReplacement.find((item) => item.id === combinationId);
    }

    if (currentProductCart) {
      handleCounterChangedProductForReplacement({
        id: combinationId,
        count: productData.count + currentProductCart.count,
      });
    } else {
      handleAddProductClicked(productData);
    }
  };

  return (
    <div className={styles.Template}>
      <Drawer
        visible={isDrawerOpened}
        onOutsideClick={handleDrawerClosed}
        width="50%"
        maxWidth="752px"
        animationDuration={350}
      >
        <DrawerContent />
      </Drawer>
      <ProductDialog
        onAddClick={onAddProductClick}
        render={({props, content}) => <Modal {...props}>{content}</Modal>}
        productCombinations={productCombinations}
        cart={productsForReplacement}
      />
      {orderCanceledAlertView}
      <div className={`${styles.TemplateSection} ${styles.FirstSection}`}>
        <div className={styles.GoBackBtn}>
          <Link to="/orders">
            <Icon name="chevron-left"
              size={16}
            />
            {t('orders.back_to_order_details')}
          </Link>
        </div>
        {orderView}
      </div>
      <div className={`${styles.TemplateSection} ${styles.ProductsSection}`}>
        <Typography level="h4"
          className={styles.ProductsTitle}
          semibold
        >
          {t('orders.order_composition')}
        </Typography>
        {productsView}
      </div>
      <div className={`${styles.TemplateSection} ${styles.SpaceBetween}`}>
        <div>
          <Typography level="h4"
            className={styles.ProductsTitle}
            semibold
          >
            {t('orders.offered_replacement')}
          </Typography>
          {productsForReplacementView}
          <div className={styles.OfferReplacementBtn}>
            <button onClick={onOfferReplacementClick}>
              <Icon name="plus"
                size={24}
              />
              {t('orders.offer_product_to_replacement')}
            </button>
          </div>
        </div>

        <div className={styles.ConfirmChangesBtn}>
          <Button size="large"
            disabled={isConfirmChangesBtnDisabled}
            onClick={onConfirmChangesClick}
            fluid
          >
            {t('confirm_changes')}
          </Button>
        </div>
      </div>
    </div>
  );
};
