import {FC, useEffect, useState} from 'react';
import {CookingTimeProps} from '../../organisms/order-card/types';
import styles from './styles.module.scss';
import {useTranslation} from 'react-i18next';

export const CookingTimer: FC<CookingTimeProps> = ({deadline}) => {
  const [minutes, setMinutes] = useState<number | null>(null);
  const [seconds, setSeconds] = useState<number | null>(null);
  const [showSeconds, setShowSeconds] = useState<boolean>(false);
  const [isNegative, setNegative] = useState<boolean>(false);

  const {t} = useTranslation();

  const getTime = () => {
    if (!deadline) return;
    const time = Math.floor((Date.parse(deadline) - Date.now()) / 1000);

    if (time < 0) {
      setNegative(true);
    } else {
      setNegative(false);
    }

    if (time < 60 && time > -60) {
      setMinutes(0);
      setSeconds(time);
      setShowSeconds(true);
    } else {
      setShowSeconds(false);
      setMinutes(Math.trunc(time / 60));
    }

    setSeconds(Math.trunc(time % 60));
  };

  useEffect(() => {
    getTime();

    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {showSeconds ? (
        <span className={styles.Timer}
          data-negative={isNegative}
        >
          {seconds} {t('sec')}
        </span>
      ) : (
        <span className={styles.Timer}
          data-negative={isNegative}
        >
          {minutes} {t('min')}
        </span>
      )}
    </>
  );
};
