import {createStore, createEvent, sample, guard} from 'effector';
import {AuthorizationConfig} from '@api/v2';
import {createSessionFx} from '@features/login';

export const pageMounted = createEvent();
export const pageUnmounted = createEvent();
export const loginChanged = createEvent<string>();
export const passwordChanged = createEvent<string>();
export const formSubmitted = createEvent();

export const $login = createStore<string | null>(null);
export const $password = createStore<string | null>(null);
export const $errorMessage = createStore<string | null>(null);
export const $inputStatus = $errorMessage.map((message) => {
  if (!message) return null;
  return 'error';
});

$login.on(loginChanged, (_, login) => login).on(pageUnmounted, () => null);

$password.on(passwordChanged, (_, password) => password).on(pageUnmounted, () => null);

sample({
  source: guard({
    clock: formSubmitted,
    source: {
      login: $login,
      password: $password,
    },
    filter: (credentials): credentials is AuthorizationConfig => {
      if (!credentials) return false;
      if (!credentials.password) return false;
      if (!credentials.login) return false;
      return true;
    },
  }),
  fn: ({login, password}) => {
    return {
      login,
      password,
    };
  },
  target: createSessionFx,
});

$errorMessage
  .on(createSessionFx.failData, (_, error) => {
    //@ts-ignore
    return error.response.data.error;
  })
  .on(pageUnmounted, () => null);
