import {User} from './model.types';
import {createEffect, createEvent, createStore, sample} from 'effector';
import i18n from 'i18next';

export const $user = createStore<User | null>(null);
export const $selectedLanguage = createStore<string>('ru');

const setLocaleToLocalStorageFx = createEffect((locale: string) => {
  localStorage.setItem('lang', locale);
});

const setLocaleToI18nFx = createEffect((locale: string) => {
  return i18n.changeLanguage(locale);
});

const reloadAppFx = createEffect(() => location.reload());

export const $roleId = $user.map((user) => {
  if (!user) return null;

  return user.role.id;
});

export const userRefined = createEvent();
export const userIdentified = createEvent<User>();
export const languageChanged = createEvent<string>();

sample({
  clock: languageChanged,
  target: [setLocaleToLocalStorageFx, setLocaleToI18nFx, reloadAppFx],
});

$user.on(userRefined, () => null).on(userIdentified, (_, user) => user);
$selectedLanguage.on(languageChanged, (_, locale) => locale);
