import {useEffect} from 'react';
import * as model from '../model';
import {useUnit} from 'effector-react';
import styles from './styles.module.scss';
import {ActivityOptions} from '../model/model.types';
import {Button, Modal, Radio, Space, Typography} from '@express-24/theseus-ui';
import {useTranslation} from 'react-i18next';

type Props = {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  options: ActivityOptions;
  onSubmit: (selectedTime: string) => void;
};

export const ActivityModal = ({visible, options, onClose, onSubmit, loading}: Props) => {
  const {
    selectedTime,

    handleActivityModalClose,
    handleActivityTimeChange,
  } = useUnit({
    selectedTime: model.$selectedTime,

    handleActivityModalClose: model.activityModalClosed,
    handleActivityTimeChange: model.activityTimeChanged,
  });
  const {t} = useTranslation();

  useEffect(() => {
    if (visible) return;

    handleActivityModalClose();
  }, [visible]);

  const handleTimeSubmit = () => {
    if (!selectedTime) return;

    onSubmit(selectedTime);
  };

  return (
    <Modal visible={visible}
      onClose={onClose}
      onOutsideClick={onClose}
    >
      <div className={styles.Header}>
        <Typography semibold
          level="h5"
        >
          {t('which_period')}
        </Typography>
        <Space margin={8} />
        <Typography level="sub-text">{t('activity_tip')}</Typography>
      </div>
      <Radio.Group selected={selectedTime}
        onChange={handleActivityTimeChange}
      >
        {options.map(({name, value, caption}) => (
          <Radio key={value}
            value={value}
            caption={caption}
          >
            {name}
          </Radio>
        ))}
      </Radio.Group>
      <div className={styles.Footer}>
        <Button fluid
          size="large"
          kind="secondary"
          onClick={onClose}
        >
          {t('actions.cancel')}
        </Button>
        <Button fluid
          size="large"
          loading={loading}
          disabled={!selectedTime}
          onClick={handleTimeSubmit}
        >
          {t('actions.disable')}
        </Button>
      </div>
    </Modal>
  );
};
