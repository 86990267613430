import {combine, createEffect, createEvent, createStore, guard, sample} from 'effector';
import {ReportsList, ReportsFileParams, ReportParams, Branch, RefreshReportConfig, Errors} from './model.types';
import {attachSession} from '@entities/session';
import {createReports, deleteReportsFile, fetchReports, fetchReportsFile} from '@api/v2';
import {$stores} from '@entities/attached-stores';
import {downloadFile} from '@shared/lib/download-file';
import {notification} from '@express-24/theseus-ui';
import {Dayjs} from 'dayjs';
import i18n from 'i18next';

const formatPaymentType = (paymentType: Array<number>) => {
  if (paymentType.length > 1) return i18n.t('all');
  if (paymentType[0] === 1) return i18n.t('cash');
  if (paymentType[0] === 11) return i18n.t('payme');
};

export const pageMounted = createEvent();
export const pageUnmounted = createEvent();
export const reportRequested = createEvent();
export const reportRefreshed = createEvent<string>();
export const storeSelected = createEvent<number>();
export const reportsTypeChanged = createEvent<string>();
export const fileDownloaded = createEvent<ReportsFileParams>();
export const branchSelected = createEvent<number[]>();
export const paymentTypeChanged = createEvent<number[]>();
export const startDateChanged = createEvent<Dayjs>();
export const endDateChanged = createEvent<Dayjs>();
export const confirmModalOpened = createEvent();
export const confirmModalClosed = createEvent();
export const refreshSubmitted = createEvent();
export const filtersReset = createEvent();

export const $reportsList = createStore<ReportsList | null>(null);
export const $isConfirmModalOpen = createStore<boolean>(false);
export const $reportsType = createStore<string | null>(null);
export const $selectedBranchesIds = createStore<Array<number>>([]);
export const $selectedStoreId = createStore<number | null>(null);
export const $paymentTypesValue = createStore<Array<number> | null>(null);
export const $errors = createStore<Errors | null>(null);
export const $startDateValue = createStore<any>(null);
export const $endDateValue = createStore<any>(null);
export const $oldReportQuery = createStore<ReportParams | null>(null);

export const $formattedReportsList = $reportsList.map((reports) => {
  if (!reports) return;

  return reports.map((report) => {
    const paymentType = formatPaymentType(report.query.paymentTypesIds);
    return {
      alias: report.alias,
      branches: report.query.branches,
      paymentType,
      period: report.query.date,
      date: report.date,
      state: report.state,
      store: report.query.store,
      id: report.id,
    };
  });
});

export const $startDate = $startDateValue.map((date) => {
  if (!date) return null;

  return date.format('YYYY-MM-DD HH:mm');
});

export const $endDate = $endDateValue.map((date) => {
  if (!date) return null;

  return date.format('YYYY-MM-DD HH:mm');
});

export const $selectedStore = combine($stores, $selectedStoreId, (stores, storeId) => {
  if (!stores) return;
  if (stores.length === 1) return stores[0];
  if (!storeId) return null;

  return stores.find((store) => store.id === storeId);
});

export const $paymentTypes = $paymentTypesValue.map((type) => {
  if (!type) return null;
  if (type[0] === 0) {
    return [1, 11];
  }
  return type;
});

export const $filteredStores = $stores.map((stores) => {
  if (!stores) return;

  return stores.map((store) => {
    return {
      value: store.id,
      label: store.name,
    };
  });
});

export const $filteredBranches = $selectedStore.map((store) => {
  if (!store) return null;

  return store.branches.map((branch) => {
    return {
      value: branch.id,
      label: branch.name,
    };
  });
});

export const $reportParams = combine(
  $reportsType,
  $selectedBranchesIds,
  $selectedStore,
  $paymentTypes,
  $startDate,
  $endDate,
  (reportType, branchesIds, storeId, paymentTypesIds, start, end) => {
    if (!reportType) return null;
    if (branchesIds.length < 1) return null;
    if (!storeId) return null;
    if (paymentTypesIds && paymentTypesIds.length < 1) return null;
    if (!start) return null;
    if (!end) return null;

    return {
      alias: reportType,
      storeId: storeId.id,
      branchesIds: branchesIds,
      paymentTypesIds: paymentTypesIds,
      date: {
        from: start,
        to: end,
      },
    };
  },
);

export const fetchReportsListApiFx = createEffect(fetchReports);
export const fetchReportsFileApiFx = createEffect(fetchReportsFile);
export const deleteReportsFileApiFx = createEffect(deleteReportsFile);
export const createReportsApiFx = createEffect(createReports);
const downLoadFileFx = createEffect(downloadFile);

export const fetchReportsListFx = attachSession({
  effect: fetchReportsListApiFx,
  mapParams: (_, authorization) => ({
    headers: {
      authorization,
    },
  }),
});

const fetchReportsFileFx = attachSession({
  effect: fetchReportsFileApiFx,
  mapParams: (params: ReportsFileParams, authorization) => ({
    headers: {
      authorization,
    },
    params,
  }),
});

export const deleteReportsFileFx = attachSession({
  effect: deleteReportsFileApiFx,
  mapParams: (params: RefreshReportConfig, authorization) => ({
    headers: {
      authorization,
    },
    params,
  }),
});

export const createReportsFx = attachSession({
  effect: createReportsApiFx,
  mapParams: (data: ReportParams, authorization) => ({
    headers: {
      authorization,
    },
    body: data,
  }),
});

sample({
  clock: pageMounted,
  target: fetchReportsListFx,
});

sample({
  source: guard({
    clock: reportRequested,
    source: $reportParams,
    filter: (reportParams): reportParams is ReportParams => reportParams !== null,
  }),
  target: createReportsFx,
});

sample({
  clock: createReportsFx.done,
  target: filtersReset,
});

sample({
  source: guard({
    clock: refreshSubmitted,
    source: $oldReportQuery,
    filter: (report): report is ReportParams => report !== null,
  }),
  fn: (report) => {
    return {
      fileId: report.id,
    };
  },
  target: deleteReportsFileFx,
});

sample({
  source: guard({
    clock: deleteReportsFileFx.done,
    source: $oldReportQuery,
    filter: (report): report is ReportParams => report !== null,
  }),
  target: createReportsFx,
});

sample({
  clock: createReportsFx.done,
  target: [fetchReportsListFx, pageUnmounted],
});

sample({
  source: fileDownloaded,
  target: fetchReportsFileFx,
});

sample({
  source: fetchReportsFileFx.done,
  fn: ({result, params}) => {
    return {
      file: result.data,
      name: `Отчет-${params.dateFrom}-${params.dateTo}`,
      extension: 'xlsx',
    };
  },
  target: downLoadFileFx,
});

sample({
  clock: pageUnmounted,
  target: filtersReset,
});

sample({
  clock: reportRefreshed,
  source: $reportsList,
  fn: (reports, fileId) => {
    if (!reports) return null;

    const params = reports.find((report) => {
      return report.id === fileId;
    });

    if (!params) return null;

    return {
      alias: params.alias,
      storeId: params.query.store.id,
      branchesIds: params.query.branches.map((branch: Branch) => branch.id),
      paymentTypesIds: params.query.paymentTypesIds,
      date: {
        from: params.query.date.from.split('').slice(0, -3)
          .join(''),
        to: params.query.date.to.split('').slice(0, -3)
          .join(''),
      },
      id: fileId,
    };
  },
  target: $oldReportQuery,
});

$reportsList.on(fetchReportsListFx.doneData, (_, {data}) => data).on(pageUnmounted, () => null);

$selectedStoreId.on(filtersReset, () => null).on(storeSelected, (_, storeId) => storeId);

$selectedBranchesIds
  .on(branchSelected, (_, branchesIds) => branchesIds)
  .on(filtersReset, () => [])
  .on(storeSelected, () => []);

$paymentTypesValue.on(paymentTypeChanged, (_, paymentType) => paymentType).on(filtersReset, () => null);

$startDateValue.on(startDateChanged, (_, date) => date).on(filtersReset, () => null);

$endDateValue.on(endDateChanged, (_, date) => date).on(filtersReset, () => null);

$reportsType.on(reportsTypeChanged, (_, type) => type).on(filtersReset, () => null);

$isConfirmModalOpen
  .on(confirmModalOpened, () => true)
  .on(confirmModalClosed, () => false)
  .on(createReportsFx.done, () => false);

//@ts-ignore
createReportsFx.failData.watch(({response}) => {
  const errors = Object.values(response.data.errors.date);
  errors.map((message) => {
    notification.error({
      title: `${message}`,
      duration: 10000,
    });
  });
});
