import {FC, useEffect, useMemo} from 'react';
import styles from './page.module.scss';
import {Modal, Button, Icon, Space, Typography, Dropdown, DatePicker, Select, Table} from '@express-24/theseus-ui';
import {useUnit} from 'effector-react';
import {
  $filteredBranches,
  $filteredStores,
  pageMounted,
  pageUnmounted,
  storeSelected,
  branchSelected,
  reportsTypeChanged,
  startDateChanged,
  endDateChanged,
  fileDownloaded,
  reportRequested,
  reportRefreshed,
  ReportStates,
  paymentTypeChanged,
  $reportParams,
  createReportsFx,
  $isConfirmModalOpen,
  confirmModalOpened,
  confirmModalClosed,
  refreshSubmitted,
  Branch,
  $reportsType,
  $selectedBranchesIds,
  $selectedStoreId,
  $paymentTypesValue,
  $startDateValue,
  $endDateValue,
  $selectedStore,
  $formattedReportsList,
} from '../model';
import {$stores} from '@entities/attached-stores';
import noReports from './assets/no-files.svg';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const Reports: FC = () => {
  const {
    handleMountPage,
    handleChangeStore,
    handleUnmountPage,
    handleBranchSelect,
    handleReportTypeChange,
    handleStartDateChange,
    handleEndDateChange,
    handleDownloadFile,
    handleRefreshReport,
    handleRequestReport,
    handlePaymentTypeChange,
    handleConfirmOpen,
    handleConfirmClose,
    handleRefreshSubmit,
    storesList,
    isReportCreating,
    selectedStore,
    filteredStores,
    filteredBranches,
    isConfirmModalOpen,
    reportsList,
    reportParams,
    reportsType,
    selectedBranchesIds,
    selectedStoreId,
    paymentTypes,
    startDate,
    endDate,
  } = useUnit({
    isReportCreating: createReportsFx.pending,
    isConfirmModalOpen: $isConfirmModalOpen,
    storesList: $stores,
    reportParams: $reportParams,
    reportsList: $formattedReportsList,
    selectedStore: $selectedStore,
    filteredStores: $filteredStores,
    filteredBranches: $filteredBranches,
    reportsType: $reportsType,
    startDate: $startDateValue,
    endDate: $endDateValue,
    selectedBranchesIds: $selectedBranchesIds,
    selectedStoreId: $selectedStoreId,
    paymentTypes: $paymentTypesValue,
    handlePaymentTypeChange: paymentTypeChanged,
    handleChangeStore: storeSelected,
    handleBranchSelect: branchSelected,
    handleReportTypeChange: reportsTypeChanged,
    handleStartDateChange: startDateChanged,
    handleEndDateChange: endDateChanged,
    handleRefreshReport: reportRefreshed,
    handleRequestReport: reportRequested,
    handleDownloadFile: fileDownloaded,
    handleConfirmOpen: confirmModalOpened,
    handleConfirmClose: confirmModalClosed,
    handleRefreshSubmit: refreshSubmitted,
    handleMountPage: pageMounted,
    handleUnmountPage: pageUnmounted,
  });
  const {t} = useTranslation();

  const PAYMENT_TYPES = [
    {
      value: 1,
      label: t('cash'),
    },
    {
      value: 11,
      label: t('payme'),
    },
  ];

  const REPORT_TYPES = [
    {
      value: 'realization',
      label: t('reports.realization'),
    },
    {
      value: 'ordersStatuses',
      label: t('reports.orders_statuses'),
    },
  ];

  const REPORT_STATES: ReportStates = {
    processing: {
      text: t('asked'),
      color: '#0091FF',
      isDownloadable: false,
      kind: 'secondary',
      isReady: false,
    },
    ready: {
      text: t('ready'),
      color: '#16C647',
      isDownloadable: true,
      kind: 'primary',
      isReady: true,
    },
    error: {
      text: t('error'),
      color: '#FF4D4F',
      isDownloadable: true,
      kind: 'secondary',
      isReady: false,
    },
    archived: {
      text: t('archived'),
      color: '#8E8E93',
      isDownloadable: true,
      kind: 'secondary',
      isReady: false,
    },
  };

  const REPORT_ALIAS = {
    realization: t('reports.realization'),
    ordersStatuses: t('reports.orders_statuses'),
  };

  useEffect(() => {
    handleMountPage();

    return () => handleUnmountPage();
  }, []);

  const storePickView = useMemo(() => {
    if (!storesList) return;
    if (!filteredStores) return;
    if (storesList.length < 1) return;

    return (
      <div className={styles.Select}>
        <Select
          mode="single"
          fluid
          onSelect={handleChangeStore}
          selected={selectedStoreId}
          renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
          label={t('store')}
          options={filteredStores}
        />
      </div>
    );
  }, [storesList, filteredStores, selectedStoreId, i18n.language]);

  const branchPickView = useMemo(() => {
    if (!filteredBranches) return;

    return (
      <div className={styles.Select}>
        <Select
          onSelect={handleBranchSelect}
          mode="multiple"
          fluid
          label={t('branch')}
          renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
          selected={selectedBranchesIds}
          options={filteredBranches}
        />
      </div>
    );
  }, [filteredBranches, selectedStore, selectedBranchesIds, i18n.language]);

  const noReportsView = useMemo(() => {
    if (!reportsList) return;
    if (reportsList.length > 0) return;

    return (
      <div className={styles.EmptyState}>
        <img src={noReports}
          alt="No Reports"
        />
        <Typography level="text"
          semibold
          kind="secondary"
        >
          {t('reports.empty_list')}
        </Typography>
        <Typography level="sub-text"
          kind="secondary"
        >
          {t('reports.no_reports')}
        </Typography>
      </div>
    );
  }, [reportsList, i18n.language]);

  const formatDateView = (date: string): string => {
    return dayjs(date).format('DD.MM.YY, HH:mm');
  };

  const formatBranches = (branches: Branch[]) => {
    return branches.map((branch, index, array) => {
      if (index !== array.length - 1) {
        return `${branch.name}, `;
      } else {
        return branch.name;
      }
    });
  };

  const reportsView = useMemo(() => {
    if (!reportsList) return;
    if (reportsList.length < 1) return;

    return (
      <div className={styles.ReportsList}>
        <Table
          source={reportsList}
          indexKey="id"
          columns={[
            {
              title: t('vendor'),
              indexKey: 'store',
              render: (report) => {
                const hasBranches = report.branches.length > 1;
                return (
                  <>
                    {hasBranches ? (
                      <Dropdown
                        trigger="hover"
                        content={(
                          <div className={styles.DropdownContent}>
                            <Space padding={20}>
                              <Typography level="text"
                                semibold
                              >
                                {t('reports.selected_branches')}
                              </Typography>
                              <Typography level="text"
                                kind="secondary"
                              >
                                {formatBranches(report.branches)}
                              </Typography>
                            </Space>
                          </div>
                        )}
                      >
                        <span className={styles.StoreName}>{report.store.name}</span>
                      </Dropdown>
                    ) : (
                      <Typography level="text">{report.store.name}</Typography>
                    )}
                  </>
                );
              },
            },
            {
              title: t('report_type'),
              indexKey: 'alias',
              render: (report) => {
                return <Typography level="text">{REPORT_ALIAS[report.alias]}</Typography>;
              },
            },
            {
              title: t('period'),
              indexKey: 'period',
              render: (report) => (
                <Typography level="text">
                  {formatDateView(report.period.from)} - {formatDateView(report.period.to)}
                </Typography>
              ),
            },
            {
              title: t('payment_type'),
              indexKey: 'paymentType',
              render: (report) => <Typography level="text">{report.paymentType}</Typography>,
            },
            {
              title: t('asked'),
              indexKey: 'date',
              render: (report) => <Typography level="text">{formatDateView(report.date.requested)}</Typography>,
            },
            {
              title: t('status'),
              indexKey: 'state',
              render: (report) => {
                return (
                  <span
                    style={{
                      color: REPORT_STATES[report.state].color,
                      fontSize: 16,
                    }}
                  >
                    {REPORT_STATES[report.state].text}
                  </span>
                );
              },
            },
            {
              title: '',
              indexKey: 'id',
              render: (report) => (
                <>
                  <Button
                    icon={(
                      <Icon name="inbox"
                        size={16}
                      />
                    )}
                    shape="square"
                    size="small"
                    disabled={!REPORT_STATES[report.state].isDownloadable}
                    kind={REPORT_STATES[report.state].kind}
                    onClick={() => {
                      const isReady = REPORT_STATES[report.state].isReady;
                      const isDownloadable = REPORT_STATES[report.state].isDownloadable;

                      if (report.id && isReady) {
                        handleDownloadFile({
                          fileId: report.id,
                          dateFrom: formatDateView(report.period.from),
                          dateTo: formatDateView(report.period.to),
                        });
                      }

                      if (isDownloadable && !isReady) {
                        handleRefreshReport(report.id);
                        handleConfirmOpen();
                      }
                    }}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    );
  }, [reportsList, i18n.language]);

  return (
    <div className={styles.PageContainer}>
      <div className={styles.Reports}>
        <Typography level="hero">{t('sections.reports')}</Typography>
        <Space padding={14} />
        <Typography level="text"
          kind="secondary"
        >
          {t('reports.page_instruction')}
        </Typography>

        <div className={styles.Controllers}>
          <div className={styles.SelectControllers}>
            <div className={styles.Select}>
              <Select
                onSelect={handleReportTypeChange}
                label={t('report_type')}
                fluid
                mode="single"
                size="large"
                renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
                options={REPORT_TYPES}
                selected={reportsType}
              />
            </div>
            {storePickView}
            {branchPickView}
            <div className={styles.Select}>
              <Select
                mode="multiple"
                fluid
                renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
                onSelect={handlePaymentTypeChange}
                label={t('payment_type')}
                selected={paymentTypes}
                size="large"
                options={PAYMENT_TYPES}
              />
            </div>
          </div>
          <div className={styles.DateControllers}>
            <div className={styles.Select}>
              <DatePicker
                size="large"
                fluid
                showTime
                label={t('start_date')}
                value={startDate}
                onConfirm={handleStartDateChange}
              />
            </div>
            <div className={styles.Select}>
              <DatePicker
                size="large"
                fluid
                showTime
                label={t('end_date')}
                value={endDate}
                onConfirm={handleEndDateChange}
              />
            </div>
            <div className={styles.SubmitButton}>
              <Button
                size="large"
                onClick={handleRequestReport}
                fluid
                disabled={!reportParams}
                loading={isReportCreating}
              >
                {t('reports.ask_report')}
              </Button>
            </div>
          </div>
        </div>
        {noReportsView}
        {reportsView}
        <Modal
          visible={isConfirmModalOpen}
          onOutsideClick={handleConfirmClose}
          onClose={handleConfirmClose}
          size="medium"
        >
          <Space padding={24}>
            <Typography level="h4"
              semibold
            >
              {t('reports.again_ask_report')}
            </Typography>
            <Space padding={6} />
            <Typography level="text">{t('reports.again_ask_report_tip')}</Typography>
            <Space padding={12} />
            <Button kind="primary"
              fluid
              onClick={handleRefreshSubmit}
              loading={isReportCreating}
            >
              {t('actions.ask')}
            </Button>
          </Space>
        </Modal>
      </div>
    </div>
  );
};
