import {attachSession} from '@entities/session';
import {createEffect, createEvent, createStore, sample} from 'effector';
import {LoadingIds, MenuActivityConfig, ProductActivityConfig} from './model.types';
import {MenuUpdateParams, ProductDetailsUpdateParams, updateMenu, updateProductDetails} from '@api/v2';

export const REFETCH_TIMOUT = 600;

export const menuSwitchToggled = createEvent<MenuActivityConfig>();
export const submenuSwitchToggled = createEvent<MenuActivityConfig>();
export const productSwitchToggled = createEvent<ProductActivityConfig>();
export const productActivityModalOpened = createEvent<number>();
export const productActivityModalClosed = createEvent();
export const productDisableSubmitted = createEvent<string>();
export const menuIdRemoved = createEvent<number>();
export const productsIdRemoved = createEvent<number>();

export const updateMenuFx = attachSession({
  effect: createEffect(updateMenu),
  mapParams: ({data, path}: MenuUpdateParams, authorization) => ({
    data,
    path,
    headers: {
      authorization,
    },
  }),
});

export const updateSubmenuFx = attachSession({
  effect: createEffect(updateMenu),
  mapParams: ({data, path}: MenuUpdateParams, authorization) => ({
    data,
    path,
    headers: {
      authorization,
    },
  }),
});

export const updateProductFx = attachSession({
  effect: createEffect(updateProductDetails),
  mapParams: ({path, data}: ProductDetailsUpdateParams, authorization) => ({
    path,
    data,
    headers: {
      authorization,
    },
  }),
});

export const $menuLoadingStateByIds = createStore<LoadingIds>({});
export const $productLoadingStateByIds = createStore<LoadingIds>({});
export const $selectedProductId = createStore<number | null>(null);

export const $isProductActivityModalOpen = createStore<boolean>(false);

export const $isMenusLoadingIdsEmpty = $menuLoadingStateByIds.map((loadingIds) => !Object.keys(loadingIds).length);
export const $isProductsLoadingIdsEmpty = $productLoadingStateByIds.map(
  (loadingIds) => !Object.keys(loadingIds).length,
);

$selectedProductId
  .on(productActivityModalOpened, (_, productId) => productId)
  .on([productActivityModalClosed, updateProductFx.done], () => null);

$menuLoadingStateByIds
  .on([updateMenuFx, updateSubmenuFx], (loadingIds, {path}) => ({
    ...loadingIds,
    [path.menuId]: true,
  }))
  .on(menuIdRemoved, (loadingIds, menuId) => {
    const updatedLoadingIds = {...loadingIds};

    delete updatedLoadingIds[menuId];

    return updatedLoadingIds;
  });

$productLoadingStateByIds
  .on(updateProductFx, (loadingIds, {path}) => ({
    ...loadingIds,
    [path.productId]: true,
  }))
  .on(productsIdRemoved, (loadingIds, productId) => {
    const updatedLoadingIds = {...loadingIds};

    delete updatedLoadingIds[productId];

    return updatedLoadingIds;
  });

$isProductActivityModalOpen
  .on(productActivityModalOpened, () => true)
  .on([productActivityModalClosed, updateProductFx.done], () => false);

sample({
  clock: menuSwitchToggled,
  fn: ({storeId, branchId, menuId, checked, isSubmenu}) => ({
    path: {
      menuId,
      storeId,
    },
    data: {
      activity: {
        branchId,
        isSubmenu,
        isActive: checked,
      },
    },
  }),
  target: updateMenuFx,
});

sample({
  clock: submenuSwitchToggled,
  fn: ({storeId, branchId, menuId, checked, isSubmenu}) => ({
    path: {
      menuId,
      storeId,
    },
    data: {
      activity: {
        branchId,
        isSubmenu,
        isActive: checked,
      },
    },
  }),
  target: updateSubmenuFx,
});

sample({
  clock: [updateMenuFx.done, updateSubmenuFx.done],
  fn: ({params}) => params.path.menuId,
  target: menuIdRemoved,
});

sample({
  clock: updateProductFx.done,
  fn: ({params}) => params.path.productId,
  target: productsIdRemoved,
});

sample({
  clock: productSwitchToggled,
  fn: ({storeId, branchId, productId, checked, unavailableTill}) => ({
    path: {
      storeId,
      productId,
    },
    data: {
      branches: [
        {
          id: branchId,
          availability: {
            unavailableTill: unavailableTill,
            isAvailable: checked,
          },
        },
      ],
    },
  }),
  target: updateProductFx,
});
