import {Icon} from '@express-24/theseus-ui';
import {GeneratedTag, GenerateTagConfig} from './types';
import i18n from 'i18next';

const STATUSES = {
  new: {
    color: '#3399FF',
    textColor: '#FFFFFF',
  },
  preOrder: {
    color: '#3399FF',
    textColor: '#FFFFFF',
  },
  merchantAcceptSearchCourier: {
    color: '#FFEC00',
    textColor: '#000000',
  },
  merchantStartPreparingOrder: {
    color: '#16C647',
    textColor: '#FFFFFF',
  },
  merchantPreparingOrder: {
    color: '#16C647',
    textColor: '#FFFFFF',
  },
  orderReadyWaitCourier: {
    color: '#ECECF1',
    textColor: '#000000',
  },
  courierArrived: {
    color: '#FFEC00',
    textColor: '#000000',
  },
  orderSended: {
    color: '#2E2E2E',
    textColor: '#FFFFFF',
  },
  clientTookOrder: {
    color: '#2E2E2E',
    textColor: '#FFFFFF',
  },
  notPaid: {
    color: '#FF4D4F',
    textColor: '#FFFFFF',
  },
  incident: {
    color: '#FFEC00',
    textColor: '#000000',
  },
  canceled: {
    color: '#FF4D4F',
    textColor: '#FFFFFF',
  },
  finished: {
    color: '#2E2E2E',
    textColor: '#FFFFFF',
  },
};

export const generateTags = (order: GenerateTagConfig, isPreparing: boolean): GeneratedTag[] => {
  const tags = [];
  const time = order.prepareTime.iso && Date.parse(order.prepareTime.iso) - Date.now();

  if (order.isReady) {
    tags.push({
      name: i18n.t('orders.order_ready'),
      color: '#16C647',
      textColor: '#FFFFFF',
      outlined: false,
    });
  }

  if (isPreparing && time && time < 0) {
    tags.push({
      name: i18n.t('orders.order_late'),
      color: '#FF4D4F',
      textColor: '#FFFFFF',
      outlined: false,
    });
  }

  if (order.isTakeaway) {
    tags.push({
      name: i18n.t('takeaway'),
      color: '#3399FF',
      textColor: '#FFFFFF',
      outlined: false,
    });
  }

  tags.push({
    name: order.status.text,
    color: STATUSES[order.status.code].color,
    textColor: STATUSES[order.status.code].textColor,
    outlined: false,
  });

  if (order.payment.type.id === 1) {
    if (tags.length > 2) {
      tags.push({
        name: '',
        textColor: '#16C647',
        outlined: true,
        icon: <Icon name="wallet" />,
      });
    } else {
      tags.push({
        name: order.payment.type.name,
        textColor: '#16C647',
        outlined: true,
        icon: <Icon name="wallet" />,
      });
    }
  }

  if (order.payment.type.id === 11) {
    if (tags.length > 2) {
      tags.push({
        name: '',
        textColor: '#3399FF',
        icon: <Icon name="credit-card" />,
        outlined: true,
      });
    } else {
      tags.push({
        name: order.payment.type.name,
        textColor: '#3399FF',
        icon: <Icon name="credit-card" />,
        outlined: true,
      });
    }
  }

  return tags;
};
