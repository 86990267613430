import {Fragment} from 'react';
import styles from './styles.module.scss';
import {Skeleton} from '@express-24/theseus-ui';

export const MenuLoader = () => {
  return (
    <Fragment>
      <div className={styles.Header}>
        <Skeleton radius={8}
          width={100}
        />
      </div>
      {Array.from({length: 8}).map((_, index) => (
        <div key={index}
          className={styles.LoaderCell}
        >
          <Skeleton radius={8}
            width={44}
            height={26}
          />
          <Skeleton radius={8}
            height={26}
          />
        </div>
      ))}
    </Fragment>
  );
};
