import {createEvent, createStore, sample, createEffect, combine, guard} from 'effector';
import {
  ChangesHistoryContract,
  fetchChangesHistory,
  fetchChangesHistoryTypes,
  fetchChangesHistoryActions,
  ChangesHistoryTypes,
} from '@api/v2';
import {attachSession} from '@entities/session';
import {Filter, HistoryParams} from './model.types';
import {$stores} from '@entities/attached-stores';
import {getLastDates} from '@shared/lib/dayjs';
import i18n from 'i18next';

const PAGE_LIMIT = 10;

export const pageMounted = createEvent();
export const pageUnmounted = createEvent();
export const filtersSubmitted = createEvent();
export const pageChanged = createEvent<number>();
export const periodChanged = createEvent<string>();
export const storeIdChanged = createEvent<number>();
export const filterChanged = createEvent<Partial<Filter>>();

export const $filter = createStore<Filter | null>(null);
export const $selectedPeriod = createStore<string>(getLastDates(3));
export const $selectedStoreId = createStore<number | null>(null);
export const $changesHistory = createStore<ChangesHistoryContract | null>(null);
export const $changesHistoryTypes = createStore<ChangesHistoryTypes>([
  {
    label: 'sections.menu',
    value: 'menu',
  },
  {
    label: 'branch',
    value: 'branch',
  },
]);
export const $changesHistoryActions = createStore<ChangesHistoryTypes>([
  {
    label: 'changes_history.enable',
    value: 'enable',
  },
  {
    label: 'changes_history.disable',
    value: 'disable',
  },
  {
    label: 'changes_history.change',
    value: 'change',
  },
]);
export const $page = createStore<number>(1);

export const $filteredBranches = combine($stores, $selectedStoreId, (stores, selectedStoreId) => {
  if (!stores) return [];
  if (!selectedStoreId) return [];

  const store = stores.find(({id}) => id === selectedStoreId);

  if (!store) return [];

  return store.branches.map(({id, name}) => ({
    value: id,
    label: name,
  }));
});

export const fetchChangesHistoryApiFx = createEffect(fetchChangesHistory);

export const fetchChangesHistoryFx = attachSession({
  effect: fetchChangesHistoryApiFx,
  mapParams: (params: HistoryParams, authorization) => {
    return {
      params,
      headers: {
        authorization,
      },
    };
  },
});

export const fetchChangesHistoryTypesFx = attachSession({
  effect: createEffect(fetchChangesHistoryTypes),
  mapParams: (_, authorization) => {
    return {
      headers: {
        authorization,
      },
    };
  },
});

export const fetchChangesHistoryActionsFx = attachSession({
  effect: createEffect(fetchChangesHistoryActions),
  mapParams: (_, authorization) => {
    return {
      headers: {
        authorization,
      },
    };
  },
});

export const $isFiltersReady = combine(
  fetchChangesHistoryActionsFx.pending,
  fetchChangesHistoryTypesFx.pending,
  (...requests) => !requests.some((isPending) => isPending),
);

export const $changesHistoryParams = combine(
  $selectedStoreId,
  $filter,
  $selectedPeriod,
  $page,
  (storeId, filter, period, page) => {
    if (!storeId) return null;

    const [dateFrom, dateTo] = period.split('/');

    return {
      ...filter,
      page,
      dateTo,
      storeId,
      dateFrom,
      limit: PAGE_LIMIT,
    };
  },
);

$page.on(pageChanged, (_, page) => page);
$selectedStoreId.on(storeIdChanged, (_, id) => id);
$selectedPeriod.on(periodChanged, (_, period) => period);
$changesHistory.on(fetchChangesHistoryFx.doneData, (_, {data}) => data);

$filter.on(filterChanged, (prevFilter, newFilter) => ({
  ...prevFilter,
  ...newFilter,
}));

sample({
  clock: pageMounted,
  source: $stores,
  fn: (stores) => {
    if (!stores) return null;

    return stores[0].id;
  },
  target: $selectedStoreId,
});

sample({
  clock: guard({
    clock: pageMounted,
    source: $changesHistoryParams,
    filter: (params): params is HistoryParams => params !== null,
  }),
  target: fetchChangesHistoryFx,
});

sample({
  clock: guard({
    clock: [filtersSubmitted, pageChanged],
    source: $changesHistoryParams,
    filter: (params): params is HistoryParams => params !== null,
  }),
  target: fetchChangesHistoryFx,
});

sample({
  clock: storeIdChanged,
  fn: () => ({
    branchesIds: [],
  }),
  target: filterChanged,
});

sample({
  clock: pageMounted,
  target: [fetchChangesHistoryActionsFx, fetchChangesHistoryTypesFx],
});
