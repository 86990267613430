import React, {useMemo} from 'react';
import styles from '../../page.module.scss';
import {Button, Counter, Typography} from '@express-24/theseus-ui';
import {OrderProduct} from '@api/v2';
import {ProductForReplacement} from '@widgets/product-dialog/model/model.types';
import {ImageCover} from 'shared/ui/molecules/image-cover';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

type ProductOrderProps = {
  product: ProductForReplacement | OrderProduct;
  currency: string;
  count: number;
  countInStock: number;
  onUpdate: ({id, count}: {id: number; count: number}) => void;
  warning?: boolean;
  max: number;
  dangerButtonConfig: {
    text: string;
    action: (productId: number) => void;
    disabled?: boolean;
  };
  stockLabel?: boolean;
};

export const ProductOrder = (props: ProductOrderProps) => {
  const {product, count, countInStock, onUpdate, warning, max, dangerButtonConfig, stockLabel, currency} = props;
  const {t} = useTranslation();

  if (!product) return null;

  const modifiedQuantityInStock = countInStock - count;

  const orderProps = useMemo(() => {
    if (!product.props) return;

    return product.props.map((prop) => (
      <Typography level="caption"
        kind="secondary"
        key={prop.value}
      >
        • {prop.value}
      </Typography>
    ));
  }, [product]);

  const fewInStockLabel = useMemo(() => {
    const minProductCount = 5;

    if (modifiedQuantityInStock > minProductCount) return;
    if (!stockLabel) return;

    return (
      <Typography level="sub-text"
        kind="warning"
      >
        {modifiedQuantityInStock === 0 ? t('no_more') : `${t('orders.left')} ${modifiedQuantityInStock}`}
      </Typography>
    );
  }, [count, i18n.language]);

  return (
    <div className={styles.Product}
      key={product.id}
    >
      <div className={styles.ProductTop}>
        <div className={styles.ProductViewInfo}>
          <div className={styles.ProductImage}>
            <ImageCover coverUrl={product.cover} />
          </div>

          <div className={styles.ProductName}>
            <Typography level="text">{product.name}</Typography>
            {warning && (
              <Typography level="text"
                kind="warning"
              >
                {t('orders.product_will_disabled')}
              </Typography>
            )}
            {fewInStockLabel}
            {orderProps}
          </div>
        </div>
        <div className={styles.ProductPrice}>
          <Typography level="text">
            {product.price.sold.toLocaleString()} {currency}
          </Typography>
        </div>
      </div>
      <div className={styles.ProductBottom}>
        <Button
          kind="danger"
          size="small"
          onClick={() => dangerButtonConfig.action(product.id)}
          disabled={dangerButtonConfig.disabled}
        >
          {dangerButtonConfig.text}
        </Button>
        <div className={styles.ProductCounter}>
          <Counter
            kind="secondary"
            size="small"
            max={max}
            min={0}
            count={count}
            onUpdate={(count) => onUpdate({id: product.id, count})}
            fluid
            shadow
          />
        </div>
      </div>
    </div>
  );
};
