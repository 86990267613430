import React from 'react';
import styles from './styles.module.scss';
import {Button, Modal, Radio, Space, Typography} from '@express-24/theseus-ui';
import {useUnit} from 'effector-react/scope';
import {
  $currentBranchActivityId,
  $isBranchActivityModalVisible,
  $selectedBranchActivityPeriod,
  $selectedStoreId,
  branchActivityPeriodChosen,
  branchActivityModalClosed,
  branchActivityChangingConfirmed,
  updateBranchActivityFx,
} from '../../../model';
import {useTranslation} from 'react-i18next';

export const BranchActivityModal = () => {
  const {
    selectedStoreId,
    isBranchActivityModalVisible,
    selectedBranchActivityPeriod,
    currentBranchActivityId,
    handleBranchActivityPeriodChosen,
    handleBranchActivityChangingConfirmed,
    handleBranchActivityModalClosed,
    isBranchActivityChanging,
  } = useUnit({
    selectedStoreId: $selectedStoreId,
    isBranchActivityModalVisible: $isBranchActivityModalVisible,
    selectedBranchActivityPeriod: $selectedBranchActivityPeriod,
    currentBranchActivityId: $currentBranchActivityId,
    handleBranchActivityPeriodChosen: branchActivityPeriodChosen,
    handleBranchActivityChangingConfirmed: branchActivityChangingConfirmed,
    handleBranchActivityModalClosed: branchActivityModalClosed,
    isBranchActivityChanging: updateBranchActivityFx.pending,
  });
  const {t} = useTranslation();

  const BRANCH_ACTIVITY_OPTIONS = [
    {
      id: 'next_30m',
      name: t('for_minutes', {minutes: 30}),
    },
    {
      id: 'next_60m',
      name: t('for_minutes', {minutes: 60}),
    },
    {
      id: 'next_period',
      name: t('next_period'),
    },
  ];

  const onConfirmClick = () => {
    if (!selectedStoreId) return;
    if (!currentBranchActivityId) return;

    handleBranchActivityChangingConfirmed({
      storeId: selectedStoreId,
      branchId: currentBranchActivityId,
      body: {
        activity: {
          isActive: false,
          deactivate: selectedBranchActivityPeriod,
        },
      },
    });
  };

  return (
    <Modal
      visible={isBranchActivityModalVisible}
      onOutsideClick={handleBranchActivityModalClosed}
      onClose={handleBranchActivityModalClosed}
    >
      <Space padding={[16, 24, 16, 16]}>
        <Typography level="h5"
          semibold
        >
          {t('branches_management.period_disable_activity')}
        </Typography>
        <Typography kind="secondary"
          level="sub-text"
        >
          {t('activity_tip')}
        </Typography>
      </Space>
      <Radio.Group onChange={handleBranchActivityPeriodChosen}
        selected={selectedBranchActivityPeriod}
      >
        {BRANCH_ACTIVITY_OPTIONS.map((option) => (
          <Radio key={option.id}
            value={option.id}
          >
            {option.name}
          </Radio>
        ))}
      </Radio.Group>
      <div className={styles.Buttons}>
        <Button kind="secondary"
          size="large"
          onClick={handleBranchActivityModalClosed}
          fluid
        >
          {t('actions.cancel')}
        </Button>
        <Button
          kind="primary"
          size="large"
          loading={isBranchActivityChanging}
          disabled={isBranchActivityChanging}
          onClick={onConfirmClick}
          fluid
        >
          {t('actions.disable')}
        </Button>
      </div>
    </Modal>
  );
};
