import {attachSession} from '@entities/session';
import {OffersList, StopListRequestConfig} from './model.types';
import {fetchStopList, StopListContract} from '@api/v2';
import {createEffect, createEvent, createStore, sample} from 'effector';
import {branchSelected, menuSelected, submenuSelected} from '../model';

export const stopListRequested = createEvent<StopListRequestConfig>();
export const stopListOpened = createEvent();
export const stopListClosed = createEvent();

export const fetchStopListFx = attachSession({
  effect: createEffect(fetchStopList),
  mapParams: ({path, params}: StopListRequestConfig, authorization) => ({
    path,
    params: {
      ...params,
      isAvailable: false,
      attachedOffers: true,
    },
    headers: {
      authorization,
    },
  }),
});

export const $stopList = createStore<StopListContract | null>(null);
export const $stopListProductsCount = createStore<number | null>(null);

export const $isStopListEmpty = createStore<boolean>(false);
export const $isStopListPending = createStore<boolean>(false);
export const $isStopListOpen = createStore<boolean>(false);

export const $offersListByCombinationIds = $stopList.map((stopList) => {
  if (!stopList) return null;

  const {sections} = stopList;

  let result: OffersList = {};

  sections.forEach((section) => {
    section.products.forEach((product) => {
      if (!product.combinations) return;

      product.combinations.forEach((combination) => {
        combination.properties.forEach(({propertyId, optionId}) => {
          if (!product.properties) return;

          const foundProperty = product.properties.find(({id}) => id === propertyId);

          if (foundProperty) {
            const foundOption = foundProperty.options.find(({id}) => id === optionId);

            if (foundOption) {
              result = {
                ...result,
                [combination.productId]: {
                  optionId,
                  propertyId,
                  optionName: foundOption.name,
                  propertyName: foundProperty.name,
                },
              };
            }
          }
        });
      });
    });
  });

  return result;
});

$stopList.on(fetchStopListFx.doneData, (_, {data}) => data);

$stopListProductsCount.on(fetchStopListFx.doneData, (_, {data}) => {
  if (!data.sections) return null;

  return data.sections.reduce((initialValue, currentValue) => {
    return initialValue + currentValue.products.length;
  }, 0);
});

$isStopListPending.on(fetchStopListFx.pending, (_, pending) => pending);

$isStopListEmpty.on(fetchStopListFx.doneData, (_, {data}) => !data.sections.length);

$isStopListOpen.on(stopListOpened, () => true).on(stopListClosed, () => false);

sample({
  clock: stopListRequested,
  target: fetchStopListFx,
});

sample({
  clock: fetchStopListFx.doneData,
  filter: ({data}) => !data.sections.length,
  target: stopListClosed,
});
