import {userIdentified} from '@entities/user';
import {createEffect, sample} from 'effector';
import {fetchAccount} from '@api/v2';
import {attachSession} from '@entities/session';

export const identifyUserApiFx = createEffect(fetchAccount);

export const identifyUserFx = attachSession({
  effect: identifyUserApiFx,
  mapParams: (_, authorization) => ({
    headers: {
      authorization,
    },
  }),
});

sample({
  source: identifyUserFx.doneData,
  fn: ({data: user}) => user,
  target: userIdentified,
});
