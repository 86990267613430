import i18n from 'i18next';
import ru from './config/ru.json';
import uz from './config/uz.json';
import {initReactI18next} from 'react-i18next';

export const languages = [
  {
    value: 'ru',
    label: 'Русский',
  },
  {
    value: 'uz',
    label: 'O’zbekcha',
  },
];

export const localeRu = {
  components: {
    select: {
      computedLabel: 'Выбрано',
    },
    datePicker: {
      confirmButton: 'Подтвердить',
    },
  },
};

export const localeUz = {
  components: {
    select: {
      computedLabel: 'Tanlandi',
    },
    datePicker: {
      confirmButton: 'Tasdiqlash',
    },
  },
};

const currentLanguage = localStorage.getItem('lang') || 'ru';

i18n.use(initReactI18next).init({
  lng: currentLanguage,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ru: {
      translation: ru,
    },
    uz: {
      translation: uz,
    },
  },
});

export default i18n;
