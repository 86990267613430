import React, {useEffect} from 'react';
import styles from './page.module.scss';
import {Space, Typography} from '@express-24/theseus-ui';
import {Filters} from './ui/organisms/filters';
import {useUnit} from 'effector-react';
import {pageMounted, pageUnmounted} from '../model';
import {OrdersTable} from './ui/organisms/orders-table';
import {useTranslation} from 'react-i18next';

export const OrdersHistory = () => {
  const {handlePageMount, handlePageUnmount} = useUnit({
    handlePageMount: pageMounted,
    handlePageUnmount: pageUnmounted,
  });
  const {t} = useTranslation();

  useEffect(() => {
    handlePageMount();

    return () => handlePageUnmount();
  }, []);

  return (
    <div className={styles.Container}>
      <Space padding={[48, 0, 28, 0]}>
        <Typography level="hero">{t('sections.orders_history')}</Typography>
      </Space>
      <Filters />
      <OrdersTable />
    </div>
  );
};
