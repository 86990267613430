import styles from './styles.module.scss';
import {Tag, Typography} from '@express-24/theseus-ui';
import {FC, useMemo} from 'react';
import {OrderCardProps} from './types';
import {CookingTimer} from '../../molecules/cooking-timer';
import {generateTags} from '../../../lib/generate-tags';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const OrderCard: FC<OrderCardProps> = ({order, onClick, isSelected, isPreparing = false}) => {
  const {t} = useTranslation();

  const tagsView = useMemo(() => {
    const tags = generateTags(order, isPreparing);

    return (
      <>
        {tags.map((tag) => (
          <Tag
            size="small"
            key={tag.name}
            textColor={tag.textColor}
            color={tag.color}
            outlined={tag.outlined}
            icon={tag.icon}
          >
            {tag.name}
          </Tag>
        ))}
      </>
    );
  }, [order]);

  const handleOrderClick = () => {
    if (!onClick) return;

    onClick(order.id);
  };

  return (
    <div
      className={styles.OrderCard}
      onClick={handleOrderClick}
      data-selected={isSelected}
      data-viewed={order.isViewed}
    >
      <div className={styles.Order}>
        <div className={styles.OrderHeader}>
          <Typography level="h4"
            semibold
          >
            #{order.id}
          </Typography>
        </div>
        <div className={styles.Labels}>{tagsView}</div>
        <div className={styles.OrderInfo}>
          <Typography level="text">
            {t('order_cost')}:
            <span className={styles.OrderValues}>
              &nbsp;{order.payment.receipt.total.toLocaleString()} {order.payment.currency}
            </span>
          </Typography>
          <Typography level="text">
            {t('store')}:<span className={styles.OrderValues}>&nbsp;{order.store.name}</span>
          </Typography>
          <Typography level="text">
            {t('branch')}:<span className={styles.OrderValues}>&nbsp;{order.store.branch.name}</span>
          </Typography>
          {i18n.language === 'uz' ? (
            <>
              {order.status.code !== 'preOrder' && !order.isReady && order.prepareTime.iso ? (
                <Typography level="text">
                  <span className={styles.OrderValues}>{order.prepareTime.iso}</span> {t('orders.prepare_to')}
                </Typography>
              ) : null}
              {order.status.code === 'preOrder' && order.date.preorderTo && (
                <Typography level="text">
                  <span className={styles.OrderValues}>{order.date.preorderTo}</span> {t('orders.pre_order_to')}
                </Typography>
              )}
            </>
          ) : (
            <>
              {order.status.code !== 'preOrder' && !order.isReady && order.prepareTime.iso ? (
                <Typography level="text">
                  {t('orders.prepare_to')}:<span className={styles.OrderValues}>&nbsp;{order.prepareTime.iso}</span>
                </Typography>
              ) : null}
              {order.status.code === 'preOrder' && order.date.preorderTo && (
                <Typography level="text">
                  {t('orders.pre_order_to')}:<span className={styles.OrderValues}>&nbsp;{order.date.preorderTo}</span>
                </Typography>
              )}
            </>
          )}
          {order.courier && order.courier.type && (
            <Typography level="text">
              {t('orders.transfer_type')}:<span className={styles.OrderValues}>&nbsp;{order.courier.type.name}</span>
            </Typography>
          )}

          <Typography level="sub-text"
            kind="secondary"
          >
            {order.date.createdAt}
          </Typography>
        </div>
      </div>
      <div className={styles.OrderTimer}>
        {order.status.code === 'merchantPreparingOrder' && <CookingTimer deadline={order.prepareTime.iso} />}
      </div>
    </div>
  );
};
