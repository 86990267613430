import React, {useMemo} from 'react';
import styles from './styles.module.scss';
import {EmptyState} from '@widgets/empty-state';
import {useUnit} from 'effector-react/scope';
import {$branchDetails, $branchSchedule} from '../../../model';
import {Space, Table, Typography} from '@express-24/theseus-ui';
import {useTranslation} from 'react-i18next';

export const BranchDetails = () => {
  const {branchDetails, branchSchedule} = useUnit({
    branchDetails: $branchDetails,
    branchSchedule: $branchSchedule,
  });
  const {t} = useTranslation();

  const noSelectedBranchView = useMemo(() => {
    if (branchDetails) return;

    return (
      <div className={styles.NoSelectedBranch}>
        <EmptyState
          illustration="noOrders"
          title={t('branches_management.no_selected_branch')}
          description={t('branches_management.no_selected_branch_tip')}
        />
      </div>
    );
  }, [branchDetails]);

  const scheduleContentView = useMemo(() => {
    if (!branchSchedule) return;

    return (
      <div className={styles.TableBranches}>
        <Typography level="h3"
          className={styles.BranchDetailsTitle}
        >
          {t('branches_management.schedule')}
        </Typography>
        <Table
          source={branchSchedule}
          indexKey="day"
          columns={[
            {
              title: t('branches_management.week_days'),
              indexKey: 'day',
              render: (branch) => branch.day,
            },
            {
              title: t('branches_management.work_time'),
              indexKey: 'time',
              render: (branch) => {
                return (
                  <>
                    {branch.time.map((time, index) => {
                      return (
                        <Space padding={[0, 0, 4, 0]}
                          key={time.start + index}
                        >
                          {`${time.start} - ${time.end}`}
                        </Space>
                      );
                    })}
                  </>
                );
              },
            },
          ]}
        />
      </div>
    );
  }, [branchSchedule]);

  return (
    <div className={styles.BranchDetails}>
      {noSelectedBranchView}
      {scheduleContentView}
    </div>
  );
};
