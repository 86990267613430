import styles from './styles.module.scss';
import {Icon} from '@express-24/theseus-ui';

export type Rating = {
  rating: number;
};

const STARS_COUNT = 5;
const STARS_ARRAY = Array.from({length: STARS_COUNT}, (_, index) => index);

export const StarRating = ({rating}: Rating) => {
  return (
    <div className={styles.StarRating}>
      {STARS_ARRAY.map((star) => (
        <div key={star}
          className={styles.Star}
          data-filled={star < rating}
        >
          <Icon size={16}
            name="star"
          />
        </div>
      ))}
    </div>
  );
};
