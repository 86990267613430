import {createPortal} from 'react-dom';
import {useUnit} from 'effector-react';
import styles from './styles.module.scss';
import * as model from '../../../../model';
import {Button, Icon, Modal, Typography} from '@express-24/theseus-ui';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useTranslation} from 'react-i18next';

export const ProductsSortModal = () => {
  const {
    handleSortSubmit,
    handleSortDragEnd,
    handleProductsModalSortClose,

    products,

    isSortPending,
    isProductsModalSortOpen,
  } = useUnit({
    handleSortSubmit: model.productsSortSubmitted,
    handleSortDragEnd: model.productsSortDragEnded,
    handleProductsModalSortClose: model.productsSortModalCloseRequested,

    products: model.$orderedProducts,

    isSortPending: model.$isProductsSortPending,
    isProductsModalSortOpen: model.$isProductsSortModalOpened,
  });
  const {t} = useTranslation();

  if (!products) return null;

  return (
    <Modal
      size="medium"
      visible={isProductsModalSortOpen}
      onClose={handleProductsModalSortClose}
      onOutsideClick={handleProductsModalSortClose}
    >
      <div className={styles.Sort}>
        <Typography semibold
          level="h4"
          className={styles.SortTitle}
        >
          {t('menu.products_sort')}
        </Typography>
        <DragDropContext onDragEnd={handleSortDragEnd}>
          <Droppable droppableId="products">
            {({innerRef, placeholder}) => (
              <div ref={innerRef}
                className={styles.SortList}
              >
                {products.map(({id, name, price}, index) => (
                  <Draggable key={id}
                    index={index}
                    draggableId={id.toString()}
                  >
                    {(provided, snapshot) => {
                      const portalContainer = document.getElementById('portal');
                      const draggableNode = (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={styles.SortProduct}
                          data-is-dragging={snapshot.isDragging}
                        >
                          <div className={styles.SortHandler}>
                            <Icon size={24}
                              name="menu-burger"
                            />
                          </div>
                          <div className={styles.SortProductContent}>
                            <Typography level="text"
                              className={styles.SortProductTitle}
                            >
                              {name}
                            </Typography>
                            <Typography bold
                              level="text"
                            >
                              {price.sold.toLocaleString()} {price.currency}
                            </Typography>
                          </div>
                          <div className={styles.SortBadge}>
                            <Typography semibold
                              level="sub-text"
                            >
                              {index + 1}
                            </Typography>
                          </div>
                        </div>
                      );

                      if (snapshot.isDragging && portalContainer) {
                        return createPortal(draggableNode, portalContainer);
                      }

                      return draggableNode;
                    }}
                  </Draggable>
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={styles.SortActions}>
          <Button fluid
            size="large"
            kind="secondary"
            onClick={handleProductsModalSortClose}
          >
            {t('actions.cancel')}
          </Button>
          <Button fluid
            size="large"
            loading={isSortPending}
            onClick={handleSortSubmit}
          >
            {t('actions.save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
