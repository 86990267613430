import {forwardRef, PropsWithChildren, useMemo, MouseEvent} from 'react';
import {ImageCover} from 'shared/ui/molecules/image-cover';
import {Button, Counter, Tag, Typography} from '@express-24/theseus-ui';
import styles from './styles.module.scss';
import {MenuProduct} from '@api/v2';
import {ProductOptionPicker} from '../product-dialog/view/ui';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

type UpdateProduct = {
  id: number;
  count: number;
};

type ProductCardProps = {
  product: MenuProduct;
  id: number;
  title: string;
  cover: string[];
  amount?: number;
  inCartCount?: number;
  isAvailable: boolean;
  price: {
    currency: string;
    sold: number;
    prime: number;
  };
  onCounterChange?: ({id, count}: UpdateProduct) => void;
  onCardClick?: (id: number) => void;
  onButtonClick?: (id: number) => void;
  loading?: boolean;
};

export const ProductCard = forwardRef<HTMLDivElement, PropsWithChildren<ProductCardProps>>(
  (
    {
      product,
      id,
      title,
      cover,
      amount = Infinity,
      inCartCount,
      isAvailable,
      price,
      onCounterChange,
      onCardClick,
      onButtonClick,
      loading,
    },
    passedRef,
  ) => {
    const {t} = useTranslation();

    const handleCounterChange = (count: number) => {
      if (!onCounterChange) return;

      return onCounterChange({id, count});
    };

    const handleCardClick = () => {
      if (onCardClick) {
        return onCardClick(id);
      }
    };

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (onButtonClick) {
        return onButtonClick(id);
      }
    };

    const contentView = useMemo(() => {
      return (
        <div className={styles.Content}>
          <Typography level="text"
            lineClamp={2}
            kind={isAvailable ? 'primary' : 'disabled'}
          >
            {title}
          </Typography>
          {inCartCount && (
            <Typography level="caption"
              kind="secondary"
            >
              {price.sold.toLocaleString()} {price.currency}
            </Typography>
          )}
        </div>
      );
    }, [title, inCartCount, price]);

    const fewInStockLabel = useMemo(() => {
      const minProductCount = 5;

      if (!isAvailable) return;
      if (amount > minProductCount) return;

      return (
        <div className={styles.CardLabel}
          data-type="stock"
        >
          <Tag size="small"
            kind="danger"
            rounded={false}
          >
            {amount === 0 ? t('no_more') : `${t('orders.left')} ${amount}`}
          </Tag>
        </div>
      );
    }, [amount, i18n.language]);

    const footerView = useMemo(() => {
      if (typeof inCartCount === 'number') {
        return (
          <Counter fluid
            shadow
            kind="ghost"
            max={amount}
            count={inCartCount}
            onUpdate={handleCounterChange}
          />
        );
      }

      return (
        <Button
          fluid
          shadow
          kind="secondary"
          size="medium"
          loading={loading}
          disabled={!isAvailable}
          onClick={handleButtonClick}
        >
          {isAvailable ? (
            <>
              {price.sold?.toLocaleString()} {price.currency}
            </>
          ) : (
            t('unavailable')
          )}
        </Button>
      );
    }, [inCartCount, amount, loading, isAvailable, price, i18n.language]);

    return (
      <div className={styles.Card}
        onClick={handleCardClick}
        data-disabled={!isAvailable}
        ref={passedRef}
      >
        <ImageCover coverUrl={cover}
          isAvailable={isAvailable}
        >
          {fewInStockLabel}
        </ImageCover>
        {contentView}
        <div className={styles.Footer}>{footerView}</div>
      </div>
    );
  },
);

ProductCard.displayName = 'ProductOptionPicker';
