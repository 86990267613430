import {ChangeEvent} from 'react';
import {history} from '@shared/history';
import {attachSession} from '@entities/session';
import {createEffect, createEvent, createStore, sample, guard} from 'effector';
import {deleteProduct, fetchProductDetails, updateProductDetails, ProductDetailsContract} from '@api/v2';
import {
  Branch,
  OfferBranch,
  ProductQuantity,
  DeactivateParams,
  FormattedBranches,
  ProductDeleteConfig,
  ProductCombinations,
  FormattedProperties,
  ProductUpdateParams,
  ProductDeleteParams,
  ProductDetailsParams,
  FormattedOfferBranches,
  ProductCombinationDetail,
} from './model.types';
import {notification} from '@express-24/theseus-ui';
import {getOnlyUpdatedKeys} from '@shared/lib/get-updated-fileds';
import i18n from 'i18next';

export const INFINITE_QUANTITY_KEY = 'infinite';
export const NON_INFINITE_QUANTITY_KEY = 'non-infinite';

export const QUANTITY_OPTIONS = [
  {
    value: INFINITE_QUANTITY_KEY,
    name: 'infinity',
  },
  {
    value: NON_INFINITE_QUANTITY_KEY,
    name: 'set_amount',
  },
];

export const pageClosed = createEvent();
export const pageOpened = createEvent<ProductDetailsParams>();

export const activityDialogClosed = createEvent();
export const offerDetailsSubmitted = createEvent();
export const deleteProductSubmitted = createEvent();
export const productDetailsSubmitted = createEvent();
export const offerDetailsDrawerClosed = createEvent();
export const deleteProductModalOpened = createEvent();
export const deleteProductModalClosed = createEvent();
export const activityDialogOpened = createEvent<number>();
export const offerDetailsDrawerOpened = createEvent<number>();
export const productQuantityChanged = createEvent<ProductQuantity>();
export const deactivateOfferSubmitted = createEvent<DeactivateParams>();
export const productQuantityTypeChanged = createEvent<ProductQuantity>();
export const offerPriceChanged = createEvent<ChangeEvent<HTMLInputElement>>();
export const productPriceChanged = createEvent<ChangeEvent<HTMLInputElement>>();

export const $storeId = createStore<number | null>(null);
export const $branchId = createStore<number | null>(null);
export const $productId = createStore<number | null>(null);
export const $selectedOfferId = createStore<number | null>(null);
export const $offers = createStore<ProductCombinations | null>(null);
export const $selectedOfferBranchId = createStore<number | null>(null);
export const $product = createStore<ProductDetailsContract | null>(null);
export const $updatedProduct = createStore<ProductDetailsContract | null>(null);
export const $formattedBranches = createStore<FormattedBranches | null>(null);
export const $selectedOffer = createStore<ProductCombinationDetail | null>(null);
export const $formattedOfferBranches = createStore<FormattedOfferBranches | null>(null);

export const $isOfferUpdatePending = createStore<boolean>(false);
export const $isEditOfferDrawerOpen = createStore<boolean>(false);
export const $isProductUpdatePending = createStore<boolean>(false);
export const $isActivityDialogOpened = createStore<boolean>(false);
export const $isDeleteProductModalOpen = createStore<boolean>(false);

const historyGoBackFx = createEffect(() => {
  history.goBack();
});

const updateProductNotificationFx = createEffect(() => {
  notification.success({
    title: i18n.t('product_success_update'),
    duration: 5000,
  });
});

const deleteProductNotificationFx = createEffect(() => {
  notification.error({
    title: i18n.t('product_success_delete'),
    duration: 5000,
  });
});

export const fetchProductDetailsFx = attachSession({
  effect: createEffect(fetchProductDetails),
  mapParams: ({storeId, branchId, productId}: ProductDetailsParams, authorization) => ({
    path: {
      storeId,
      productId,
    },
    params: {
      branchId,
    },
    headers: {
      authorization,
    },
  }),
});

export const updateProductFx = attachSession({
  effect: createEffect(updateProductDetails),
  mapParams: ({path, params, data}: ProductUpdateParams, authorization) => ({
    data,
    path: {
      storeId: path.storeId,
      productId: path.productId,
    },
    params: {
      branchId: params.branchId,
    },
    headers: {
      authorization,
    },
  }),
});

export const deleteProductFx = attachSession({
  effect: createEffect(deleteProduct),
  mapParams: ({path}: ProductDeleteParams, authorization) => ({
    path: {
      storeId: path.storeId,
      productId: path.productId,
    },
    headers: {
      authorization,
    },
  }),
});

export const updateOfferFx = attachSession({
  effect: createEffect(updateProductDetails),
  mapParams: ({path, params, data}: ProductUpdateParams, authorization) => ({
    data,
    path: {
      storeId: path.storeId,
      productId: path.productId,
    },
    params: {
      branchId: params.branchId,
    },
    headers: {
      authorization,
    },
  }),
});

export const $isProductWithCombinations = $product.map((product) => {
  if (!product) return false;

  return Boolean(product.combinations.length);
});

$storeId.on(pageOpened, (_, {storeId}) => storeId).on(pageClosed, () => null);

$branchId.on(pageOpened, (_, {branchId}) => branchId).on(pageClosed, () => null);

$productId.on(pageOpened, (_, {productId}) => productId).on(pageClosed, () => null);

$selectedOffer.on(offerDetailsDrawerClosed, () => null);

$selectedOfferId.on(offerDetailsDrawerOpened, (_, offerId) => offerId).on(offerDetailsDrawerClosed, () => null);

$selectedOfferBranchId
  .on(activityDialogOpened, (_, branchId) => branchId)
  .on([activityDialogClosed, pageClosed], () => null);

$product.on(fetchProductDetailsFx.doneData, (_, {data}) => data);

$updatedProduct
  .on(productPriceChanged, (product, {currentTarget}) => {
    if (!product) return null;

    return {
      ...product,
      price: {
        ...product.price,
        sold: Number(currentTarget.value),
      },
    };
  })
  .on(pageClosed, () => null);

$selectedOffer.on(offerPriceChanged, (offer, {currentTarget}) => {
  if (!offer) return null;

  return {
    ...offer,
    price: {
      ...offer.price,
      sold: Number(currentTarget.value),
    },
  };
});

$formattedBranches
  .on(productQuantityTypeChanged, (branches, {id, value}) => {
    if (!branches) return null;

    return {
      ...branches,
      [id]: {
        ...branches[id],
        amount: {
          ...branches[id].amount,
          isInfinite: value === INFINITE_QUANTITY_KEY,
        },
      },
    };
  })
  .on(productQuantityChanged, (branches, {id, value}) => {
    if (!branches) return null;

    return {
      ...branches,
      [id]: {
        ...branches[id],
        amount: {
          ...branches[id].amount,
          value: Number(value),
        },
      },
    };
  })
  .on(pageClosed, () => null);

$formattedOfferBranches.on(deactivateOfferSubmitted, (branches, {id, unavailableTill, checked}) => {
  if (!branches) return null;

  return {
    ...branches,
    [id]: {
      ...branches[id],
      availability: {
        isAvailable: checked,
        unavailableTill: unavailableTill,
      },
    },
  };
});

$isOfferUpdatePending.on(updateOfferFx.pending, (_, pending) => pending);
$isProductUpdatePending.on(updateProductFx.pending, (_, pending) => pending);

$isDeleteProductModalOpen.on(deleteProductModalOpened, () => true).on(deleteProductModalClosed, () => false);

$isActivityDialogOpened
  .on(activityDialogOpened, () => true)
  .on([activityDialogClosed, deactivateOfferSubmitted], () => false);

$isEditOfferDrawerOpen
  .on(offerDetailsDrawerOpened, () => true)
  .on([offerDetailsDrawerClosed, updateOfferFx.done, pageClosed], () => false);

sample({
  clock: pageOpened,
  target: fetchProductDetailsFx,
});

sample({
  source: $product,
  fn: (product) => {
    if (!product) return null;

    const branchesList: Record<number, Branch> = {};

    for (const branch of product.branches) {
      branchesList[branch.id] = branch;
    }

    return branchesList;
  },
  target: $formattedBranches,
});

sample({
  clock: $product,
  target: $updatedProduct,
});

sample({
  source: $selectedOffer,
  fn: (offer) => {
    if (!offer) return null;

    const branchesList: Record<number, OfferBranch> = {};

    for (const branch of offer.branches) {
      branchesList[branch.id] = branch;
    }

    return branchesList;
  },
  target: $formattedOfferBranches,
});

sample({
  clock: fetchProductDetailsFx.doneData,
  fn: ({data}) => {
    const {properties, combinations} = data;

    return combinations.map((combination) => {
      const propertiesData: FormattedProperties = [];

      combination.properties.forEach(({propertyId, optionId}) => {
        const foundProperty = properties.find(({id}) => id === propertyId);

        if (foundProperty) {
          const foundOption = foundProperty.options.find(({id}) => id === optionId);

          if (foundOption) {
            propertiesData.push({
              optionId,
              propertyId,
              optionName: foundOption.name,
              propertyName: foundProperty.name,
            });
          }
        }
      });

      return {
        ...combination,
        properties: propertiesData,
      };
    });
  },
  target: $offers,
});

sample({
  source: {
    product: $product,
    updatedProduct: $updatedProduct,
    branches: $formattedBranches,
  },
  clock: guard({
    source: {
      storeId: $storeId,
      branchId: $branchId,
      productId: $productId,
    },
    clock: productDetailsSubmitted,
    filter: (data): data is ProductDetailsParams => data !== null,
  }),
  fn: ({product, updatedProduct, branches}, {storeId, branchId, productId}) => {
    const branchesList: Array<Branch> = [];

    for (const branch in branches) {
      branchesList.push(branches[branch]);
    }

    // @ts-ignore
    const updatedBranches: Array<Branch> = getOnlyUpdatedKeys(branchesList, product?.branches);
    const filteredBranches = updatedBranches.filter((element) => element !== null);

    return {
      path: {
        storeId,
        productId,
      },
      params: {
        branchId,
      },
      data: {
        price: updatedProduct?.price !== product?.price ? updatedProduct?.price : undefined,
        branches: filteredBranches,
      },
    };
  },
  target: updateProductFx,
});

sample({
  source: {
    offer: $selectedOffer,
    branches: $formattedOfferBranches,
  },
  clock: guard({
    source: {
      storeId: $storeId,
      branchId: $branchId,
      productId: $selectedOfferId,
    },
    clock: offerDetailsSubmitted,
    filter: (data): data is ProductDetailsParams => data !== null,
  }),
  fn: ({offer, branches}, {storeId, branchId, productId}) => {
    const branchesList: Array<Branch> = [];

    for (const branch in branches) {
      branchesList.push(branches[branch]);
    }
    //@ts-ignore
    const updatedBranches: Array<Branch> = getOnlyUpdatedKeys(branchesList, offer?.branches);
    const filteredBranches = updatedBranches.filter((element) => element !== null);

    return {
      path: {
        storeId,
        productId: productId,
      },
      params: {
        branchId,
      },
      data: {
        price: offer?.price,
        branches: filteredBranches,
      },
    };
  },
  target: updateOfferFx,
});

sample({
  clock: updateOfferFx.done,
  target: offerDetailsDrawerClosed,
});

sample({
  source: $offers,
  clock: offerDetailsDrawerOpened,
  fn: (offers, selectedOfferId) => {
    if (!offers) return null;

    const foundOffer = offers.find(({productId}) => productId === selectedOfferId);

    if (foundOffer) return foundOffer;

    return null;
  },
  target: $selectedOffer,
});

sample({
  source: guard({
    source: {
      productId: $productId,
      storeId: $storeId,
    },
    filter: (data): data is ProductDeleteConfig => data !== null,
    clock: deleteProductSubmitted,
  }),
  fn: ({storeId, productId}) => ({
    path: {
      storeId,
      productId,
    },
  }),
  target: deleteProductFx,
});

sample({
  clock: deleteProductFx.done,
  target: [deleteProductModalClosed, deleteProductNotificationFx, historyGoBackFx],
});

sample({
  clock: updateProductFx.done,
  target: updateProductNotificationFx,
});

sample({
  source: guard({
    source: {
      storeId: $storeId,
      branchId: $branchId,
      productId: $productId,
    },
    clock: [updateProductFx.done, updateOfferFx.done],
    filter: (data): data is ProductDetailsParams => data !== null,
  }),
  target: fetchProductDetailsFx,
});
