import {Space, Typography} from '@express-24/theseus-ui';

type ProductContentProps = {
  title: string;
};

export const ProductContent = ({title}: ProductContentProps) => {
  return (
    <Space padding={16}>
      <Typography level="h5"
        semibold
      >
        {title}
      </Typography>
    </Space>
  );
};
