import {Stores} from './model.types';
import {combine, createEvent, createStore, guard, sample} from 'effector';

export const $stores = createStore<Stores | null>(null);
export const $selectedStoreId = createStore<number | null>(null);
export const $selectedStore = combine($stores, $selectedStoreId, (stores, storeId) => {
  if (!stores) return;
  return stores.find((store) => store.id === storeId);
});
export const $storesOptions = $stores.map((stores) => {
  if (!stores) return null;

  return stores.map((store) => {
    return {
      value: store.id,
      label: store.name,
      activity: store.activity,
    };
  });
});

export const $activeBranchesOptions = $stores.map((stores) => {
  if (!stores) return null;

  const branches = [];

  for (let i = 0; i < stores.length; i++) {
    for (let j = 0; j < stores[i].branches.length; j++) {
      if (stores[i].branches[j].activity.isActive) {
        branches.push({
          label: stores[i].branches[j].name,
          caption: stores[i].name,
          value: stores[i].branches[j].id,
        });
      }
    }
  }

  if (branches.length === 0) {
    return null;
  }

  return branches;
});

export const $allBranchesOptions = $stores.map((stores) => {
  if (!stores) return null;

  const branches = [];

  for (let i = 0; i < stores.length; i++) {
    for (let j = 0; j < stores[i].branches.length; j++) {
      branches.push({
        label: stores[i].branches[j].name,
        caption: stores[i].name,
        value: stores[i].branches[j].id,
      });
    }
  }

  if (branches.length === 0) {
    return null;
  }

  return branches;
});

export const attachedStoresChanged = createEvent<Stores>();
export const storesReset = createEvent();
export const currentStoreChanged = createEvent<number>();

sample({
  source: guard({
    clock: $stores,
    filter: (stores): stores is Stores => stores !== null,
  }),
  fn: (stores) => stores[0].id,
  target: currentStoreChanged,
});

$stores.on(storesReset, () => null).on(attachedStoresChanged, (_, stores) => stores);
$selectedStoreId.on(currentStoreChanged, (_, storeId) => storeId);
