import React, {useEffect, useMemo} from 'react';
import {useUnit} from 'effector-react';
import {
  $attachedBranches,
  $filteredEmployees,
  $selectedBranchId,
  $selectedEmployee,
  $selectedEmployeeId,
  $temporaryUserInfo,
  activityChanged,
  branchSelected,
  editFormSubmitted,
  employeeInfoEdited,
  employeeSelected,
  pageMounted,
  pageUnmounted,
  $isFormEdited,
  $editInputErrors,
  $inputStatuses,
  ROLE_NAMES,
  ROLE_OPTIONS,
  createEmployeeModalOpened,
  createEmployeeModalClosed,
  $isCreateEmployeeModalOpen,
  updateEmployeeFx,
} from '../model';
import {Button, Input, List, Modal, Select, Space, Toggle, Typography} from '@express-24/theseus-ui';
import styles from './page.module.scss';
import {EmptyState} from '@widgets/empty-state';
import {CreateEmployeeForm} from './ui/organisms/create-employee-form';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const RolesManagement = () => {
  const {
    handlePageMount,
    handlePageUnmount,
    employees,
    selectedEmployeeId,
    handleSelectEmployee,
    filteredBranches,
    handleActivityChange,
    handleBranchSelect,
    selectedBranchId,
    selectedEmployee,
    temporaryEmployeeInfo,
    handleInfoEdit,
    isUserInfoEdited,
    handleEditingSubmit,
    inputErrors,
    inputStatuses,
    handleOpenModal,
    handleCloseModal,
    isCreateModalOpen,
    isEmployeeUpdating,
  } = useUnit({
    handlePageMount: pageMounted,
    handlePageUnmount: pageUnmounted,
    handleInfoEdit: employeeInfoEdited,
    employees: $filteredEmployees,
    temporaryEmployeeInfo: $temporaryUserInfo,
    selectedBranchId: $selectedBranchId,
    handleBranchSelect: branchSelected,
    selectedEmployeeId: $selectedEmployeeId,
    handleSelectEmployee: employeeSelected,
    filteredBranches: $attachedBranches,
    handleActivityChange: activityChanged,
    selectedEmployee: $selectedEmployee,
    isUserInfoEdited: $isFormEdited,
    handleEditingSubmit: editFormSubmitted,
    inputErrors: $editInputErrors,
    inputStatuses: $inputStatuses,
    handleOpenModal: createEmployeeModalOpened,
    handleCloseModal: createEmployeeModalClosed,
    isCreateModalOpen: $isCreateEmployeeModalOpen,
    isEmployeeUpdating: updateEmployeeFx.pending,
  });
  const {t} = useTranslation();

  useEffect(() => {
    handlePageMount();

    return () => handlePageUnmount();
  }, []);

  const listControlView = useMemo(() => {
    if (!employees) return;

    return (
      <>
        <Space padding={8} />
        <List>
          {employees.map((employee) => (
            <div
              className={styles.Employee}
              key={employee.id}
              data-selected={selectedEmployeeId === employee.id}
              onClick={() => handleSelectEmployee(employee.id)}
            >
              <Space padding={[0, 8]}>
                <List.Element
                  title={employee.name}
                  caption={(
                    <Typography kind="secondary"
                      level="sub-text"
                    >
                      {t(`roles_management.${ROLE_NAMES[employee.roleId]}`)}
                    </Typography>
                  )}
                  postfix={(
                    <div className={styles.ActivityControl}>
                      <Space padding={[5, 5]} />
                      <Toggle
                        onChange={() =>
                          handleActivityChange({
                            id: employee.id,
                            isActive: !employee.isActive,
                          })
                        }
                        checked={employee.isActive}
                      />
                    </div>
                  )}
                ></List.Element>
              </Space>
            </div>
          ))}
          <Space padding={24}>
            <Button onClick={handleOpenModal}
              size="medium"
              kind="secondary"
              fluid
            >
              {t('roles_management.add_employee')}
            </Button>
          </Space>
        </List>
      </>
    );
  }, [employees, selectedEmployeeId, selectedBranchId, filteredBranches, i18n.language]);

  const employeeControlView = useMemo(() => {
    if (!temporaryEmployeeInfo) return;
    if (!filteredBranches) return;
    if (!selectedEmployee) return;

    return (
      <div className={styles.EmployeeInfo}>
        <div className={styles.EmployeeControl}>
          <Typography level="h4"
            semibold
          >
            {selectedEmployee.name}
          </Typography>
          <Space padding={12} />
          <div className={styles.Selects}>
            <Select
              mode="multiple"
              selected={temporaryEmployeeInfo.attachedBranchesIds}
              options={filteredBranches}
              onSelect={(branchesIds) => handleInfoEdit({attachedBranchesIds: branchesIds})}
              label={t('choose_branch')}
              message={inputErrors?.attachedBranchesIds}
              status={inputStatuses['attachedBranchesIds']}
              fluid
            />
            <Select
              mode="single"
              selected={temporaryEmployeeInfo.roleId}
              options={ROLE_OPTIONS.map((role) => ({...role, label: t(`roles_management.${role.label}`)}))}
              onSelect={(roleId) => handleInfoEdit({roleId: roleId})}
              label={t('choose_role')}
              fluid
            />
          </div>
          <div className={styles.EmployeeForm}>
            <Input
              label={t('name')}
              value={temporaryEmployeeInfo.name}
              onChange={({target}) => handleInfoEdit({name: target.value})}
              message={inputErrors?.name}
              status={inputStatuses['name']}
              fluid
            />
            <Input
              fluid
              allowClear
              label={t('phone_number')}
              message={inputErrors?.phone}
              status={inputStatuses['phone']}
              value={temporaryEmployeeInfo.phone}
              onChange={({target}) => handleInfoEdit({phone: target.value})}
            />
            <Input
              label={t('login')}
              value={temporaryEmployeeInfo.login}
              onChange={({target}) => handleInfoEdit({login: target.value})}
              message={inputErrors?.login}
              status={inputStatuses['login']}
              fluid
            />
            <Input
              label={t('password')}
              onChange={({target}) => handleInfoEdit({password: target.value})}
              value={temporaryEmployeeInfo.password}
              message={inputErrors?.password}
              status={inputStatuses['password']}
              fluid
            />
          </div>
        </div>
        <div className={styles.SubmitForm}>
          <div className={styles.SubmitButton}>
            <Button
              size="large"
              kind="primary"
              disabled={!isUserInfoEdited || isEmployeeUpdating}
              onClick={handleEditingSubmit}
              loading={isEmployeeUpdating}
              fluid
            >
              {t('actions.save_changes')}
            </Button>
          </div>
        </div>
      </div>
    );
  }, [
    temporaryEmployeeInfo,
    filteredBranches,
    isUserInfoEdited,
    inputErrors,
    inputStatuses,
    selectedEmployee,
    isEmployeeUpdating,
    i18n.language,
  ]);

  const noEmployeeView = useMemo(() => {
    if (selectedEmployee) return;

    return (
      <div className={styles.EmptyUser}>
        <EmptyState
          illustration="noUsers"
          title={t('roles_management.no_selected_employee')}
          description={t('roles_management.no_selected_employee_tip')}
        />
      </div>
    );
  }, [selectedEmployee, i18n.language]);

  const noEmployeeListView = useMemo(() => {
    if (employees) return;

    return (
      <div className={styles.EmptyList}>
        <EmptyState
          illustration="noList"
          title={t('roles_management.empty_employee_list')}
          description={t('roles_management.no_branch_employees')}
        />
      </div>
    );
  }, [employees, i18n.language]);

  return (
    <div className={styles.EmployeesPage}>
      <div className={styles.EmployeesList}>
        <Space padding={[0, 24]}>
          <Space padding={[32, 0]}>
            <Typography level="h1"
              kind="primary"
            >
              {t('sections.roles_management')}
            </Typography>
          </Space>
          {filteredBranches && (
            <Select
              onSelect={handleBranchSelect}
              mode="single"
              label={t('branch')}
              selected={selectedBranchId}
              fluid
              renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
              options={filteredBranches}
            />
          )}
        </Space>
        {listControlView}
        {noEmployeeListView}
      </div>
      {employeeControlView}
      {noEmployeeView}
      <Modal visible={isCreateModalOpen}
        onClose={handleCloseModal}
      >
        <CreateEmployeeForm />
      </Modal>
    </div>
  );
};
