import React, {useMemo} from 'react';
import {Select, Space, Typography, Toggle} from '@express-24/theseus-ui';
import styles from './styles.module.scss';
import {useUnit} from 'effector-react/scope';
import {$storesOptions, $stores} from '@entities/attached-stores';
import {
  $selectedBranchId,
  $selectedStoreId,
  branchActivitySwitcherChanged,
  branchSelected,
  storeSelected,
} from '../../../model';
import {StoresBranch} from '@api/v2';
import {BranchActivityModal} from '../branch-activity-modal';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const BranchesList = () => {
  const {
    stores,
    storesOptions,
    selectedStoreId,
    selectedBranchId,
    handleStoreSelected,
    handleBranchSelected,
    handleBranchActivitySwitcherChanged,
  } = useUnit({
    stores: $stores,
    storesOptions: $storesOptions,
    selectedStoreId: $selectedStoreId,
    selectedBranchId: $selectedBranchId,
    handleStoreSelected: storeSelected,
    handleBranchSelected: branchSelected,
    handleBranchActivitySwitcherChanged: branchActivitySwitcherChanged,
  });
  const {t} = useTranslation();

  const selectBranchesView = useMemo(() => {
    if (!storesOptions) return;

    return (
      <Select
        onSelect={handleStoreSelected}
        selected={selectedStoreId}
        mode="single"
        label={t('branch')}
        renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
        options={storesOptions}
      />
    );
  }, [storesOptions, selectedStoreId, i18n.language]);

  const onBranchClick = (branch: StoresBranch) => {
    if (!selectedStoreId) return;
    if (branch.id === selectedBranchId) return;

    handleBranchSelected({
      branchId: branch.id,
      storeId: selectedStoreId,
    });
  };

  const onBranchActivityChange = (branchId: number, targetBranchActivity: boolean) => {
    if (!selectedStoreId) return;

    handleBranchActivitySwitcherChanged({
      storeId: selectedStoreId,
      branchId,
      targetBranchActivity,
    });
  };

  const branchesListView = useMemo(() => {
    if (!stores) return;

    const selectedStore = stores.find((item) => item.id === selectedStoreId);

    if (!selectedStore) return;

    return selectedStore.branches.map((branch) => {
      const isBranchActive = branch.activity.isActive;
      const unblockingDate = dayjs(branch.blocking.unblockAt).format('DD.MM.YYYY, HH:mm');

      return (
        <div className={styles.Branch}
          key={branch.id}
          data-selected={selectedBranchId === branch.id}
        >
          <div className={styles.BranchInfo}
            onClick={() => onBranchClick(branch)}
          >
            {branch.name}
            <Space padding={2} />
            {branch.blocking.unblockAt && (
              <Typography level="sub-text"
                kind="secondary"
              >
                {t('off_date', {date: unblockingDate})}
              </Typography>
            )}
          </div>
          <div className={styles.BranchSwitch}>
            <Toggle onChange={() => onBranchActivityChange(branch.id, !isBranchActive)}
              checked={isBranchActive}
            />
          </div>
        </div>
      );
    });
  }, [stores, selectedStoreId, selectedBranchId, i18n.language]);

  return (
    <div className={styles.BranchesList}>
      <div className={styles.BranchesListHeader}>
        <Typography level="h1">{t('sections.branches_management')}</Typography>
        <Space padding={20} />
        {selectBranchesView}
      </div>
      {branchesListView}
      <BranchActivityModal />
    </div>
  );
};
