import React, {useMemo, forwardRef, useState, useEffect} from 'react';
import styles from './styles.module.scss';
import {Typography} from '@express-24/theseus-ui';
import {ProductCard} from 'widgets/product-card';
import {MenuContract} from '@api/v2';
import {
  $incidentStoreParams,
  $modifiedProductsOrder,
  $productsForReplacement,
  productCardClicked,
  counterChangedProductForReplacement,
  $productsOrder,
} from '../../model';
import {useUnit} from 'effector-react';
import {IntersectionObserve} from '@shared/lib/intersection-observe';

type ProductListProps = {
  isSearchInputFocused: boolean;
  sourceData: MenuContract;
  onSectionIntersect: (name: string) => void;
  productCartRef: React.RefObject<HTMLDivElement>;
  drawerHeaderRef: React.RefObject<HTMLDivElement>;
};

export const ProductList = forwardRef<HTMLDivElement, ProductListProps>((props, ref) => {
  const {isSearchInputFocused, sourceData, productCartRef, drawerHeaderRef, onSectionIntersect} = props;

  const [productListHeight, setProductListHeight] = useState('100%');

  const {
    productsOrder,
    incidentStoreParams,
    modifiedProductsOrder,
    productsForReplacement,
    handleProductCardClick,
    handleCounterChangedProductForReplacement,
  } = useUnit({
    productsOrder: $productsOrder,
    incidentStoreParams: $incidentStoreParams,
    modifiedProductsOrder: $modifiedProductsOrder,
    productsForReplacement: $productsForReplacement,
    handleProductCardClick: productCardClicked,
    handleCounterChangedProductForReplacement: counterChangedProductForReplacement,
  });

  useEffect(() => {
    const productCartHeight = productCartRef.current ? productCartRef.current.offsetHeight : 0;
    const drawerHeaderHeight = drawerHeaderRef.current ? drawerHeaderRef.current.offsetHeight : 0;
    const marginBetweenCartAndProducts = 20;

    setProductListHeight(`calc(100% - ${drawerHeaderHeight + marginBetweenCartAndProducts + productCartHeight}px)`);
  }, [isSearchInputFocused, productsForReplacement]);

  const productListView = useMemo(() => {
    if (!sourceData) return;

    return (
      <>
        {sourceData.sections.map((section) => {
          if (!section.products.length) return;

          return (
            <article data-id={section.id}
              key={section.id}
            >
              <IntersectionObserve
                onIntersect={onSectionIntersect}
                payload={String(section.id)}
                rootRef={ref}
                rootMargin="500px 0px 0px 0px"
              >
                <Typography className={styles.ProductTitle}
                  level="h4"
                  semibold
                >
                  {section.name}
                </Typography>
                <div className={styles.ProductSection}>
                  {section.products.map((product) => {
                    if (!incidentStoreParams) return;

                    let isAvailable = product.availability.isAvailable;
                    let currentProductCartCount;
                    const {storeId, branchId} = incidentStoreParams;

                    if (modifiedProductsOrder && productsOrder) {
                      const initialIncidentProduct = productsOrder.products.find((item) => item.id === product.id);

                      if (initialIncidentProduct) {
                        const disabledProducts = modifiedProductsOrder.filter(
                          (item) => item.quantity !== initialIncidentProduct.quantity,
                        );

                        isAvailable = isAvailable && !disabledProducts.some((item) => item.id === product.id);
                      }
                    }

                    if (productsForReplacement) {
                      const currentProductData = productsForReplacement.find((item) => item.id === product.id);

                      if (currentProductData) {
                        currentProductCartCount = currentProductData.count;
                      }
                    }

                    return (
                      <ProductCard
                        key={product.id}
                        id={product.id}
                        cover={product.cover}
                        title={product.name}
                        amount={product.amount.value}
                        inCartCount={currentProductCartCount}
                        price={product.price}
                        product={product}
                        isAvailable={isAvailable}
                        onCounterChange={handleCounterChangedProductForReplacement}
                        onCardClick={(productId) =>
                          handleProductCardClick({byButton: false, storeId, productId, branchId})
                        }
                        onButtonClick={(productId) =>
                          handleProductCardClick({byButton: true, storeId, productId, branchId})
                        }
                      />
                    );
                  })}
                </div>
              </IntersectionObserve>
            </article>
          );
        })}
      </>
    );
  }, [sourceData, productsForReplacement, ref, productsOrder]);

  return (
    <div className={styles.ProductList}
      style={{height: productListHeight}}
      ref={ref}
    >
      {productListView}
    </div>
  );
});

ProductList.displayName = 'ProductList';
