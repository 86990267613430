import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {Image, Ratio, Skeleton} from '@express-24/theseus-ui';
import {compileCdnUrl} from '@shared/lib/compile-cdn-url';
import styles from './styles.module.scss';
import noPhotoIcon from './assets/no-photo.sprite.svg';

type CoverProps = {
  coverUrl: string | string[];
  isAvailable?: boolean;
};

export const ImageCover: FC<CoverProps> = ({coverUrl, children}) => {
  const [isLoaded, setLoaded] = useState(false);
  const [isBroken, setBroken] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imageRef.current) return;

    if (imageRef.current.complete) {
      setLoaded(true);
    }

    return () => setLoaded(false);
  }, []);

  useEffect(() => {
    const url = Array.isArray(coverUrl) ? coverUrl[0] : coverUrl;
    const isPathBroken = !url;

    if (isPathBroken) {
      setBroken(true);
      setLoaded(true);
    }
  }, [coverUrl]);

  const imageView = useMemo(() => {
    const url = Array.isArray(coverUrl) ? coverUrl[0] : coverUrl;

    return (
      <Ratio ratio={[200, 200]}>
        <Image
          fluid
          src={compileCdnUrl(url, 300)}
          fit="cover"
          onError={() => setBroken(true)}
          onLoad={() => setLoaded(true)}
          ref={imageRef}
        />
      </Ratio>
    );
  }, [coverUrl]);

  return (
    <div className={styles.Cover}>
      <div className={styles.Image}
        data-is-loaded={isLoaded}
        data-is-broken={isBroken}
      >
        {isBroken ? (
          <Ratio ratio={[200, 200]}>
            <Image src={noPhotoIcon} />
          </Ratio>
        ) : (
          imageView
        )}
      </div>
      {!isLoaded && !isBroken && (
        <Ratio ratio={[200, 200]}>
          <div className={styles.Skeleton}>
            <Skeleton fluid />
          </div>
        </Ratio>
      )}
      {children}
    </div>
  );
};
