import dayjs from 'dayjs';
import {useUnit} from 'effector-react';
import {useMemo, useState} from 'react';
import styles from './styles.module.scss';
import * as model from '../../../.././model';
import {ProductDetailsContract} from '@api/v2';
import {compileCdnUrl} from '@shared/lib/compile-cdn-url';
import noCoverProduct from '../../../assests/no-cover-product.sprite.svg';
import {Image, Ratio, Toggle, Loader, Checkbox, Typography} from '@express-24/theseus-ui';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

type Props = {
  product: ProductDetailsContract;
};

export const StopListProduct = ({product}: Props) => {
  const {
    handleProductActivityChange,

    offersList,

    loadingProductsIds,
  } = useUnit({
    handleProductActivityChange: model.productSwitchToggleRequested,

    offersList: model.$offersListByCombinationIds,

    loadingProductsIds: model.$productLoadingStateByIds,
  });
  const [isBroken, setBroken] = useState(false);
  const {t} = useTranslation();

  const coverView = useMemo(() => {
    const url = Array.isArray(product.cover) ? product.cover[0] : product.cover;

    if (!product || isBroken) {
      return (
        <Ratio ratio={[48, 48]}>
          <Image fit="cover"
            src={noCoverProduct}
          />
        </Ratio>
      );
    }

    return (
      <Ratio ratio={[48, 48]}>
        <Image fit="cover"
          src={compileCdnUrl(url, 300)}
          onError={() => setBroken(true)}
        />
      </Ratio>
    );
  }, [product, isBroken]);

  const availabilityCaptionView = useMemo(() => {
    const {isActive, availability} = product;

    const date = dayjs(product.availability.unavailableTill).format('DD.MM.YY, hh:mm');

    if (!isActive) {
      return (
        <Typography align="left"
          kind="warning"
          level="sub-text"
          className={styles.ProductCaption}
        >
          {t('disabled')}
        </Typography>
      );
    }

    if (!availability.isAvailable && availability.unavailableTill) {
      return (
        <Typography align="left"
          level="sub-text"
          kind="secondary"
          className={styles.ProductCaption}
        >
          {t('off_date', {date})}
        </Typography>
      );
    }
  }, [product, i18n.language]);

  const handleProductEnable = ({id, checked}: {id: number; checked: boolean}) => {
    return handleProductActivityChange({
      productId: id,
      checked: checked,
    });
  };

  const combinationsView = useMemo(() => {
    if (!product.combinations) return null;

    const offersAvailabilityCaption = {};

    for (const combination of product.combinations) {
      let date = '';
      const availability = combination.branches[0].availability;

      if (availability.isAvailable) continue;

      if (availability.unavailableTill !== null) {
        date = dayjs(availability.unavailableTill).format('DD.MM.YY, hh:mm');

        offersAvailabilityCaption[combination.productId] = {
          caption: t('off_date', {date}),
          kind: 'secondary',
        };

        continue;
      }

      offersAvailabilityCaption[combination.productId] = {
        caption: t('disabled'),
        kind: 'warning',
      };
    }

    return (
      <div className={styles.OffersList}>
        {product.combinations.map(({productId, price, branches}) => (
          <div key={productId}
            className={styles.OfferContainer}
          >
            {loadingProductsIds[productId] ? (
              <div className={styles.SwitchLoader}>
                <Loader size={16}
                  strokeWidth={3}
                />
              </div>
            ) : (
              <Checkbox
                value=""
                checked={branches[0].availability.isAvailable}
                onChange={(event) =>
                  handleProductEnable({
                    id: productId,
                    checked: event.isChecked,
                  })
                }
              />
            )}
            <div className={styles.ContentWrapper}>
              <div className={styles.Content}>
                <div className={styles.Combinations}>
                  {offersList && (
                    <div key={productId}
                      className={styles.CombinationProperties}
                    >
                      <Typography semibold
                        level="text"
                      >
                        {offersList[productId].propertyName}:
                      </Typography>
                      <Typography level="text">{offersList[productId].optionName}</Typography>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.Footer}>
                <div className={styles.Price}>
                  <Typography semibold
                    level="text"
                  >
                    {`${t('price')}:`}
                  </Typography>
                  <Typography level="text">
                    {price.sold.toLocaleString()} {price.currency}
                  </Typography>
                </div>
                {offersAvailabilityCaption[productId] && (
                  <Typography align="right"
                    level="sub-text"
                    kind={offersAvailabilityCaption[productId].kind}
                  >
                    {offersAvailabilityCaption[productId].caption}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }, [product, loadingProductsIds]);

  return (
    <div className={styles.Product}>
      <div className={styles.ProductHeader}>
        {loadingProductsIds[product.id] ? (
          <div className={styles.SwitchLoader}>
            <Loader size={24}
              strokeWidth={3}
            />
          </div>
        ) : (
          <Toggle
            onChange={(event) =>
              handleProductEnable({
                id: product.id,
                checked: event.currentTarget.checked,
              })
            }
            checked={!product.isActive ? product.isActive : product.availability.isAvailable}
          />
        )}

        <div className={styles.ProductCover}>{coverView}</div>
        <div className={styles.ProductDetails}>
          <Typography level="text"
            lineClamp={1}
            className={styles.ContentTitle}
          >
            {product.name}
          </Typography>
          <Typography bold
            level="text"
          >
            {`${product.price.sold.toLocaleString()} ${product.price.currency}`}
          </Typography>
          <Typography align="right"
            level="sub-text"
            kind="secondary"
          >
            {availabilityCaptionView}
          </Typography>
        </div>
      </div>
      {combinationsView}
    </div>
  );
};
