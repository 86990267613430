import * as model from '../model';
import {useEffect, useState} from 'react';
import {useUnit} from 'effector-react';
import {ProductDialogContent} from './ui';
import {ProductCombination, ProductForReplacement} from '../model/model.types';

type DialogProps = {
  visible: boolean;
  onClose: () => void;
  onOutsideClick: () => void;
  onExited: () => void;
};

type DialogParams = {
  content: JSX.Element;
  isCoverHidden: boolean;
};

type ProductDialogProps = {
  render: (config: DialogParams & {props: DialogProps}) => JSX.Element;
  onAddClick: (productData: ProductForReplacement) => void;
  cart: ProductForReplacement[] | null;
  productCombinations: ProductCombination[] | null;
};

export const ProductDialog = ({render, cart, onAddClick, productCombinations}: ProductDialogProps) => {
  const [isCoverHidden, setIsCoverHidden] = useState(false);

  const {isDialogOpen, handleDialogClosed, handleDialogExited} = useUnit({
    isDialogOpen: model.$isDialogOpen,
    handleDialogClosed: model.dialogClosed,
    handleDialogExited: model.dialogExited,
  });

  useEffect(() => {
    if (isDialogOpen) return;

    setIsCoverHidden(false);
  }, [isDialogOpen]);

  const props = {
    visible: isDialogOpen,
    onClose: handleDialogClosed,
    onOutsideClick: handleDialogClosed,
    onExited: handleDialogExited,
  };

  const content = (
    <ProductDialogContent
      onImageError={() => setIsCoverHidden(true)}
      onAddClick={onAddClick}
      cart={cart}
      productCombinations={productCombinations}
    />
  );

  return render({props, content, isCoverHidden});
};
