import React from 'react';
import {Icon, Tag} from '@express-24/theseus-ui';
import i18n from 'i18next';

export const getStatusTag = (code: string, text: string) => {
  const color = code === 'finished' ? '#272728' : '#E73C50';

  return (
    <Tag color={color}
      textColor="#F3F3F4"
    >
      {text}
    </Tag>
  );
};

export const getOrderTypeTag = (orderType: boolean | number) => {
  const tag: {text: null | string} = {text: null};

  if (typeof orderType === 'boolean') {
    tag.text = orderType ? i18n.t('takeaway') : i18n.t('delivery');
  } else {
    tag.text = orderType === 1 ? i18n.t('delivery') : i18n.t('takeaway');
  }

  return (
    <Tag color="#3399FF"
      textColor="#F3F3F4"
    >
      {tag.text}
    </Tag>
  );
};

export const getPaymentTypeTag = (paymentId: number) => {
  if (paymentId === 1) {
    return (
      <Tag textColor="#16C647"
        icon={<Icon name="wallet" />}
        outlined
      >
        {i18n.t('cash')}
      </Tag>
    );
  }
  if (paymentId === 11) {
    return (
      <Tag textColor="#3399FF"
        icon={<Icon name="credit-card" />}
        outlined
      >
        {i18n.t('payme')}
      </Tag>
    );
  }
  return null;
};
