import {Button, List, Modal, Space, Tag, Typography} from '@express-24/theseus-ui';
import {CookingTimer} from '../../molecules/cooking-timer';
import i18n from 'i18next';
import {generateTags} from '../../../lib/generate-tags';
import {history} from '@shared/history';
import {useUnit} from 'effector-react';
import {
  $isAcceptModalOpened,
  $selectedOrder,
  $selectedOrderId,
  $selectedOrderStatuses,
  acceptModalClosed,
  acceptModalOpened,
  finishOrderFx,
  orderFinished,
  orderPrepared,
  updateOrderFx,
} from '../../../model';
import {useTranslation} from 'react-i18next';
import {Product} from '@shared/ui/organisms/order-product';
import {AcceptForm} from '../order-accept-form';
import {useMemo} from 'react';
import {EmptyState} from '@widgets/empty-state';
import styles from './styles.module.scss';

export const OrderDetails = () => {
  const {
    selectedOrder,
    selectedOrderId,
    selectedOrderStatuses,
    handleOrderPrepared,
    isFoodReadyLoading,
    isOrderFinishing,
    handleOrderFinish,
    handleAcceptModalOpen,
    isAcceptModalOpen,
    handleAcceptModalClose,
  } = useUnit({
    selectedOrder: $selectedOrder,
    selectedOrderId: $selectedOrderId,
    selectedOrderStatuses: $selectedOrderStatuses,
    handleOrderPrepared: orderPrepared,
    isFoodReadyLoading: updateOrderFx.pending,
    isOrderFinishing: finishOrderFx.pending,
    handleOrderFinish: orderFinished,
    handleAcceptModalOpen: acceptModalOpened,
    isAcceptModalOpen: $isAcceptModalOpened,
    handleAcceptModalClose: acceptModalClosed,
  });
  const {t} = useTranslation();

  const noPickedOrderView = useMemo(() => {
    if (selectedOrderId) return;

    return (
      <Space padding={[200, 0, 0, 0]}>
        <EmptyState
          illustration="noOrders"
          title={t('orders.order_not_selected')}
          description={t('orders.choose_order')}
        />
      </Space>
    );
  }, [selectedOrderId, i18n.language]);

  if (!selectedOrder) return null;

  const {isPreparingTakeaway, isPreparing, isTakeawayPicked, notAcceptedPreorder} = selectedOrderStatuses;
  const tags = generateTags(selectedOrder, isPreparingTakeaway || isPreparing);

  const offerReplacementBtn = (
    <Button fluid
      kind="secondary"
      size="large"
      onClick={() => history.push(`/orders/incident/${selectedOrder.id}`)}
    >
      {t('orders.offer_replacement')}
    </Button>
  );

  return (
    <div className={styles.OrderDetails}>
      <div className={styles.DetailsContainer}>
        <div className={styles.StateDetails}>
          <div className={styles.StateHeader}>
            <div className={styles.Labels}>
              {tags.map((tag) => (
                <Tag
                  size="small"
                  key={tag.name}
                  textColor={tag.textColor}
                  color={tag.color}
                  outlined={tag.outlined}
                  icon={tag.icon}
                >
                  {tag.name}
                </Tag>
              ))}
            </div>
            {isPreparingTakeaway ||
              (isPreparing && (
                <>{selectedOrder.prepareTime.iso && <CookingTimer deadline={selectedOrder.prepareTime.iso} />}</>
              ))}
          </div>
          <div className={styles.OrderInfo}>
            <Typography level="text">
              {t('branch')}:<span className={styles.OrderValues}>&nbsp;{selectedOrder.store.branch.name}</span>
            </Typography>
            <Typography level="text">
              {t('orders.order_created')}:
              <span className={styles.OrderValues}>&nbsp;{selectedOrder.date.createdAt}</span>
            </Typography>
            {selectedOrder.date.acceptedAt && (
              <Typography level="text">
                {t('order_accepted')}:<span className={styles.OrderValues}>&nbsp;{selectedOrder.date.acceptedAt}</span>
              </Typography>
            )}

            {i18n.language === 'uz' ? (
              <>
                {selectedOrder.prepareTime.iso && !selectedOrder.isReady && (
                  <Typography level="text">
                    <span className={styles.OrderValues}>{selectedOrder.prepareTime.iso}</span> {t('orders.prepare_to')}
                  </Typography>
                )}
                {selectedOrder.date.preorderTo && selectedOrder.status.code === 'preOrder' && (
                  <Typography level="text">
                    <span className={styles.OrderValues}>{selectedOrder.date.preorderTo}</span>{' '}
                    {t('orders.pre_order_to')}
                  </Typography>
                )}
              </>
            ) : (
              <>
                {selectedOrder.prepareTime.iso && !selectedOrder.isReady && (
                  <Typography level="text">
                    {t('orders.prepare_to')}:
                    <span className={styles.OrderValues}>&nbsp;{selectedOrder.prepareTime.iso}</span>
                  </Typography>
                )}
                {selectedOrder.date.preorderTo && selectedOrder.status.code === 'preOrder' && (
                  <Typography level="text">
                    {t('orders.pre_order_to')}:
                    <span className={styles.OrderValues}>&nbsp;{selectedOrder.date.preorderTo}</span>
                  </Typography>
                )}
              </>
            )}
          </div>
          <div className={styles.OrderPicker}>
            {selectedOrder.isTakeaway && (
              <List.Cell title={t('orders.client')}
                caption={selectedOrder.client.name}
              />
            )}
            {!selectedOrder.isTakeaway && selectedOrder.courier && (
              <List.Cell
                title={t('orders.courier')}
                caption={selectedOrder.courier.name}
                postfix={{
                  title: selectedOrder.courier.phone,
                  chevron: false,
                  caption: selectedOrder.courier.type && selectedOrder.courier.type.name,
                }}
              />
            )}
          </div>
          <div className={styles.OrderComment}>
            <Space padding={[16, 24]}>
              <Typography level="text"
                semibold
              >
                {t('orders.order_comment')}
              </Typography>
              <Typography level="text">{selectedOrder.comment}</Typography>
            </Space>
          </div>
        </div>
        <div className={styles.DetailsControl}>
          <div className={styles.ProductList}>
            <Space padding={[24, 0, 0, 24]}>
              <Typography level="h4"
                semibold
              >
                {t('orders.order_composition')}
              </Typography>
            </Space>
            {selectedOrder.products.map((product) => (
              <Product
                key={product.id}
                currency={selectedOrder.payment.currency}
                props={product.props}
                name={product.name}
                cover={product.cover}
                id={product.id}
                quantity={product.quantity}
                price={product.price}
              />
            ))}
          </div>

          <div className={styles.OrderControl}>
            <Space padding={24}>
              {!selectedOrder.isTakeaway && (
                <div className={styles.Compensation}>
                  <Typography level="text"
                    kind="secondary"
                  >
                    {t('order_compensation')}
                  </Typography>
                  <Typography level="text"
                    kind="secondary"
                  >
                    {Number(selectedOrder.payment.compensation.courier)} {selectedOrder.payment.currency}
                  </Typography>
                </div>
              )}
              <div className={styles.Total}>
                <Typography level="h5">{t('orders.total_amount')}</Typography>
                <Typography level="h5">
                  {selectedOrder.payment.receipt.total.toLocaleString()} {selectedOrder.payment.currency}
                </Typography>
              </div>

              <div className={styles.Controllers}>
                {selectedOrder.status.code === 'new' && !isPreparing && (
                  <>
                    <Button kind="primary"
                      size="large"
                      fluid
                      onClick={handleAcceptModalOpen}
                    >
                      {t('orders.accept_order')}
                    </Button>
                    <Space padding={8} />
                    {offerReplacementBtn}
                  </>
                )}

                {notAcceptedPreorder && offerReplacementBtn}

                {isPreparingTakeaway || isPreparing ? (
                  <Button kind="primary"
                    size="large"
                    fluid
                    loading={isFoodReadyLoading}
                    onClick={handleOrderPrepared}
                  >
                    {t('orders.order_ready')}
                  </Button>
                ) : null}

                {isTakeawayPicked && (
                  <Button kind="primary"
                    size="large"
                    fluid
                    loading={isOrderFinishing}
                    onClick={handleOrderFinish}
                  >
                    {t('orders.complete_order')}
                  </Button>
                )}
              </div>
            </Space>
          </div>
        </div>
      </div>

      {noPickedOrderView}
      <Modal visible={isAcceptModalOpen}
        onClose={handleAcceptModalClose}
      >
        <AcceptForm />
      </Modal>
    </div>
  );
};
