import React, {useMemo, useState} from 'react';
import styles from './styles.module.scss';
import {Icon, Row, Skeleton, Space, Tag, Typography} from '@express-24/theseus-ui';
import {getOrderTypeTag, getPaymentTypeTag, getStatusTag} from '@shared/lib/generate-tags';
import {OrderDetailsComponentProps} from '@widgets/order-details/types';
import {Product} from 'shared/ui/organisms/order-product';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const OrderDetails = ({orderDetails, handleOrderDetailsClose, isLoading}: OrderDetailsComponentProps) => {
  const [isFiscalReceiptCopied, setFiscalReceiptCopied] = useState(false);
  const {t} = useTranslation();

  const loadingView = useMemo(() => {
    if (!isLoading) return;

    return (
      <div className={styles.OrderDetailsSkeleton}>
        <Skeleton width={150}
          height={25}
          radius={10}
        />
        <Skeleton width={424}
          height={170}
          radius={10}
        />
        <Skeleton width={424}
          height={100}
          radius={10}
        />
        <Skeleton width={150}
          height={30}
          radius={10}
        />
        <Skeleton width={424}
          height={60}
          radius={10}
        />
        <Skeleton width={424}
          height={50}
          radius={10}
        />
      </div>
    );
  }, [isLoading]);

  const handleFiscalReceiptCopy = (id: number) => {
    setFiscalReceiptCopied(true);

    setTimeout(() => {
      setFiscalReceiptCopied(false);
    }, 3000);

    navigator.clipboard.writeText(id.toString());
  };

  const fiscalCopyButtonView = useMemo(() => {
    const iconName = isFiscalReceiptCopied ? 'checkmark' : 'copy';

    return (
      <Tag size="small"
        shape="square"
        kind="secondary"
        icon={<Icon name={iconName} />}
      />
    );
  }, [isFiscalReceiptCopied]);

  const fiscalReceiptView = useMemo(() => {
    if (!orderDetails) return;
    if (!orderDetails.payment.receipt.fiscalization) return;

    const {id} = orderDetails.payment.receipt.fiscalization;

    if (!id) {
      return (
        <div className={styles.FiscalReceiptField}>
          {t('orders.fiscal_check')}: <span className={styles.UnavailableFiscalReceipt}>Недоступен</span>
        </div>
      );
    }

    return (
      <div className={styles.FiscalReceiptField}>
        <div>
          {t('orders.fiscal_check')}: <span>#{id}</span>
        </div>
        <div onClick={() => handleFiscalReceiptCopy(id)}>{fiscalCopyButtonView}</div>
      </div>
    );
  }, [orderDetails, isFiscalReceiptCopied, i18n.language]);

  const tagsView = useMemo(() => {
    if (!orderDetails) return;

    const statusTag = getStatusTag(orderDetails.status.code, orderDetails.status.text);
    const paymentTypeTag = getPaymentTypeTag(orderDetails.payment.type.id);
    const orderType = getOrderTypeTag(orderDetails.isTakeaway);

    return (
      <Space padding={[24, 0, 0, 0]}>
        <Row>
          {statusTag}
          <Space padding={[0, 8, 0, 0]} />
          {paymentTypeTag}
          <Space padding={[0, 8, 0, 0]} />
          {orderType}
        </Row>
      </Space>
    );
  }, [orderDetails]);

  return (
    <>
      {loadingView}
      {orderDetails && (
        <div className={styles.Container}>
          <Space padding={24}>
            <Row justify="space-between">
              <Typography level="h3"
                semibold
              >
                #{orderDetails.id}
              </Typography>
              <div className={styles.CloseButton}
                onClick={handleOrderDetailsClose}
              >
                <Icon name="cross"
                  size={20}
                />
              </div>
            </Row>
            {tagsView}
            <div className={styles.HeaderInfo}>
              <Space padding={[16, 0, 16, 0]}>
                {t('branch')}: <span>{orderDetails.store.branch.name}</span>
              </Space>
              <Space padding={[0, 0, 16, 0]}>
                {t('orders.order_created')}: <span>{orderDetails.date.createdAt}</span>
              </Space>
              <Space padding={[0, 0, 16, 0]}>
                {t('order_accepted')}: <span>{orderDetails.date.acceptedAt}</span>
              </Space>
              {t('orders.specified_prepare_time')}:{' '}
              <span>
                {orderDetails.prepareTime.minutes} {t('min')}
              </span>
              {fiscalReceiptView}
            </div>
          </Space>

          <div className={styles.Comment}>
            <div className={styles.CommentTitle}>{t('orders.order_comment')}</div>
            {orderDetails.comment}
          </div>

          <Space padding={24}>
            <Typography level="h3"
              semibold
            >
              {t('orders.order_composition')}
            </Typography>
          </Space>
          {orderDetails.products.map((product) => (
            <Product
              id={product.id}
              name={product.name}
              cover={product.cover}
              quantity={product.quantity}
              price={product.price}
              currency={orderDetails.payment.currency}
              key={product.id}
              props={product.props}
            />
          ))}

          <Space padding={24}>
            <Row justify="space-between">
              <Typography level="sub-text"
                kind="secondary"
              >
                {t('order_compensation')}
              </Typography>
              <Typography level="sub-text"
                kind="secondary"
              >
                {orderDetails.payment.compensation.courier}&nbsp;{orderDetails.payment.currency}
              </Typography>
            </Row>
            <Space padding={[8, 0, 0, 0]} />
            <Row justify="space-between">
              <Typography level="h5"
                semibold
              >
                {t('orders.total_amount')}
              </Typography>
              <Typography level="h5"
                semibold
              >
                {orderDetails.payment.receipt.total.toLocaleString()} {orderDetails.payment.currency}
              </Typography>
            </Row>
          </Space>
        </div>
      )}
    </>
  );
};
