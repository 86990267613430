import {WSS_URL} from '@shared/api/wss';
import {userRefined} from '@entities/user';
import {CentrifugeInstance, UpdatedOrder} from './model.types';
import {createEffect, createEvent, guard, sample, scopeBind} from 'effector';
import {Centrifuge} from 'centrifuge';
import {$wsToken} from '@entities/session';
import {$stores, attachedStoresChanged, Stores} from '@entities/attached-stores';

const ordersCentrifugeInstance: CentrifugeInstance = {};

export const orderUpdated = createEvent<UpdatedOrder>();

const createWsInstanceFx = createEffect((wsToken: string) => {
  ordersCentrifugeInstance.instance = new Centrifuge(WSS_URL, {
    token: wsToken,
  });
});

const subscribeOrdersSocketFx = createEffect((storesIds: number[]) => {
  for (const storeId of storesIds) {
    if (!ordersCentrifugeInstance.instance) return;

    const orderSub = ordersCentrifugeInstance.instance.newSubscription(`$merchant:store_orders_${storeId}`);
    orderSub.on('publication', scopeBind(orderUpdated));
    orderSub.subscribe();
  }
});

const connectOrdersSocketFx = createEffect(() => {
  if (ordersCentrifugeInstance.instance) ordersCentrifugeInstance.instance.connect();
});

const disconnectOrdersSocketFx = createEffect(() => {
  if (ordersCentrifugeInstance.instance) ordersCentrifugeInstance.instance.disconnect();
});

sample({
  source: guard({
    clock: attachedStoresChanged,
    source: $wsToken,
    filter: (wsToken): wsToken is string => {
      if (ordersCentrifugeInstance.instance) return false;
      return wsToken !== null;
    },
  }),
  fn: (wsToken) => wsToken,
  target: createWsInstanceFx,
});

sample({
  clock: guard({
    clock: createWsInstanceFx.done,
    source: $stores,
    filter: (stores): stores is Stores => stores !== null,
  }),
  fn: (stores) => {
    return stores.map((store) => store.id);
  },
  target: subscribeOrdersSocketFx,
});

sample({
  clock: subscribeOrdersSocketFx.done,
  target: connectOrdersSocketFx,
});

sample({
  clock: userRefined,
  target: disconnectOrdersSocketFx,
});
