import {forwardRef} from 'react';
import * as model from '../../../../model';
import {useUnit} from 'effector-react';
import {Radio, Row, Space, Typography} from '@express-24/theseus-ui';

type ProductOptionPickerProps = {
  showRequiredOption?: boolean;
};

export const ProductOptionPicker = forwardRef<HTMLDivElement, ProductOptionPickerProps>(
  ({showRequiredOption}, externalRef) => {
    const {hasOptions, properties, selectedOptionByPropertyIdMap, handleOptionSelect} = useUnit({
      hasOptions: model.$hasOptions,
      properties: model.$properties,
      selectedOptionByPropertyIdMap: model.$selectedOptionByPropertyId,
      handleOptionSelect: model.optionSelected,
    });

    const handleOptionClick = (propertyId: number, optionId: number) => {
      handleOptionSelect({
        propertyId,
        optionId,
      });
    };

    if (!hasOptions) return null;

    return (
      <div ref={externalRef}>
        {properties.map((property) => {
          return (
            <div key={property.id}
              data-property-id={property.id}
            >
              <Space padding={[4, 16]}>
                <Row justify="space-between"
                  wrap="nowrap"
                  align="end"
                >
                  <Typography level="sub-text"
                    kind="secondary"
                  >
                    {property.name}
                  </Typography>
                </Row>
              </Space>
              <Radio.Group
                onChange={(optionId) => handleOptionClick(property.id, Number(optionId))}
                selected={String(selectedOptionByPropertyIdMap[property.id])}
              >
                {property.options.map((option) => (
                  <Radio key={option.id}
                    value={String(option.id)}
                  >
                    {option.name}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          );
        })}
      </div>
    );
  },
);

ProductOptionPicker.displayName = 'ProductOptionPicker';
