import * as model from '../model';
import {useUnit} from 'effector-react';
import {history} from '@shared/history';
import styles from './page.module.scss';
import {EmptyState} from '@widgets/empty-state';
import {Link, useLocation} from 'react-router-dom';
import {Fragment, useEffect, useMemo} from 'react';
import {$allBranchesOptions} from '@entities/attached-stores';
import {Icon, Input, Select, Typography} from '@express-24/theseus-ui';
import {MenuList, ProductCard, ProductsList, StopList, StopListCard, SubmenuList} from './ui';
import noProducts from '@widgets/empty-state/assets/no-products.svg';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const Menu = () => {
  const {
    handlePageMount,
    handleMenuSelect,
    handlePageUnmount,
    handleBranchChange,
    handleSearchChange,
    handleSubmenuSelect,

    branches,
    foundProducts,
    selectedMenuId,
    selectedStoreId,
    selectedBranchId,

    isSearchActive,
    isStopListOpen,
  } = useUnit({
    handlePageMount: model.pageMounted,
    handleMenuSelect: model.menuSelected,
    handlePageUnmount: model.pageUnmounted,
    handleBranchChange: model.branchSelected,
    handleSubmenuSelect: model.submenuSelected,
    handleSearchChange: model.searchInputChanged,

    menu: model.$menu,
    branches: $allBranchesOptions,
    foundProducts: model.$foundProducts,
    selectedMenuId: model.$selectedMenuId,
    selectedStoreId: model.$selectedStoreId,
    selectedBranchId: model.$selectedBranchId,

    isSearchActive: model.$isSearchActive,
    isStopListOpen: model.$isStopListOpen,
  });
  const location = useLocation();
  const {t} = useTranslation();

  useEffect(() => {
    handlePageMount();

    return () => handlePageUnmount();
  }, []);

  useEffect(() => {
    const queries = new URLSearchParams(location.search);

    const menuId = queries.get('menuId');
    const submenuId = queries.get('submenuId');

    if (Number(menuId) !== selectedMenuId) handleMenuSelect(Number(menuId));
    if (submenuId) handleSubmenuSelect(Number(submenuId));
  }, [location]);

  const searchView = useMemo(() => {
    if (!foundProducts) return null;

    if (!foundProducts.length) {
      return (
        <div className={styles.SearchEmptyView}>
          <EmptyState illustration="noProducts"
            description={t('menu.products_not_found')}
          />
        </div>
      );
    }

    return foundProducts.map(({id, name, products}) => (
      <div key={id}>
        <div className={styles.SearchHeader}>
          <Typography level="text"
            kind="secondary"
          >
            {name}
          </Typography>
        </div>
        {products.map((product) => (
          <ProductCard kind="search"
            key={product.id}
            product={product}
          />
        ))}
      </div>
    ));
  }, [foundProducts, selectedStoreId, selectedMenuId, i18n.language]);

  const handleBranchSelect = (branchId: number) => {
    handleBranchChange(branchId);

    history.replace({
      pathname: '/menu',
      search: `branchId=${branchId}`,
    });
  };

  const branchSelectView = useMemo(() => {
    if (!branches) return null;

    return (
      <Select
        fluid
        mode="single"
        options={branches}
        label={t('choose_branch')}
        selected={selectedBranchId}
        onSelect={handleBranchSelect}
        icon={(
          <Icon name="map-marker"
            size={20}
          />
        )}
        renderOptionsContainer={(optionsView) => <div className={styles.BranchesOptions}>{optionsView}</div>}
      />
    );
  }, [branches, selectedBranchId, i18n.language]);

  const siderContentView = useMemo(() => {
    if (isSearchActive) return searchView;

    return (
      <Fragment>
        <StopListCard />

        <MenuList />
      </Fragment>
    );
  }, [searchView, isSearchActive]);

  const mainContentView = useMemo(() => {
    if (isStopListOpen) return <StopList />;

    return (
      <Fragment>
        <SubmenuList />
        <ProductsList />
      </Fragment>
    );
  }, [isStopListOpen]);

  return (
    <div className={styles.Container}>
      <div className={styles.Sider}>
        <div className={styles.SiderHeader}>
          <Typography level="h1">{t('sections.menu')}</Typography>
          <div className={styles.SiderFilters}>
            {branchSelectView}

            <Input
              fluid
              allowClear
              prefix={(
                <Icon size={20}
                  name="search"
                />
              )}
              onChange={handleSearchChange}
              placeholder={t('menu.search_by_branch')}
            />
          </div>
        </div>
        <div className={styles.SiderContent}>{siderContentView}</div>
      </div>

      {mainContentView}
    </div>
  );
};
