import {Button, Icon, Input, Space, Typography} from '@express-24/theseus-ui';
import styles from './styles.module.scss';
import {useUnit} from 'effector-react';
import {
  $prepareTime,
  $suggestedCookingTimes,
  acceptOrderFx,
  prepareTimeChanged,
  prepareTimeSubmitted,
} from '../../../model';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export const AcceptForm = () => {
  const {
    handlePrepareTimeChange,
    handlePrepareTimeSubmit,

    prepareTime,
    suggestedCookingTimes,

    isAccepting,
  } = useUnit({
    handlePrepareTimeChange: prepareTimeChanged,
    handlePrepareTimeSubmit: prepareTimeSubmitted,

    prepareTime: $prepareTime,
    suggestedCookingTimes: $suggestedCookingTimes,

    isAccepting: acceptOrderFx.pending,
  });
  const {t} = useTranslation();

  const suggestedTimeIconView = useMemo(() => {
    return (
      <div className={styles.SuggestedTimeIcon}>
        <Icon name="lightning" />
      </div>
    );
  }, []);

  return (
    <Space padding={24}>
      <div className={styles.AcceptForm}>
        <Typography level="h4"
          semibold
        >
          {t('orders.prepare_time')}
        </Typography>
        <Input
          fluid
          onChange={(event) => handlePrepareTimeChange(Number(event.target.value))}
          label={t('orders.set_manually')}
          type="number"
          message={t('orders.max_min', {minutes: 180})}
          value={String(prepareTime)}
          max={180}
          min={1}
        />
        <div className={styles.AcceptMinutes}>
          {suggestedCookingTimes.map(({label, value, isOptimal}) => (
            <div key={value}
              className={styles.TimeButton}
            >
              <Button
                fluid
                size="medium"
                kind="secondary"
                onClick={() => handlePrepareTimeChange(value)}
                icon={isOptimal ? suggestedTimeIconView : undefined}
              >
                {label}
              </Button>
            </div>
          ))}
        </div>
        <Button fluid
          kind="primary"
          size="large"
          loading={isAccepting}
          onClick={handlePrepareTimeSubmit}
        >
          {t('actions.confirm')}
        </Button>
      </div>
    </Space>
  );
};
