import {useMemo} from 'react';
import {useUnit} from 'effector-react';
import styles from './styles.module.scss';
import {getLastDates} from '@shared/lib/dayjs';
import {$storesOptions} from '@entities/attached-stores';
import {Button, Icon, Select, Skeleton, Typography} from '@express-24/theseus-ui';
import {
  periodChanged,
  filterChanged,
  storeIdChanged,
  filtersSubmitted,
  $filter,
  $selectedPeriod,
  $selectedStoreId,
  $filteredBranches,
  $changesHistoryTypes,
  $changesHistoryActions,
  $isFiltersReady,
  fetchChangesHistoryFx,
} from '../../../../model';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const Filter = () => {
  const {
    handleFilterChange,
    handlePeriodChange,
    handleStoreIdChange,
    handleFiltersSubmit,

    filter,
    changesTypes,
    storesOptions,
    changesActions,
    selectedPeriod,
    selectedStoreId,
    filteredBranches,

    isFiltersReady,
    isHistoryChangesLoading,
  } = useUnit({
    handleFilterChange: filterChanged,
    handlePeriodChange: periodChanged,
    handleStoreIdChange: storeIdChanged,
    handleFiltersSubmit: filtersSubmitted,

    filter: $filter,
    storesOptions: $storesOptions,
    selectedPeriod: $selectedPeriod,
    selectedStoreId: $selectedStoreId,
    changesTypes: $changesHistoryTypes,
    filteredBranches: $filteredBranches,
    changesActions: $changesHistoryActions,

    isFiltersReady: $isFiltersReady,
    isHistoryChangesLoading: fetchChangesHistoryFx.pending,
  });
  const {t} = useTranslation();

  const DATE_OPTIONS = [
    {
      value: getLastDates(3),
      label: t('last_3days'),
    },
    {
      value: getLastDates(7),
      label: t('last_days', {days: 7}),
    },
    {
      value: getLastDates(14),
      label: t('last_days', {days: 14}),
    },
  ];

  const storeSelectView = useMemo(() => {
    if (!storesOptions) return null;

    return (
      <div className={styles.Filter}>
        <Typography level="text"
          kind="secondary"
        >
          {t('store')}
        </Typography>
        <Select
          mode="single"
          label={t('store')}
          options={storesOptions}
          selected={selectedStoreId}
          onSelect={handleStoreIdChange}
          renderOptionsContainer={(options) => <div className={styles.Options}>{options}</div>}
        />
      </div>
    );
  }, [selectedStoreId, storesOptions, i18n.language]);

  const emptyFilterView = useMemo(() => {
    if (isFiltersReady) return null;

    return Array.from({length: 5}, (_, index) => (
      <div key={index}
        className={styles.Filter}
      >
        <Skeleton width={60}
          height={8}
          radius={20}
        />
        <Skeleton width={140}
          height={47}
          radius={20}
        />
      </div>
    ));
  }, [isFiltersReady]);

  const filterView = useMemo(() => {
    if (!isFiltersReady) return null;

    return (
      <>
        {storeSelectView}
        <div className={styles.Filter}>
          <Typography level="text"
            kind="secondary"
          >
            {t('branch')}
          </Typography>
          <Select
            mode="multiple"
            label={t('all_branches')}
            options={filteredBranches}
            selected={filter && filter.branchesIds}
            onSelect={(branchesIds) => handleFilterChange({branchesIds})}
            renderOptionsContainer={(options) => <div className={styles.Options}>{options}</div>}
          />
        </div>
        <div className={styles.Filter}>
          <Typography level="text"
            kind="secondary"
          >
            {t('change_type')}
          </Typography>
          <Select
            mode="multiple"
            label={t('all_changes')}
            options={changesTypes.map((type) => ({...type, label: t(type.label)}))}
            selected={filter && filter.changesTypes}
            onSelect={(changesTypes) => handleFilterChange({changesTypes})}
            renderOptionsContainer={(options) => <div className={styles.Options}>{options}</div>}
          />
        </div>
        <div className={styles.Filter}>
          <Typography level="text"
            kind="secondary"
          >
            {t('action_type')}
          </Typography>
          <Select
            mode="multiple"
            label={t('all_actions')}
            options={changesActions.map((action) => ({...action, label: t(action.label)}))}
            selected={filter && filter.changesActions}
            onSelect={(changesActions) => handleFilterChange({changesActions})}
            renderOptionsContainer={(options) => <div className={styles.Options}>{options}</div>}
          />
        </div>
        <div className={styles.Filter}>
          <Typography level="text"
            kind="secondary"
          >
            {t('time')}
          </Typography>
          <Select
            mode="single"
            label={t('time')}
            options={DATE_OPTIONS}
            selected={selectedPeriod}
            onSelect={handlePeriodChange}
            renderOptionsContainer={(options) => <div className={styles.Options}>{options}</div>}
          />
        </div>
        <Button
          size="large"
          shape="square"
          icon={<Icon name="search" />}
          loading={isHistoryChangesLoading}
          disabled={isHistoryChangesLoading}
          onClick={handleFiltersSubmit}
        />
      </>
    );
  }, [selectedPeriod, selectedStoreId, storeSelectView, filter, isFiltersReady, isHistoryChangesLoading]);

  return (
    <div className={styles.Container}>
      {emptyFilterView}
      {filterView}
    </div>
  );
};
