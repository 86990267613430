import {FC, useMemo} from 'react';
import styles from './page.module.scss';
import {SegmentedControl, Typography, Select, Icon, Space, Button, Input} from '@express-24/theseus-ui';
import {useGate, useUnit} from 'effector-react';
import {
  $attachedBranches,
  $filteredOrderGroups,
  $isOrdersListView,
  $orders,
  $pickedBranches,
  $selectedGroup,
  $selectedOrderId,
  $socketUpdates,
  branchesChanged,
  groupSelected,
  orderSelected,
  ordersGate,
  ordersViewOpened,
  searchViewOpened,
} from '../../model';
import {OrderCard} from '../../ui/organisms/order-card';
import {EmptyState} from 'widgets/empty-state';
import {$incidentOrder} from '../incidents/model';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import {OrderDetails} from '../../ui/organisms/order-details';

export const Orders: FC = () => {
  const {
    orderGroups,
    handleGroupSelect,
    selectedGroup,
    attachedBranches,
    handleChangeBranches,
    pickedBranches,
    orders,
    isOrdersListView,
    selectedOrderId,
    handleOrderSelect,
    handleOpenOrders,
    socketUpdates,
  } = useUnit({
    handleChangeBranches: branchesChanged,
    selectedOrderId: $selectedOrderId,
    incidentOrder: $incidentOrder,
    handleOrderSelect: orderSelected,
    isOrdersListView: $isOrdersListView,
    handleOpenSearch: searchViewOpened,
    handleOpenOrders: ordersViewOpened,
    orders: $orders,
    pickedBranches: $pickedBranches,
    selectedGroup: $selectedGroup,
    attachedBranches: $attachedBranches,
    handleGroupSelect: groupSelected,
    orderGroups: $filteredOrderGroups,
    socketUpdates: $socketUpdates,
  });
  const {t} = useTranslation();
  useGate(ordersGate);

  const orderGroupsView = useMemo(() => {
    if (!orderGroups) return;
    if (!selectedGroup) return;
    if (!isOrdersListView) return;
    if (!socketUpdates) return;

    return (
      <SegmentedControl
        selected={selectedGroup.alias}
        options={orderGroups}
        onChange={handleGroupSelect}
        renderOption={(option) => {
          const isUpdated = socketUpdates[option.value];
          return (
            <>
              {option.label}&nbsp;
              {isUpdated && <span className={styles.Indicator} />}
            </>
          );
        }}
      />
    );
  }, [orderGroups, selectedGroup, isOrdersListView, socketUpdates]);

  const branchPickView = useMemo(() => {
    if (!attachedBranches) return;
    if (!attachedBranches.length) return;
    if (!isOrdersListView) return;

    return (
      <div className={styles.Filters}>
        <Select
          mode="multiple"
          options={attachedBranches}
          selected={pickedBranches}
          fluid
          label={t('choose_branch')}
          icon={(
            <Icon name="map-marker"
              size={20}
            />
          )}
          renderOptionsContainer={(optionsView) => <div className={styles.OptionsContainer}>{optionsView}</div>}
          onSelect={handleChangeBranches}
        />
      </div>
    );
  }, [attachedBranches, pickedBranches, isOrdersListView, i18n.language]);

  const searchView = useMemo(() => {
    if (isOrdersListView) return;

    return (
      <>
        <div className={styles.OrdersNavigationControl}
          onClick={handleOpenOrders}
        >
          <Icon name="chevron-left"
            size={24}
          />
          <Typography level="text"
            kind="secondary"
          >
            {t('orders.to_orders_list')}
          </Typography>
        </div>
        <div className={styles.SearchControl}>
          <Input
            onChange={console.log}
            type="number"
            prefix={(
              <Icon strokeWidth={2}
                name="search"
                size={16}
              />
            )}
            placeholder={t('orders.enter_order_number')}
            fluid
          />
          <Button kind="primary"
            size="large"
          >
            {t('actions.find')}
          </Button>
        </div>
      </>
    );
  }, [isOrdersListView, i18n.language]);

  const ordersView = useMemo(() => {
    if (!orders) return;
    if (!isOrdersListView) return;
    if (!selectedGroup) return;

    const isPreparing = selectedGroup.alias === 'preparing';

    return (
      <>
        {orders.groups.map((groupOrders) => {
          if (groupOrders.orders.length < 1) return;

          return (
            <div key={groupOrders.title}>
              <Space padding={12} />
              <Typography level="h4"
                semibold
              >
                {groupOrders.title}
              </Typography>
              <Space padding={8} />
              {groupOrders.orders.map((order) => (
                <Space padding={[8, 0]}
                  key={order.id}
                >
                  <OrderCard
                    key={order.id}
                    order={order}
                    isPreparing={isPreparing}
                    isSelected={order.id === selectedOrderId}
                    onClick={handleOrderSelect}
                  />
                </Space>
              ))}
            </div>
          );
        })}
      </>
    );
  }, [orders, isOrdersListView, selectedOrderId, selectedGroup]);

  const noOrdersView = useMemo(() => {
    if (!orders) return;
    if (orders.count > 0) return;
    if (!attachedBranches) return;
    if (!pickedBranches) return;
    if (!pickedBranches.length) return;

    return (
      <Space padding={[100, 0, 0, 0]}>
        <EmptyState illustration="noOrders"
          description={t('orders.not_active_orders')}
        />
      </Space>
    );
  }, [orders, pickedBranches, i18n.language]);

  const noBranchView = useMemo(() => {
    if (!attachedBranches) return;
    if (pickedBranches.length > 0) return;

    return (
      <Space padding={[100, 0, 0, 0]}>
        <EmptyState illustration="noBranches"
          description={t('orders.choose_branch')}
        />
      </Space>
    );
  }, [pickedBranches, attachedBranches, i18n.language]);

  const noActiveBranchesView = useMemo(() => {
    if (attachedBranches) return;

    return (
      <Space padding={[100, 0, 0, 0]}>
        <EmptyState
          illustration="noBranches"
          title={t('orders.no_active_branches')}
          description={t('orders.no_active_branches_tip')}
        />
      </Space>
    );
  }, [attachedBranches, i18n.language]);

  return (
    <div className={styles.OrdersPage}>
      <div className={styles.OrdersList}>
        {orderGroupsView}
        <Space padding={8} />
        {branchPickView}
        {ordersView}
        {searchView}
        {noOrdersView}
        {noBranchView}
        {noActiveBranchesView}
      </div>

      <OrderDetails />
    </div>
  );
};
