import {AxiosPromise as R} from 'axios';
import {http} from '../../http';
import {
  AcceptOrderConfig,
  AccountConfig,
  AccountContract,
  AuthorizationConfig,
  AuthorizationContract,
  CreateUserConfig,
  Employee,
  EmployeesContract,
  BranchActivityConfig,
  BranchActivityContract,
  BranchDetailsConfig,
  BranchDetailsContract,
  FinishOrderConfig,
  MenuContract,
  MenuUpdateConfig,
  OrderConfig,
  OrderContract,
  OrderGroup,
  OrdersConfig,
  OrdersContract,
  ProductDeleteConfig,
  ProductsSortConfig,
  ProductDetailsUpdateConfig,
  ProductDetailsConfig,
  ProductDetailsContract,
  ReportFileConfig,
  ReportsConfig,
  ReportsContract,
  SendIncidentProductsConfig,
  MenuConfig,
  StoresContract,
  UpdateOrderConfig,
  UpdateUserConfig,
  OrderHistoryConfig,
  OrderHistoryContract,
  PaymentTypesConfig,
  PaymentTypesContract,
  MenuSortUpdateConfig,
  StopListConfig,
  StopListContract,
  ReviewsConfig,
  ReviewsContract,
  CookingTimesContract,
  CookingTimesConfig,
  ChangesHistoryConfig,
  ChangesHistoryContract,
  ChangesHistoryActions,
  ChangesHistoryTypes,
} from './request.types';

export const createSession = (config: AuthorizationConfig): R<AuthorizationContract> => {
  return http({
    method: 'post',
    url: '/v2/authentication/login',
    data: config,
  });
};

export const closeSession = (config: AccountConfig) => {
  return http({
    method: 'post',
    url: '/v2/authentication/logout',
    headers: config.headers,
  });
};

export const fetchAccount = (config: AccountConfig): R<AccountContract> => {
  return http({
    method: 'get',
    url: '/v2/user',
    headers: config.headers,
  });
};

export const fetchStores = (config: AccountConfig): R<StoresContract> => {
  return http({
    method: 'get',
    url: '/v2/stores',
    headers: config.headers,
  });
};

export const fetchBranchDetails = ({storeId, branchId, headers}: BranchDetailsConfig): R<BranchDetailsContract> => {
  return http({
    url: `/v2/stores/${storeId}/branches/${branchId}`,
    headers: headers,
  });
};

export const updateBranchActivity = ({
  storeId,
  branchId,
  body,
  headers,
}: BranchActivityConfig): R<BranchActivityContract> => {
  return http({
    method: 'patch',
    url: `/v2/stores/${storeId}/branches/${branchId}`,
    headers: headers,
    data: body,
  });
};

export const fetchReports = (config: AccountConfig): R<ReportsContract> => {
  return http({
    method: 'get',
    url: '/v2/reports',
    headers: config.headers,
  });
};

export const createReports = (config: ReportsConfig) => {
  return http({
    method: 'post',
    url: '/v2/reports',
    headers: config.headers,
    data: config.body,
  });
};

export const fetchReportsFile = ({params, headers}: ReportFileConfig) => {
  return http({
    method: 'get',
    url: `/v2/reports/${params.fileId}/excel`,
    responseType: 'blob',
    headers,
  });
};

export const deleteReportsFile = ({params, headers}: ReportFileConfig) => {
  return http({
    method: 'delete',
    url: `/v2/reports/${params.fileId}`,
    headers,
  });
};

export const fetchOrderGroups = ({headers}: AccountConfig): R<OrderGroup[]> => {
  return http({
    method: 'get',
    url: '/v2/orders/groups',
    headers,
  });
};

export const fetchOrders = ({params, headers}: OrdersConfig): R<OrdersContract> => {
  return http({
    method: 'get',
    url: '/v2/orders',
    headers,
    params,
  });
};

export const fetchOrder = ({params, headers}: OrderConfig): R<OrderContract> => {
  return http({
    method: 'get',
    url: `/v2/orders/${params.orderId}`,
    headers,
  });
};

export const fetchSuggestedCookingTimes = ({params, headers}: CookingTimesConfig): R<CookingTimesContract> => {
  return http({
    headers,
    method: 'get',
    url: `v2/orders/${params.orderId}/cooking-times`,
  });
};

export const sendIncidentProducts = ({orderId, body, headers}: SendIncidentProductsConfig) => {
  return http({
    method: 'post',
    url: `/v2/orders/${orderId}/incidents`,
    data: body,
    headers,
  });
};

export const acceptOrder = ({prepareTime, headers, params}: AcceptOrderConfig) => {
  return http({
    method: 'patch',
    url: `/v2/orders/${params.orderId}`,
    headers,
    data: {
      prepareTime,
    },
  });
};

export const updateOrder = ({isReady, headers, params}: UpdateOrderConfig) => {
  return http({
    method: 'patch',
    url: `/v2/orders/${params.orderId}`,
    headers,
    data: {
      isReady,
    },
  });
};

export const finishOrder = ({isDeparted, headers, params}: FinishOrderConfig) => {
  return http({
    method: 'patch',
    url: `/v2/orders/${params.orderId}`,
    headers,
    data: {
      isDeparted,
    },
  });
};

export const fetchEmployees = (config: AccountConfig): R<EmployeesContract> => {
  return http({
    method: 'get',
    url: '/v2/employees',
    headers: config.headers,
  });
};

export const updateEmployee = ({params, headers, data}: UpdateUserConfig): R<Employee> => {
  return http({
    method: 'patch',
    url: `/v2/employees/${params.userId}`,
    headers,
    data,
  });
};

export const createEmployee = ({headers, data}: CreateUserConfig) => {
  return http({
    method: 'post',
    url: '/v2/employees',
    headers,
    data,
  });
};

export const fetchOrderHistory = (config: OrderHistoryConfig): R<OrderHistoryContract> => {
  return http({
    url: `/v2/stores/${config.storeId}/orders/history`,
    headers: config.headers,
    params: config.params,
  });
};

export const fetchPaymentTypes = (config: PaymentTypesConfig): R<PaymentTypesContract> => {
  return http({
    url: '/v2/system/payment-types',
    headers: config.headers,
  });
};

export const fetchMenu = ({path, params, headers}: MenuConfig): R<MenuContract> => {
  return http({
    params,
    headers,
    url: `v2/stores/${path.storeId}/menu`,
  });
};

export const fetchStopList = ({path, params, headers}: StopListConfig): R<StopListContract> => {
  return http({
    params,
    headers,
    url: `v2/stores/${path.storeId}/menu`,
  });
};

export const sortMenu = ({path, data, headers}: MenuSortUpdateConfig) => {
  return http({
    data,
    headers,
    method: 'patch',
    url: `v2/stores/${path.storeId}/menu`,
  });
};

export const updateMenu = ({path, data, headers}: MenuUpdateConfig) => {
  return http({
    data,
    headers,
    method: 'patch',
    url: `v2/stores/${path.storeId}/menu/${path.menuId}`,
  });
};

export const sortProducts = ({path, data, headers}: ProductsSortConfig) => {
  return http({
    data,
    headers,
    method: 'patch',
    url: `v2/stores/${path.storeId}/products`,
  });
};

export const fetchProductDetails = ({path, params, headers}: ProductDetailsConfig): R<ProductDetailsContract> => {
  return http({
    params,
    headers,
    url: `v2/stores/${path.storeId}/products/${path.productId}`,
  });
};

export const updateProductDetails = ({path, data, headers}: ProductDetailsUpdateConfig) => {
  return http({
    data,
    headers,
    method: 'patch',
    url: `v2/stores/${path.storeId}/products/${path.productId}`,
  });
};

export const deleteProduct = ({path, headers}: ProductDeleteConfig) => {
  return http({
    headers,
    method: 'delete',
    url: `v2/stores/${path.storeId}/products/${path.productId}`,
  });
};

export const fetchReviews = ({headers, params}: ReviewsConfig): R<ReviewsContract> => {
  return http({
    headers,
    params,
    method: 'get',
    url: 'v2/reviews',
  });
};

export const fetchChangesHistory = ({headers, params}: ChangesHistoryConfig): R<ChangesHistoryContract> => {
  return http({
    headers,
    params,
    method: 'get',
    url: 'v2/changes-history',
  });
};

export const fetchChangesHistoryTypes = ({headers}: AccountConfig): R<ChangesHistoryTypes> => {
  return http({
    headers,
    method: 'get',
    url: 'v2/changes-history/types',
  });
};

export const fetchChangesHistoryActions = ({headers}: AccountConfig): R<ChangesHistoryActions> => {
  return http({
    headers,
    method: 'get',
    url: 'v2/changes-history/actions',
  });
};
