import {ChangeEvent} from 'react';
import {history} from '@shared/history';
import {debounce} from 'patronum/debounce';
import {DropResult} from 'react-beautiful-dnd';
import {attachSession} from '@entities/session';
import {MenuSections, fetchMenu, MenuCredentials} from '@api/v2';
import {searchDisabled, searchRequested} from './search';
import {$allBranchesOptions, $stores} from '@entities/attached-stores';
import {Products, MenuRequestConfig, SubmenuRequestCredentials} from './model.types';
import {combine, guard, merge, sample, createEvent, createStore, createEffect} from 'effector';
import {fetchStopListFx, stopListClosed, stopListOpened, stopListRequested} from './stop-list';
import {
  SubmenuSortCredentials,
  menuSortAccomplished,
  menuSorted,
  submenuSorted,
  productsSorted,
  menuSortEnabled,
  menuSortRequested,
  submenuSortEnabled,
  submenuSortRequested,
  productSortRequested,
  submenuSortAccomplished,
  productsSortModalClosed,
  productsSortModalOpened,
  productsSortAccomplished,
  sortMenuFx,
  sortProductsFx,
  sortSubmenuFx,
  $orderedProducts,
  $orderedMenuSections,
  $orderedSubmenuSections,
  $isProductsSortPending,
} from './sort';
import {
  MenuSwitchToggleParams,
  ProductSwitchToggleParams,
  REFETCH_TIMOUT,
  menuSwitchToggled,
  submenuSwitchToggled,
  productSwitchToggled,
  productActivityModalClosed,
  productActivityModalOpened,
  updateMenuFx,
  updateProductFx,
  updateSubmenuFx,
  $isMenusLoadingIdsEmpty,
  $isProductsLoadingIdsEmpty,
} from './update';

export const pageMounted = createEvent();
export const pageUnmounted = createEvent();
const menuRefetchRequested = createEvent<MenuCredentials>();
const submenuRefetchRequested = createEvent<SubmenuRequestCredentials>();
export const menuSelected = createEvent<number>();
export const branchSelected = createEvent<number>();
export const submenuSelected = createEvent<number>();
export const productsSortDragEnded = createEvent<DropResult>();
export const productsSortSubmitted = createEvent();
export const menuSortEnableRequested = createEvent();
export const menuSortDragEnded = createEvent<DropResult>();
export const menuSortSubmitted = createEvent();
export const submenuSortEnableRequested = createEvent();
export const submenuSortDragEnded = createEvent<DropResult>();
export const submenuSortSubmitted = createEvent();
export const menuSwitchToggleRequested = createEvent<MenuSwitchToggleParams>();
export const submenuSwitchToggleRequested = createEvent<MenuSwitchToggleParams>();
export const productsSortModalOpenRequested = createEvent();
export const productsSortModalCloseRequested = createEvent();
export const productSwitchToggleRequested = createEvent<ProductSwitchToggleParams>();
export const productActivityModalOpenRequested = createEvent<number>();
export const productActivityModalCloseRequested = createEvent();
export const stopListOpenRequested = createEvent();
export const searchInputChanged = createEvent<ChangeEvent<HTMLInputElement>>();

export const $menu = createStore<MenuSections | null>(null);
export const $submenu = createStore<MenuSections | null>(null);
export const $selectedMenuId = createStore<number | null>(null);
export const $products = createStore<Products | null>(null);
export const $selectedStoreId = createStore<number | null>(null);
export const $selectedBranchId = createStore<number | null>(null);
export const $selectedSubmenuId = createStore<number | null>(null);

export const $isMenuNested = createStore<boolean>(false);
export const $isMenuRequestPending = createStore<boolean>(false);
export const $isSubmenuRequestPending = createStore<boolean>(false);
export const $isMenuAlreadyFetched = createStore<boolean>(false);
export const $isSubmenuAlreadyFetched = createStore<boolean>(false);
export const $isStopListAlreadyFetched = createStore<boolean>(false);

export const fetchMenuFx = attachSession({
  effect: createEffect(fetchMenu),
  mapParams: ({path, params}: MenuRequestConfig, authorization) => ({
    path,
    params,
    headers: {
      authorization,
    },
  }),
});

export const fetchSubmenuFx = attachSession({
  effect: createEffect(fetchMenu),
  mapParams: ({params, path}: MenuRequestConfig, authorization) => ({
    path,
    params,
    headers: {
      authorization,
    },
  }),
});

const $menuCredentials = combine($selectedStoreId, $selectedBranchId, (storeId, branchId) => {
  if (!storeId) return null;
  if (!branchId) return null;

  return {
    storeId,
    branchId,
  };
});

const $submenuCredentials = combine(
  $selectedStoreId,
  $selectedBranchId,
  $selectedMenuId,
  (storeId, branchId, menuId) => {
    if (!storeId) return null;
    if (!branchId) return null;
    if (!menuId) return null;

    return {
      menuId,
      storeId,
      branchId,
    };
  },
);

const $formattedBranchesIdsByStoreId = $stores.map((stores) => {
  if (!stores) return {};

  const branchesIds: Record<number, number> = {};

  for (const store of stores) {
    for (const branch of store.branches) {
      branchesIds[branch.id] = store.id;
    }
  }

  return branchesIds;
});

export const $selectedMenuName = combine($menu, $selectedMenuId, (menu, selectedMenuId) => {
  if (!menu) return null;
  if (!selectedMenuId) return null;

  const section = menu.find(({id}) => id === selectedMenuId);

  if (section) return section.name;

  return null;
});

export const $isProductsPending = combine(
  $isMenuRequestPending,
  $isProductsSortPending,
  $isSubmenuRequestPending,
  (...loadingStates) => loadingStates.some((isLoading) => isLoading),
);

$menu
  .on(fetchMenuFx.doneData, (_, {data}) => data.sections)
  .on(updateMenuFx.done, (menu, {params}) => {
    const activityData = params.data.activity;

    if (!menu) return null;
    if (!activityData) return menu;
    if (activityData.isSubmenu) return menu;

    return menu.map((section) => {
      if (section.id === params.path.menuId) {
        return {
          ...section,
          isEnabled: activityData.isActive,
        };
      }

      return section;
    });
  });

$submenu
  .on(fetchSubmenuFx.doneData, (_, {data}) => data.sections)
  .on(updateSubmenuFx.done, (menu, {params}) => {
    const activityData = params.data.activity;

    if (!menu) return null;
    if (!activityData) return menu;
    if (!activityData.isSubmenu) return menu;

    return menu.map((section) => {
      if (section.id === params.path.menuId) {
        return {
          ...section,
          isEnabled: activityData.isActive,
        };
      }

      return section;
    });
  })
  .on([branchSelected, pageUnmounted, stopListOpened], () => null);

$products
  .on(updateProductFx.done, (products, {params}) => {
    const updatedBranch = params.data.branches && params.data.branches[0].availability;

    if (!products) return null;
    if (!updatedBranch) return products;

    return {
      title: products.title,
      list: products.list.map((product) => {
        if (product.id === params.path.productId) {
          return {
            ...product,
            availability: {
              ...updatedBranch,
              unavailableTill: updatedBranch.unavailableTill || null,
            },
          };
        }

        return product;
      }),
    };
  })
  .on([branchSelected, pageUnmounted, stopListOpened], () => null);

$selectedBranchId.on(branchSelected, (_, branchId) => branchId).on(pageUnmounted, () => null);

$selectedMenuId.on(menuSelected, (_, menuId) => menuId).on([branchSelected, stopListOpened, pageUnmounted], () => null);

$selectedSubmenuId
  .on(submenuSelected, (_, submenuId) => submenuId)
  .on([branchSelected, menuSelected, stopListOpened, pageUnmounted], () => null);

$isMenuNested.on(fetchMenuFx.doneData, (_, {data}) => data.isNested);

$isMenuRequestPending.on(fetchMenuFx.pending, (_, pending) => pending);

$isSubmenuRequestPending.on(fetchSubmenuFx.pending, (_, pending) => pending);

$isMenuAlreadyFetched.on(fetchMenuFx.done, () => true).on([branchSelected, pageUnmounted], () => false);

$isSubmenuAlreadyFetched
  .on(fetchSubmenuFx.done, () => true)
  .on([branchSelected, pageUnmounted, menuSelected], () => false);

$isStopListAlreadyFetched.on(fetchStopListFx.done, () => true).on([branchSelected, pageUnmounted], () => false);

sample({
  source: {
    branchId: $selectedBranchId,
    branches: $formattedBranchesIdsByStoreId,
  },
  fn: ({branchId, branches}) => {
    if (!branchId) return null;

    return branches[branchId];
  },
  target: $selectedStoreId,
});

sample({
  source: {
    selectedBranchId: $selectedBranchId,
    branches: $allBranchesOptions,
  },
  clock: pageMounted,
  filter: ({selectedBranchId}) => selectedBranchId === null,
  fn: ({branches}) => {
    if (!branches) return null;

    const searchParams = new URLSearchParams(history.location.search);
    const branchId = searchParams.get('branchId');

    if (branchId) return Number(branchId);

    return branches[0].value;
  },
  target: $selectedBranchId,
});

sample({
  source: guard({
    clock: $menuCredentials,
    filter: (config): config is MenuCredentials => config !== null,
  }),
  clock: debounce({
    source: merge([updateMenuFx.done, sortMenuFx.done]),
    timeout: REFETCH_TIMOUT,
  }),
  filter: $isMenusLoadingIdsEmpty,
  target: menuRefetchRequested,
});

sample({
  source: guard({
    clock: $menuCredentials,
    filter: (config): config is MenuCredentials => config !== null,
  }),
  clock: debounce({
    source: merge([updateProductFx.done, sortProductsFx.done]),
    timeout: REFETCH_TIMOUT,
  }),
  filter: $isProductsLoadingIdsEmpty && $isMenuNested.map((isNested) => !isNested),
  target: menuRefetchRequested,
});

sample({
  source: guard({
    clock: [$menuCredentials, menuRefetchRequested],
    filter: (config): config is MenuCredentials => config !== null,
  }),
  fn: ({storeId, branchId}) => ({
    path: {
      storeId,
    },
    params: {
      branchId,
    },
  }),
  target: fetchMenuFx,
});

sample({
  source: guard({
    source: $submenuCredentials,
    filter: (config): config is SubmenuRequestCredentials => config !== null,
  }),
  clock: debounce({
    source: merge([updateSubmenuFx.done, sortSubmenuFx.done]),
    timeout: REFETCH_TIMOUT,
  }),
  filter: $isMenusLoadingIdsEmpty,
  target: submenuRefetchRequested,
});

sample({
  source: guard({
    source: $submenuCredentials,
    clock: debounce({
      source: merge([updateProductFx.done, sortProductsFx.done]),
      timeout: REFETCH_TIMOUT,
    }),
    filter: (config): config is SubmenuRequestCredentials => config !== null,
  }),
  filter: $isProductsLoadingIdsEmpty && $isMenuNested,
  target: submenuRefetchRequested,
});

sample({
  source: guard({
    source: $submenuCredentials,
    filter: (data): data is SubmenuRequestCredentials => data !== null,
    clock: [fetchMenuFx.done, menuSelected, submenuRefetchRequested],
  }),
  fn: ({storeId, branchId, menuId}) => ({
    path: {
      storeId,
    },
    params: {
      branchId,
      parentId: menuId,
    },
  }),
  filter: $isMenuNested,
  target: fetchSubmenuFx,
});

sample({
  clock: stopListOpenRequested,
  target: stopListOpened,
});

sample({
  clock: [menuSelected, submenuSelected],
  target: stopListClosed,
});

sample({
  source: guard({
    source: $menuCredentials,
    filter: (config): config is MenuCredentials => config !== null,
  }),
  clock: [fetchMenuFx.done, updateProductFx.done, updateMenuFx.done, updateSubmenuFx.done],
  fn: ({storeId, branchId}) => ({
    path: {
      storeId,
    },
    params: {
      branchId,
    },
  }),
  target: stopListRequested,
});

sample({
  source: {
    menu: $menu,
    submenu: $submenu,
    isMenuNested: $isMenuNested,
    selectedMenuId: $selectedMenuId,
    selectedSubmenuId: $selectedSubmenuId,
  },
  fn: ({menu, submenu, isMenuNested, selectedMenuId, selectedSubmenuId}) => {
    if (!menu) return null;
    if (!selectedMenuId) return null;

    if (isMenuNested) {
      if (!submenu) return null;
      if (!selectedSubmenuId) return null;

      const section = submenu.find(({id}) => id === selectedSubmenuId);

      if (section) {
        return {
          title: section.name,
          list: section.products,
        };
      }

      return null;
    }

    const section = menu.find(({id}) => id === selectedMenuId);

    if (section) {
      return {
        title: section.name,
        list: section.products,
      };
    }

    return null;
  },
  target: $products,
});

sample({
  source: guard({
    source: $products,
    clock: productsSortModalOpenRequested,
    filter: (products): products is Products => products !== null,
  }),
  fn: ({list}) => list,
  target: $orderedProducts,
});

sample({
  clock: productsSortModalOpenRequested,
  target: productsSortModalOpened,
});

sample({
  clock: productsSortModalCloseRequested,
  target: productsSortModalClosed,
});

sample({
  clock: productsSortDragEnded,
  target: productsSorted,
});

sample({
  clock: [fetchMenuFx.done, fetchSubmenuFx.done],
  target: productsSortAccomplished,
});

sample({
  source: guard({
    source: $selectedStoreId,
    clock: productsSortSubmitted,
    filter: (storeId): storeId is number => storeId !== null,
  }),
  target: productSortRequested,
});

sample({
  source: $menu,
  target: $orderedMenuSections,
});

sample({
  clock: menuSortEnableRequested,
  target: menuSortEnabled,
});

sample({
  clock: menuSortDragEnded,
  target: menuSorted,
});

sample({
  source: guard({
    source: $selectedStoreId,
    clock: menuSortSubmitted,
    filter: (storeId): storeId is number => storeId !== null,
  }),
  target: menuSortRequested,
});

sample({
  clock: fetchMenuFx.done,
  target: menuSortAccomplished,
});

sample({
  source: $submenu,
  target: $orderedSubmenuSections,
});

sample({
  clock: submenuSortEnableRequested,
  target: submenuSortEnabled,
});

sample({
  clock: submenuSortDragEnded,
  target: submenuSorted,
});

sample({
  source: guard({
    source: {
      storeId: $selectedStoreId,
      menuId: $selectedMenuId,
    },
    clock: submenuSortSubmitted,
    filter: (data): data is SubmenuSortCredentials => data !== null,
  }),
  target: submenuSortRequested,
});

sample({
  clock: fetchSubmenuFx.done,
  target: submenuSortAccomplished,
});

sample({
  source: guard({
    source: $menuCredentials,
    filter: (config): config is MenuCredentials => config !== null,
  }),
  clock: menuSwitchToggleRequested,
  fn: ({storeId, branchId}, {menuId, checked, isSubmenu}) => ({
    menuId,
    checked,
    storeId,
    branchId,
    isSubmenu,
  }),
  target: menuSwitchToggled,
});

sample({
  source: guard({
    source: $menuCredentials,
    filter: (config): config is MenuCredentials => config !== null,
  }),
  clock: submenuSwitchToggleRequested,
  fn: ({storeId, branchId}, {menuId, checked, isSubmenu}) => ({
    menuId,
    checked,
    storeId,
    branchId,
    isSubmenu,
  }),
  target: submenuSwitchToggled,
});

sample({
  source: guard({
    source: $menuCredentials,
    filter: (config): config is MenuCredentials => config !== null,
  }),
  clock: productSwitchToggleRequested,
  fn: ({storeId, branchId}, {productId, checked, unavailableTill}) => ({
    checked,
    storeId,
    branchId,
    productId,
    unavailableTill,
  }),
  target: productSwitchToggled,
});

sample({
  clock: productActivityModalOpenRequested,
  target: productActivityModalOpened,
});

sample({
  clock: productActivityModalCloseRequested,
  target: productActivityModalClosed,
});

sample({
  source: guard({
    source: $menuCredentials,
    filter: (config): config is MenuCredentials => config !== null,
  }),
  clock: searchInputChanged,
  fn: (menuCredentials, {currentTarget}) => ({
    ...menuCredentials,
    search: currentTarget.value,
  }),
  target: searchRequested,
});

sample({
  clock: pageUnmounted,
  target: searchDisabled,
});
