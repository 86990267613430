import {useMemo} from 'react';
import {useUnit} from 'effector-react';
import styles from './styles.module.scss';
import * as model from '../../../../model';
import {Input, Radio, Typography} from '@express-24/theseus-ui';
import {Branch, FormattedBranches} from '../../../../model/model.types';
import {useTranslation} from 'react-i18next';

export const QuantityControl = () => {
  const {
    handleQuantityChange,
    handleQuantityTypeChange,

    branches,
  } = useUnit({
    handleQuantityChange: model.productQuantityChanged,
    handleQuantityTypeChange: model.productQuantityTypeChanged,

    branches: model.$formattedBranches,
  });
  const {t} = useTranslation();

  const branchList = useMemo(() => {
    if (!branches) return null;

    const branchesList: Array<Branch> = [];

    for (const branch in branches) {
      branchesList.push(branches[branch]);
    }

    return branchesList;
  }, [branches]);

  if (!branches) return null;

  return (
    <div>
      <Typography bold
        level="h5"
      >
        {t('menu.available_product_amount')}
      </Typography>
      {branchList &&
        branchList.map(({id, name, amount}) => (
          <div className={styles.Card}
            key={id}
          >
            <Typography level="text"
              className={styles.CardTitle}
            >
              {name}
            </Typography>
            <div className={styles.Controls}>
              <div className={styles.Wrapper}>
                <Radio.Group
                  onChange={(value) =>
                    handleQuantityTypeChange({
                      id,
                      value,
                    })
                  }
                  selected={amount.isInfinite ? model.INFINITE_QUANTITY_KEY : model.NON_INFINITE_QUANTITY_KEY}
                >
                  {model.QUANTITY_OPTIONS.map(({name, value}) => (
                    <Radio key={value}
                      value={value}
                    >
                      {t(`branches_management.${name}`)}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
              <div>
                <Input
                  fluid
                  label={t('amount')}
                  disabled={amount.isInfinite}
                  value={amount.isInfinite ? '' : amount.value}
                  onChange={({currentTarget}) =>
                    handleQuantityChange({
                      id,
                      value: currentTarget.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
