import {useMemo} from 'react';
import {useUnit} from 'effector-react';
import {history} from '@shared/history';
import styles from './styles.module.scss';
import * as model from '../../../../model';
import {EmptyState} from '@widgets/empty-state';
import {Typography} from '@express-24/theseus-ui';
import {MenuCell} from '../../molecules/menu-cell';
import {FallbackLoader, MenuLoader} from '../../../ui';
import {Droppable, Draggable, DragDropContext} from 'react-beautiful-dnd';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const MenuList = () => {
  const {
    handleSortSubmit,
    handleSortEnable,
    handleSortDragEnd,
    handleMenuSwitchToggle,

    menu,
    selectedMenuId,
    selectedBranchId,

    isNested,
    isSortActive,
    isSortPending,
    isMenuPending,
    isMenuAlreadyFetched,
  } = useUnit({
    handleSortSubmit: model.menuSortSubmitted,
    handleSortDragEnd: model.menuSortDragEnded,
    handleSortEnable: model.menuSortEnableRequested,
    handleMenuSwitchToggle: model.menuSwitchToggleRequested,

    menu: model.$orderedMenuSections,
    selectedMenuId: model.$selectedMenuId,
    selectedBranchId: model.$selectedBranchId,

    isNested: model.$isMenuNested,
    isSortActive: model.$isMenuSortActive,
    isSortPending: model.$isMenuSortPending,
    isMenuPending: model.$isMenuRequestPending,
    isMenuAlreadyFetched: model.$isMenuAlreadyFetched,
  });
  const {t} = useTranslation();

  const handleMenuClick = (menuId: number) => {
    if (isSortActive) return;

    history.replace({
      pathname: '/menu',
      search: `branchId=${selectedBranchId}&menuId=${menuId}`,
    });
  };

  const sortLoaderView = useMemo(() => {
    if (!isSortPending) return;

    return <FallbackLoader />;
  }, [isSortPending]);

  const contentLoadingView = useMemo(() => {
    if (isSortPending) return null;
    if (!isMenuPending) return null;
    if (isMenuAlreadyFetched) return null;

    return <MenuLoader />;
  }, [isSortPending, isMenuPending, isMenuAlreadyFetched]);

  const handleSortButtonClick = () => {
    if (isSortActive) return handleSortSubmit();

    return handleSortEnable();
  };

  const sortButtonView = useMemo(() => {
    if (!menu) return null;
    if (!menu.length) return null;

    const sortButtonTitle = isSortActive ? t('actions.save') : t('actions.sort');

    return (
      <div className={styles.SortButton}
        onClick={handleSortButtonClick}
        data-is-sort-active={isSortActive}
      >
        <Typography level="text">{sortButtonTitle}</Typography>
      </div>
    );
  }, [isSortActive, menu, i18n.language]);

  const contentView = useMemo(() => {
    if (!menu) return null;
    if (isMenuPending && !isMenuAlreadyFetched) return null;

    if (!menu.length) {
      return (
        <div className={styles.EmptyView}>
          <EmptyState
            illustration="noBranches"
            title={t('menu.no_categories')}
            description={t('menu.contact_manager')}
          />
        </div>
      );
    }

    return (
      <>
        <div className={styles.Header}>
          <Typography semibold
            level="h5"
            lineClamp={2}
          >
            {isNested ? t('categories') : t('menu.sections')}
          </Typography>

          {sortButtonView}
        </div>
        <DragDropContext onDragEnd={handleSortDragEnd}>
          <Droppable droppableId="menu">
            {({innerRef}) => (
              <div ref={innerRef}
                className={styles.MenuWrapper}
              >
                {menu.map((section, index) => (
                  <Draggable
                    index={index}
                    key={section.id}
                    isDragDisabled={!isSortActive}
                    draggableId={section.id.toString()}
                  >
                    {(provided, snapshot) => (
                      <div {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <div key={section.id}
                          onClick={() => handleMenuClick(section.id)}
                        >
                          <MenuCell
                            isSubmenu={false}
                            section={section}
                            isMenuNested={isNested}
                            isSortActive={isSortActive}
                            dragging={snapshot.isDragging}
                            onSwitchToggle={handleMenuSwitchToggle}
                            selected={section.id === selectedMenuId}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  }, [
    menu,
    selectedMenuId,
    sortButtonView,
    isNested,
    isSortActive,
    isMenuPending,
    isMenuAlreadyFetched,
    i18n.language,
  ]);

  return (
    <div className={styles.Container}>
      {sortLoaderView}

      {contentView}

      {contentLoadingView}
    </div>
  );
};
