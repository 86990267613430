import styles from './styles.module.scss';
import {FC} from 'react';
import {ProductProps} from './types';
import {Typography} from '@express-24/theseus-ui';
import {ImageCover} from 'shared/ui/molecules/image-cover';
import {useTranslation} from 'react-i18next';

export const Product: FC<ProductProps> = ({cover, props, id, price, currency, quantity, name}: ProductProps) => {
  const {t} = useTranslation();

  return (
    <div className={styles.ProductWrapper}>
      <div className={styles.ProductContainer}>
        <div className={styles.ProductImage}>
          <ImageCover coverUrl={cover} />
        </div>
        <div className={styles.ProductName}>
          <Typography level="text">{name}</Typography>
          {props.map((prop) => (
            <Typography level="caption"
              kind="secondary"
              key={prop.value}
            >
              • {prop.value}
            </Typography>
          ))}
        </div>
        <div className={styles.ProductValues}>
          <Typography level="text">
            {price.sold.toLocaleString()} {currency}
          </Typography>
          <Typography level="sub-text"
            kind="secondary"
          >
            {quantity} {t('piece')}
          </Typography>
        </div>
      </div>
    </div>
  );
};
