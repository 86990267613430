import {FC, useCallback, useEffect, useRef} from 'react';
import {useUnit} from 'effector-react';
import {Image, Input, Typography, Button} from '@express-24/theseus-ui';
import logo from './ui/assets/logo.svg';
import styles from './page.module.scss';
import {
  $errorMessage,
  formSubmitted,
  loginChanged,
  passwordChanged,
  pageMounted,
  pageUnmounted,
  $inputStatus,
} from '../model';
import {createSessionFx} from '@features/login';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export const Login: FC = () => {
  const model = useUnit({
    handleLoginChange: loginChanged,
    handlePasswordChange: passwordChanged,

    errorMessage: $errorMessage,
    inputStatus: $inputStatus,
    isLoading: createSessionFx.pending,

    handlePageMount: pageMounted,
    handlePageUnmount: pageUnmounted,
    handleSubmit: formSubmitted,
  });

  const loginInputRef = useRef<HTMLInputElement>(null);
  const {t} = useTranslation();

  useEffect(() => {
    model.handlePageMount();

    if (loginInputRef.current) {
      loginInputRef.current.focus();
    }

    return () => model.handlePageUnmount();
  }, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    model.handleSubmit();
  }, []);

  return (
    <div className={styles.Page}>
      <div className={styles.Logo}>
        <Image src={logo} />
      </div>

      <form onSubmit={handleSubmit}
        className={styles.Form}
      >
        <Input
          onChange={(e) => model.handleLoginChange(e.target.value)}
          label={t('login')}
          type="text"
          fluid
          autoFocus={true}
        />
        <Input.Password
          onChange={(event) => model.handlePasswordChange(event.target.value)}
          label={t('password')}
          message={model.errorMessage && model.errorMessage}
          status={model.inputStatus}
          fluid
        />
        <div className={styles.Submit}>
          <Typography level="caption"
            kind="secondary"
          >
            {t('login_agreement')} <br />
            <Link to="/user-agreement.html"
              target="_blank"
            >
              {t('login_agreement_url')}
            </Link>
          </Typography>
          <Button loading={model.isLoading}
            type="submit"
            size="large"
            kind="primary"
            fluid
          >
            {t('actions.enter')}
          </Button>
        </div>
      </form>
    </div>
  );
};
