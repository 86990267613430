import styles from './styles.module.scss';
import {Icon, Typography} from '@express-24/theseus-ui';
import {ProductCombinationDetail} from '../../../../model/model.types';
import {useTranslation} from 'react-i18next';

type Props = {
  offer: ProductCombinationDetail;
  onOfferEdit: (id: number) => void;
};

export const OfferCard = ({offer, onOfferEdit}: Props) => {
  const {t} = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <div className={styles.Combinations}>
          {offer.properties.map(({propertyName, optionName}, index) => (
            <div key={index}
              className={styles.CombinationProperties}
            >
              <Typography semibold
                level="text"
              >
                {propertyName}:
              </Typography>
              <Typography level="text">{optionName}</Typography>
            </div>
          ))}
        </div>
        <div onClick={() => onOfferEdit(offer.productId)}
          className={styles.EditButton}
        >
          <Icon size={24}
            name="edit"
          />
        </div>
      </div>
      <div className={styles.Footer}>
        <div className={styles.Price}>
          <Typography semibold
            level="text"
          >
            {`${t('price')}:`}
          </Typography>
          <Typography level="text">
            {offer.price.sold} {offer.price.currency}
          </Typography>
        </div>
      </div>
    </div>
  );
};
