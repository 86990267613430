import {WSS_URL} from '@shared/api/wss';
import {userRefined} from '@entities/user';
import {CentrifugeInstance, NotificationContract} from './model.types';
import {createEffect, createEvent, guard, sample, scopeBind} from 'effector';
import {Centrifuge} from 'centrifuge';
import {$wsToken} from '@entities/session';
import {$stores, attachedStoresChanged, Stores} from '@entities/attached-stores';

const notificationCentrifugeInstance: CentrifugeInstance = {};

export const notificationReceived = createEvent<NotificationContract>();

const createWsInstanceFx = createEffect((wsToken: string) => {
  notificationCentrifugeInstance.instance = new Centrifuge(WSS_URL, {
    token: wsToken,
  });
});

const subscribeNotificationsSocketFx = createEffect((storesIds: number[]) => {
  for (const storeId of storesIds) {
    if (!notificationCentrifugeInstance.instance) return;

    const notificationSub = notificationCentrifugeInstance.instance.newSubscription(
      `$merchant:notifications_${storeId}`,
    );
    notificationSub.on('publication', scopeBind(notificationReceived));
    notificationSub.subscribe();
  }
});

const connectNotificationsSocketFx = createEffect(() => {
  if (notificationCentrifugeInstance.instance) notificationCentrifugeInstance.instance.connect();
});

const disconnectNotificationsSocketFx = createEffect(() => {
  if (notificationCentrifugeInstance.instance) notificationCentrifugeInstance.instance.disconnect();
});

sample({
  source: guard({
    clock: attachedStoresChanged,
    source: $wsToken,
    filter: (wsToken): wsToken is string => {
      if (notificationCentrifugeInstance.instance) return false;
      return wsToken !== null;
    },
  }),
  fn: (wsToken) => wsToken,
  target: createWsInstanceFx,
});

sample({
  clock: guard({
    clock: createWsInstanceFx.done,
    source: $stores,
    filter: (stores): stores is Stores => stores !== null,
  }),
  fn: (stores) => {
    return stores.map((store) => store.id);
  },
  target: subscribeNotificationsSocketFx,
});

sample({
  clock: subscribeNotificationsSocketFx.done,
  target: connectNotificationsSocketFx,
});

sample({
  clock: userRefined,
  target: disconnectNotificationsSocketFx,
});
