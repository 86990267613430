import {combine, createEffect, createEvent, createStore, guard, sample} from 'effector';
import {
  acceptOrder,
  fetchOrder,
  fetchOrderGroups,
  fetchOrders,
  fetchSuggestedCookingTimes,
  finishOrder,
  updateOrder,
  Order,
  OrderContract,
  OrderGroup,
  OrdersContract,
  CookingTimesContract,
} from '@api/v2';
import {attachSession} from '@entities/session';
import {$stores} from '@entities/attached-stores';
import {
  AcceptParams,
  FilteredGroup,
  FinishParams,
  GroupTriggers,
  NotificationContract,
  OrdersParams,
  SynchronizeOrderListConfig,
  UpdateParams,
} from './model.types';
import newOrderSound from '../assets/sound.wav';
import changeStatusSound from '../assets/sound2.wav';
import {fetchStoresFx} from '@features/user/attached-stores';
import {history} from '@shared/history';
import {orderUpdated} from '@features/live-orders-update';
import {notificationReceived} from '@features/live-notifications';
import {interval} from 'patronum/interval';
import {createGate} from 'effector-react';

const audioObj = new Audio(newOrderSound);
const changeStatusAudioObj = new Audio(changeStatusSound);

export const groupSelected = createEvent<string>();
export const branchesChanged = createEvent<number[]>();
export const ordersViewOpened = createEvent();
export const searchViewOpened = createEvent();
export const orderSelected = createEvent<number>();
export const acceptModalOpened = createEvent();
export const acceptModalClosed = createEvent();
export const prepareTimeChanged = createEvent<number>();
export const prepareTimeSubmitted = createEvent();
export const orderPrepared = createEvent();
export const orderFinished = createEvent();
const startNewOrderSounds = createEvent();
const stopNewOrderSounds = createEvent();
const newOrderChanged = createEvent<Order>();

export const ordersGate = createGate();

export const $orders = createStore<OrdersContract | null>(null);
export const $selectedOrder = createStore<OrderContract | null>(null);
export const $selectedOrderId = createStore<number | null>(null);
export const $orderGroups = createStore<OrderGroup[] | null>(null);
export const $selectedGroup = createStore<OrderGroup | null>(null);
export const $pickedBranches = createStore<number[]>([]);
export const $isOrdersListView = createStore<boolean>(true);
export const $isAcceptModalOpened = createStore<boolean>(false);
export const $prepareTime = createStore<number | null>(null);
export const $socketUpdates = createStore<GroupTriggers | null>(null);
export const $notification = createStore<NotificationContract | null>(null);
export const $suggestedCookingTimes = createStore<CookingTimesContract>([]);
export const $newOrders = createStore<Order[] | null>(null);

export const $selectedOrderStatuses = $selectedOrder.map((selectedOrder) => {
  if (!selectedOrder) {
    return {
      isPreparingTakeaway: false,
      isPreparing: false,
      isTakeawayPicked: false,
      notAcceptedPreorder: false,
    };
  }

  return {
    isPreparingTakeaway: selectedOrder.isTakeaway && !!selectedOrder.date.acceptedAt && !selectedOrder.isReady,
    isPreparing:
      Boolean(selectedOrder.courier) &&
      Boolean(selectedOrder.date.acceptedAt) &&
      !selectedOrder.isReady &&
      selectedOrder.status.code !== 'orderSended',
    isTakeawayPicked: selectedOrder.isTakeaway && selectedOrder.isReady,
    notAcceptedPreorder:
      !selectedOrder.date.acceptedAt && !!selectedOrder.date.preorderTo && selectedOrder.status.code !== 'new',
  };
});

export const $filteredOrderGroups = $orderGroups.map((orderGroups) => {
  if (!orderGroups) return;

  const result: FilteredGroup[] = [];

  for (const group of orderGroups) {
    result.push({
      label: group.name,
      value: group.alias,
      name: String(group.isUpdated),
    });
  }

  return result;
});

export const $orderParams = combine($pickedBranches, $selectedGroup, (branchesIds, orderGroup) => {
  if (!branchesIds) return null;
  if (!branchesIds.length) return null;
  if (!orderGroup) return null;

  return {
    branchesIds,
    groupAlias: orderGroup.alias,
  };
});

export const $attachedBranches = $stores.map((stores) => {
  if (!stores) return null;

  const branches = [];

  for (let i = 0; i < stores.length; i++) {
    for (let j = 0; j < stores[i].branches.length; j++) {
      if (stores[i].branches[j].activity.isActive) {
        branches.push({
          label: stores[i].branches[j].name,
          caption: stores[i].name,
          value: stores[i].branches[j].id,
        });
      }
    }
  }

  if (branches.length === 0) {
    return null;
  }

  return branches;
});

export const $isOptimalTimeChosen = combine($suggestedCookingTimes, $prepareTime, (cookingTimes, prepareTime) => {
  if (!prepareTime) return false;

  const suggestedTime = cookingTimes.find(({value}) => value === prepareTime);

  if (!suggestedTime) return false;

  return suggestedTime.isOptimal;
});

export const fetchOrderGroupsApiFx = createEffect(fetchOrderGroups);
export const fetchOrdersApiFx = createEffect(fetchOrders);
export const fetchOrderApiFx = createEffect(fetchOrder);
export const acceptOrderApiFx = createEffect(acceptOrder);
export const updateOrderApiFx = createEffect(updateOrder);
export const finishOrderApiFx = createEffect(finishOrder);
export const goToOrdersPageFx = createEffect(() => history.push('/orders'));

export const fetchOrderGroupsFx = attachSession({
  effect: fetchOrderGroupsApiFx,
  mapParams: (_, authorization) => ({
    headers: {
      authorization,
    },
  }),
});

export const fetchOrdersFx = attachSession({
  effect: fetchOrdersApiFx,
  mapParams: (params: OrdersParams, authorization) => ({
    params,
    headers: {
      authorization,
    },
  }),
});

export const fetchOrderFx = attachSession({
  effect: fetchOrderApiFx,
  mapParams: (orderId: number, authorization) => ({
    params: {
      orderId: orderId,
    },
    headers: {
      authorization,
    },
  }),
});

export const acceptOrderFx = attachSession({
  effect: acceptOrderApiFx,
  mapParams: ({orderId, prepareTime, isOptimal}: AcceptParams, authorization) => ({
    params: {
      orderId,
    },
    headers: {
      authorization,
    },
    prepareTime: {
      isOptimal,
      minutes: prepareTime,
    },
  }),
});

export const updateOrderFx = attachSession({
  effect: updateOrderApiFx,
  mapParams: (updateParams: UpdateParams, authorization) => ({
    params: {
      orderId: updateParams.orderId,
    },
    headers: {
      authorization,
    },
    isReady: updateParams.isReady,
  }),
});

export const finishOrderFx = attachSession({
  effect: finishOrderApiFx,
  mapParams: (updateParams: FinishParams, authorization) => ({
    params: {
      orderId: updateParams.orderId,
    },
    headers: {
      authorization,
    },
    isDeparted: updateParams.isDeparted,
  }),
});

export const fetchSuggestedCookingTimesFx = attachSession({
  effect: createEffect(fetchSuggestedCookingTimes),
  mapParams: (orderId: number, authorization) => ({
    params: {
      orderId,
    },
    headers: {
      authorization,
    },
  }),
});

const playSoundFx = createEffect(() => {
  return audioObj.play();
});

const playChangeStatusSoundFx = createEffect(() => {
  return changeStatusAudioObj.play();
});

const synchronizeOrdersListFx = createEffect(
  ({updatedOrder, orderList, pickedBranches, selectedGroup}: SynchronizeOrderListConfig): OrdersContract => {
    const branchId = updatedOrder.data.order.store.branch.id;
    const currentGroup = updatedOrder.data.groups.current;
    const orders = {...orderList};

    // Игнорируем апдейт если заказ пришел на филиал, который не включен в селекте.
    if (!pickedBranches.includes(branchId)) return orderList;

    const foundSection = orders.groups.find(
      (groups) =>
        groups.orders.find((order) => order.id === updatedOrder.data.order.id)?.id === updatedOrder.data.order.id,
    );

    // Флоу отмененного/завершенного заказа.
    if (updatedOrder.data.event === 'del' && foundSection) {
      const removeFromSection = orders.groups.indexOf(foundSection);

      const removeOrder = foundSection.orders.findIndex((order) => order.id === updatedOrder.data.order.id);

      orders.groups[removeFromSection].orders.splice(removeOrder, 1);

      const ordersIds = orders.groups.map((group) => group.orders.map((order) => order.id)).flat();

      if (ordersIds.length < 1) {
        orders.count = 0;
      }

      return orders;
    }

    // Если заказ уже имеется в списке, но перешел в другую секцию, нужно удалить текущий из списка и добавить апдейт.
    if (foundSection && foundSection?.alias !== currentGroup.section.alias) {
      const removeFromSection = orders.groups.indexOf(foundSection);

      const removeOrder = foundSection.orders.findIndex((order) => order.id === updatedOrder.data.order.id);

      orders.groups[removeFromSection].orders.splice(removeOrder, 1);
    }

    // Флоу перехода заказа из текущего списка в другой таб.
    if (selectedGroup.alias !== currentGroup.alias) {
      const ordersIds = orders.groups.map((group) => group.orders.map((order) => order.id)).flat();

      if (ordersIds.length < 1) {
        orders.count = 0;
      }

      if (!ordersIds.includes(updatedOrder.data.order.id)) return orders;

      if (foundSection) {
        const removeFromSection = orders.groups.indexOf(foundSection);
        const removeOrder = foundSection.orders.findIndex((order) => order.id === updatedOrder.data.order.id);

        orders.groups[removeFromSection].orders.splice(removeOrder, 1);

        const orderList = orders.groups.map((group) => group.orders.map((order) => order.id)).flat();

        if (orderList.length < 1) {
          orders.count = 0;
        }

        return orders;
      }
    }

    const currentSection = orders.groups.find((section) => section.alias === currentGroup.section.alias);

    if (!currentSection) return orderList;

    const currentOrders: Order[] = currentSection.orders;
    const indexOfOrder = currentOrders.findIndex((order) => order.id === updatedOrder.data.order.id);

    if (indexOfOrder === -1) {
      currentOrders.unshift(updatedOrder.data.order);
      orders.count++;
      return orders;
    }

    // Обновление заказа в списке заказов.
    currentOrders[indexOfOrder] = updatedOrder.data.order;

    return orders;
  },
);

sample({
  //@ts-ignore
  clock: orderUpdated,
  source: {
    orderList: $orders,
    selectedGroup: $selectedGroup,
    pickedBranches: $pickedBranches,
  },
  fn: ({orderList, selectedGroup, pickedBranches}, updatedOrder) => {
    return {
      orderList,
      selectedGroup,
      pickedBranches,
      updatedOrder,
    };
  },
  target: synchronizeOrdersListFx,
});

sample({
  clock: ordersGate.open,
  target: fetchOrderGroupsFx,
});

sample({
  source: $attachedBranches,
  fn: (branches) => {
    if (!branches) return [];
    if (branches.length === 0) return [];

    return branches.map((branch) => {
      return branch.value;
    });
  },
  target: branchesChanged,
});

const {tick: newOrderSoundTick} = interval({
  timeout: 1500,
  start: startNewOrderSounds,
  stop: stopNewOrderSounds,
});

sample({
  clock: $newOrders,
  filter: (newOrders) => {
    if (!newOrders) return true;

    return !newOrders.length;
  },
  target: stopNewOrderSounds,
});

sample({
  source: $newOrders,
  filter: (newOrders) => {
    if (!newOrders) return false;

    return Boolean(newOrders.length);
  },
  target: [playSoundFx, startNewOrderSounds],
});

sample({
  clock: newOrderSoundTick,
  target: playSoundFx,
});

sample({
  clock: orderUpdated,
  filter: (order) => {
    if (order.data.groups.current.alias !== 'new') return false;
    if (order.data.order.status.code !== 'merchantPreparingOrder') return false;

    return true;
  },
  target: playChangeStatusSoundFx,
});

sample({
  clock: groupSelected,
  source: guard({
    clock: $orderGroups,
    filter: (orderGroups): orderGroups is OrderGroup[] => orderGroups !== null,
  }),
  fn: (groups, selectedGroup) => {
    const group = groups.find((group) => selectedGroup === group.alias);
    if (!group) return null;

    return group;
  },
  target: $selectedGroup,
});

sample({
  source: guard({
    clock: $orderParams.updates,
    source: $orderParams,
    filter: (ordersParams): ordersParams is OrdersParams => ordersParams !== null,
  }),
  target: fetchOrdersFx,
});

sample({
  source: guard({
    clock: [orderSelected, goToOrdersPageFx.doneData],
    source: $selectedOrderId,
    filter: (orderId): orderId is number => orderId !== null,
  }),
  target: [fetchOrderFx, fetchSuggestedCookingTimesFx],
});

sample({
  source: guard({
    clock: prepareTimeSubmitted,
    source: {
      orderId: $selectedOrderId,
      prepareTime: $prepareTime,
      isOptimal: $isOptimalTimeChosen,
    },
    filter: (acceptParams): acceptParams is AcceptParams => {
      if (acceptParams.prepareTime === 0) return false;

      return acceptParams !== null;
    },
  }),
  target: acceptOrderFx,
});

sample({
  source: guard({
    clock: orderPrepared,
    source: $selectedOrderId,
    filter: (orderId): orderId is number => orderId !== null,
  }),
  fn: (orderId) => {
    return {
      orderId,
      isReady: true,
    };
  },
  target: updateOrderFx,
});

sample({
  source: guard({
    clock: orderFinished,
    source: $selectedOrderId,
    filter: (orderId): orderId is number => orderId !== null,
  }),
  fn: (orderId) => {
    return {
      orderId,
      isDeparted: true,
    };
  },
  target: finishOrderFx,
});

sample({
  clock: notificationReceived,
  target: fetchStoresFx,
});

sample({
  clock: orderUpdated,
  source: {
    pickedBranches: $pickedBranches,
    groups: $orderGroups,
    selectedGroup: $selectedGroup,
  },
  fn: ({groups, selectedGroup}, updatedOrder) => {
    if (!groups) return null;
    if (!selectedGroup) return null;

    const groupTriggers = {};

    for (const group of groups) {
      groupTriggers[group.alias] = false;
    }

    if (selectedGroup.alias === updatedOrder.data.groups.current.alias) {
      return groupTriggers;
    }

    groupTriggers[updatedOrder.data.groups.current.alias] = true;

    return groupTriggers;
  },
  target: $socketUpdates,
});

sample({
  clock: [acceptModalClosed, fetchSuggestedCookingTimesFx.done],
  source: $suggestedCookingTimes,
  fn: (suggestedTimes) => {
    const suggestedTime = suggestedTimes.find(({isOptimal}) => isOptimal);

    if (!suggestedTime) return null;

    return suggestedTime.value;
  },
  target: $prepareTime,
});

sample({
  clock: orderUpdated,
  filter: (order) => order.data.groups.current.alias === 'new' || order.data.groups.current.alias === 'upd',
  fn: ({data}) => data.order,
  target: newOrderChanged,
});

sample({
  clock: fetchOrdersFx.doneData,
  filter: (data) => data.config.params.groupAlias === 'new',
  fn: ({data}) => {
    const attentionGroup = data.groups.find((item) => item.alias === 'attention');

    if (!attentionGroup) return null;
    return attentionGroup.orders.filter((order) => !order.isViewed && order.status.code === 'new');
  },
  target: $newOrders,
});

sample({
  clock: fetchOrderFx.doneData,
  filter: ({data}) => data.status.code === 'new',
  fn: ({data}) => ({
    ...data,
    isViewed: true,
  }),
  target: newOrderChanged,
});

$orderGroups.on(fetchOrderGroupsFx.doneData, (_, {data}) => data);

$socketUpdates
  .on(fetchOrderGroupsFx.doneData, (_, {data}) => {
    const groupTriggers = {};

    for (const group of data) {
      groupTriggers[group.alias] = false;
    }

    return groupTriggers;
  })
  .on(groupSelected, (triggers, selectedGroup) => {
    const groupTriggers = {...triggers};
    for (const trigger in groupTriggers) {
      if (trigger === selectedGroup) {
        groupTriggers[selectedGroup] = false;
      }
    }

    return groupTriggers;
  });

$selectedGroup.on(fetchOrderGroupsFx.doneData, (_, {data}) => data[0]);

$pickedBranches.on(branchesChanged, (_, branchesIds) => branchesIds);

$orders.on(fetchOrdersFx.doneData, (_, {data}) => data).on(synchronizeOrdersListFx.doneData, (orders, data) => data);

$isOrdersListView.on(ordersViewOpened, () => true).on(searchViewOpened, () => false);

$selectedOrderId.on(orderSelected, (_, orderId) => orderId).on(groupSelected, () => null);

$selectedOrder
  .on(fetchOrderFx.doneData, (_, {data}) => data)
  .on(orderUpdated, (selectedOrder, updatedOrder) => {
    if (!selectedOrder) return null;
    if (selectedOrder.id !== updatedOrder.data.order.id) return null;
    if (updatedOrder.data.event === 'del') return null;

    return updatedOrder.data.order;
  })
  .on(groupSelected, () => null);

$isAcceptModalOpened
  .on(acceptModalClosed, () => false)
  .on(acceptModalOpened, () => true)
  .on(acceptOrderFx.done, () => false);

$prepareTime.on(prepareTimeChanged, (_, time) => time);

$suggestedCookingTimes.on(fetchSuggestedCookingTimesFx.doneData, (_, {data}) => data).on(ordersGate.close, () => []);

$notification.on(notificationReceived, (_, notification) => notification);

$newOrders.on(newOrderChanged, (prevOrders, newOrder) => {
  if (!prevOrders) return [newOrder];
  const uniqueOrders = prevOrders.filter((order) => order.id !== newOrder.id);

  return [...uniqueOrders, newOrder].filter((order) => !order.isViewed && order.status.code === 'new');
});
