import {Props} from './types';
import styles from './styles.module.scss';
import {FC, useMemo, useState} from 'react';
import {compileCdnUrl} from '@shared/lib/compile-cdn-url';
import noCoverMenu from '../../../assests/no-cover-menu.sprite.svg';
import {Icon, Image, Ratio, Loader, Toggle, Typography} from '@express-24/theseus-ui';
import {useUnit} from 'effector-react';
import {$menuLoadingStateByIds} from '../../../../model';

export const MenuCell: FC<Props> = ({
  section,
  selected,
  dragging,
  isSubmenu,
  isMenuNested,
  isSortActive,
  onSwitchToggle,
}) => {
  const {menuLoadingStateByIds} = useUnit({menuLoadingStateByIds: $menuLoadingStateByIds});

  const {id, name, cover, isEnabled} = section;

  const isCoversEmpty = !cover;
  const [brokenCovers, setBrokenCovers] = useState<Record<number, boolean>>({});

  const handleCoverError = () =>
    setBrokenCovers({
      ...brokenCovers,
      [id]: true,
    });

  const prefixView = useMemo(() => {
    if (isSortActive) {
      return (
        <div className={styles.DragHandler}>
          <Icon size={24}
            name="menu-burger"
          />
        </div>
      );
    }

    if (menuLoadingStateByIds[id]) {
      return (
        <div className={styles.SwitchLoader}>
          <Loader size={24}
            strokeWidth={3}
          />
        </div>
      );
    }

    if (isMenuNested) return null;

    return (
      <Toggle
        checked={isEnabled}
        onChange={({currentTarget}) =>
          onSwitchToggle({
            isSubmenu,
            menuId: id,
            checked: currentTarget.checked,
          })
        }
      />
    );
  }, [isSortActive, isMenuNested, isEnabled, menuLoadingStateByIds]);

  const postfixView = useMemo(() => {
    if (!isMenuNested) return null;

    if (isCoversEmpty || brokenCovers[id]) {
      return (
        <div key={cover}
          className={styles.CellCover}
        >
          <Ratio ratio={[68, 48]}>
            <Image fit="cover"
              src={noCoverMenu}
            />
          </Ratio>
        </div>
      );
    }

    return (
      <div key={cover}
        className={styles.CellCover}
      >
        <Ratio ratio={[48, 48]}>
          <Image fit="cover"
            onError={handleCoverError}
            src={compileCdnUrl(cover, 300)}
          />
        </Ratio>
      </div>
    );
  }, [cover, brokenCovers, isCoversEmpty, isMenuNested]);

  return (
    <div
      key={id}
      className={styles.Cell}
      data-is-selected={selected}
      data-is-dragging={dragging}
      data-is-nested={isMenuNested}
    >
      <div onClick={(event) => event.stopPropagation()}>{prefixView}</div>

      <Typography level="text"
        lineClamp={1}
        className={styles.CellTitle}
      >
        {name}
      </Typography>

      {postfixView}
    </div>
  );
};
