import React, {FC} from 'react';

type ProtectedBlockProps = {
  access: number[];
  roleId: number | null;
};

export const ProtectedBlock: FC<ProtectedBlockProps> = ({access, roleId, children}) => {
  if (!roleId) return null;

  if (access.includes(roleId)) {
    return <>{children}</>;
  }

  return null;
};
