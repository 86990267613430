import {createEffect, sample} from 'effector';
import {closeSession} from '@api/v2';
import {sessionClosed} from '@entities/session';
import {userRefined} from '@entities/user';

export const closeSessionFx = createEffect(closeSession);

sample({
  clock: closeSessionFx.done,
  target: [sessionClosed, userRefined],
});
