import {Provider} from 'effector-react';
import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {history} from '@shared/history';
import {App} from './app';
import {appInitialized} from './model';
import {allSettled, fork} from 'effector';
import i18n from 'i18next';
import 'shared/lib/internationalization';
import {localeRu, localeUz} from '@shared/lib/internationalization';
import {LocaleProvider} from '@express-24/theseus-ui';

const scope = fork();
const root = document.getElementById('root');
const app = (
  <Provider value={scope}>
    <LocaleProvider value={i18n.language === 'ru' ? localeRu : localeUz}>
      <Router history={history}>
        <App />
      </Router>
    </LocaleProvider>
  </Provider>
);

allSettled(appInitialized, {
  scope,
}).then(() => {
  ReactDOM.render(app, root);
});

if (module.hot) {
  module.hot.accept();
}
