import dayjs from 'dayjs';
import {useUnit} from 'effector-react';
import {useEffect, useMemo} from 'react';
import styles from './styles.module.scss';
import * as model from '../../../../model';
import {ActivityModal} from '@widgets/activity-modal/ui';
import {usePreventScroll} from '@shared/lib/use-prevent-scroll';
import {Button, Drawer, Icon, Input, Toggle, Typography} from '@express-24/theseus-ui';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export const OfferModal = () => {
  const {
    handleOfferPriceChange,
    handleOfferDetailsClose,
    handleOfferDetailsSubmit,
    handleActivityDialogOpen,
    handleActivityDialogClose,
    handleDeactivateOfferSubmit,

    offer,
    offerBranches,
    selectedOfferBranchId,

    isDrawerOpen,
    isActivityDialogOpen,
    isOfferUpdatePending,
  } = useUnit({
    handleOfferPriceChange: model.offerPriceChanged,
    handleActivityDialogOpen: model.activityDialogOpened,
    handleOfferDetailsSubmit: model.offerDetailsSubmitted,
    handleActivityDialogClose: model.activityDialogClosed,
    handleOfferDetailsClose: model.offerDetailsDrawerClosed,
    handleDeactivateOfferSubmit: model.deactivateOfferSubmitted,

    offer: model.$selectedOffer,
    offerBranches: model.$formattedOfferBranches,
    selectedOfferBranchId: model.$selectedOfferBranchId,

    isDrawerOpen: model.$isEditOfferDrawerOpen,
    isOfferUpdatePending: model.$isOfferUpdatePending,
    isActivityDialogOpen: model.$isActivityDialogOpened,
  });
  const {t} = useTranslation();
  const [blockScroll, allowScroll] = usePreventScroll();

  useEffect(() => {
    if (isDrawerOpen) return blockScroll();

    return allowScroll();
  }, [isDrawerOpen]);

  const handleOfferDisable = (selectedTime: string) => {
    if (!selectedOfferBranchId) return;

    handleDeactivateOfferSubmit({
      checked: false,
      id: selectedOfferBranchId,
      unavailableTill: selectedTime,
    });
  };

  const branchList = useMemo(() => {
    if (!offerBranches) return null;

    const branchesList: Array<model.OfferBranch> = [];

    for (const branch in offerBranches) {
      branchesList.push(offerBranches[branch]);
    }

    return branchesList;
  }, [offerBranches]);

  const handleActivityChange = ({id, checked}: {id: number; checked: boolean}) => {
    if (!checked) {
      return handleActivityDialogOpen(id);
    }

    return handleDeactivateOfferSubmit({
      id,
      checked,
    });
  };

  const branchesListView = useMemo(() => {
    if (!branchList) return;

    return (
      <div className={styles.BranchesList}>
        {branchList.map(({id, name, availability}) => {
          const date = availability.unavailableTill && dayjs(availability.unavailableTill).format('DD.MM.YY, hh:mm');

          const isValidDate = date && dayjs(date).isValid();

          return (
            <div key={id}
              className={styles.BranchesListCell}
            >
              <Typography level="text">{name}</Typography>
              {isValidDate && (
                <Typography align="right"
                  level="sub-text"
                  kind="secondary"
                >
                  {t('off_date', {date})}
                </Typography>
              )}
              <Toggle
                onChange={({target}) => {
                  handleActivityChange({
                    id,
                    checked: target.checked,
                  });
                }}
                checked={availability.isAvailable}
              />
              {availability.isAvailable}
            </div>
          );
        })}
      </div>
    );
  }, [branchList, i18n.language]);

  if (!offer) return null;

  const ACTIVITY_OPTIONS = [
    {
      value: 'next_60m',
      name: t('for_minutes', {minutes: 60}),
    },
    {
      value: 'next_period',
      name: t('next_period'),
    },
    {
      value: 'unknown',
      name: t('undefined_period'),
      caption: t('disable_in_client'),
    },
  ];

  return (
    <Drawer width="500px"
      visible={isDrawerOpen}
      onOutsideClick={handleOfferDetailsClose}
    >
      <div className={styles.Container}>
        <div className={styles.Header}>
          <Typography semibold
            level="h4"
          >
            {t('menu.edit_variations')}
          </Typography>
          <div className={styles.CloseButton}
            onClick={handleOfferDetailsClose}
          >
            <Icon size={20}
              name="cross"
            />
          </div>
        </div>
        <div className={styles.Content}>
          <Input fluid
            label={t('price')}
            value={offer.price.sold}
            onChange={handleOfferPriceChange}
          />
          <div>
            <Typography semibold
              level="h4"
              className={styles.BranchTitle}
            >
              {t('menu.activity_by_branches')}
            </Typography>
            {branchesListView}
          </div>
        </div>
        <div className={styles.Footer}>
          <Button fluid
            size="large"
            loading={isOfferUpdatePending}
            onClick={handleOfferDetailsSubmit}
          >
            {t('actions.save_changes')}
          </Button>
        </div>
      </div>

      <ActivityModal
        onSubmit={handleOfferDisable}
        loading={isOfferUpdatePending}
        visible={isActivityDialogOpen}
        options={ACTIVITY_OPTIONS}
        onClose={handleActivityDialogClose}
      />
    </Drawer>
  );
};
