import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import error from '../assets/error.png';
import styles from './page.module.scss';
import {Button} from '@express-24/theseus-ui';
import {Link} from 'react-router-dom';

export const NotFound: FC = () => {
  const {t} = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.Info}>
        <img src={error}
          alt="error"
        />
        <div className={styles.Title}>{t('not_found.page_not_found')}</div>
        <div className={styles.SubTitle}>{t('not_found.tip')}</div>
        <div className={styles.Button}>
          <Link to="/orders">
            <Button size="large">{t('not_found.to_orders')}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
