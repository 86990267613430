import {useUnit} from 'effector-react';
import styles from './styles.module.scss';
import * as model from '../../../../model';
import {StopListProduct} from '../../molecules';
import {useEffect, useMemo, useState} from 'react';
import {Icon, Typography} from '@express-24/theseus-ui';
import {useTranslation} from 'react-i18next';

export const StopList = () => {
  const {
    stopList,

    isStopListLoading,
  } = useUnit({
    stopList: model.$stopList,

    isStopListLoading: model.$isStopListPending,
  });
  const {t} = useTranslation();
  const [expandedList, setExpandedList] = useState<Record<number, boolean>>({});

  useEffect(() => {
    if (!stopList) return;

    const result = {};

    for (const element of stopList.sections) {
      result[element.id] = true;
    }

    setExpandedList(result);
  }, [stopList]);

  const loadingView = useMemo(() => {
    if (!isStopListLoading) return;

    return <div className={styles.FallbackLoader} />;
  }, [isStopListLoading]);

  const handleListExpand = (id: number) => {
    setExpandedList({
      ...expandedList,
      [id]: !expandedList[id],
    });
  };

  if (!stopList) return null;

  return (
    <div className={styles.Container}>
      {loadingView}

      <div className={styles.Title}>
        <Typography semibold
          level="h4"
        >
          {t('menu.stop_list')}
        </Typography>
      </div>
      <div className={styles.SectionsList}>
        {stopList.sections.map(({id, name, products}) => (
          <div key={id}
            className={styles.Section}
          >
            <div onClick={() => handleListExpand(id)}
              className={styles.SectionHeader}
            >
              <Typography semibold
                level="h5"
              >
                {name}
              </Typography>
              <div className={styles.Chevron}
                data-is-expanded={expandedList[id]}
              >
                <Icon size={20}
                  name="chevron-down"
                />
              </div>
            </div>
            <div className={styles.Content}
              data-is-open={expandedList[id]}
            >
              <div className={styles.ProductsWrapper}>
                {products.map((product) => (
                  <StopListProduct
                    key={product.id}
                    //@ts-ignore
                    product={product}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
