import {combine, createEffect, createEvent, createStore, sample} from 'effector';
import {
  fetchOrder,
  fetchProductDetails,
  fetchMenu,
  sendIncidentProducts,
  OrderContract,
  OrderProducts,
  MenuContract,
  SendIncidentProductsParams,
  ProductDetailsCombinations,
  ProductDetailsCombination,
} from '@api/v2';
import {attachSession} from '@entities/session';
import {
  CounterProductForReplacement,
  FilteredOrderProducts,
  IncidentStoreParams,
  ModifiedProductOrders,
  ModifyProductsOrderParams,
  ProductDetailsParams,
  ProductsForReplacement,
  StoreMenuParams,
  SubMenuParams,
  UpdateOrderParams,
} from './model.types';
import {debounce} from 'patronum/debounce';
import {productDialogModel} from '@widgets/product-dialog';
import {throttle} from 'patronum/throttle';
import {ProductCombination, ProductForReplacement} from '@widgets/product-dialog/model/model.types';
import {goToOrdersPageFx} from '../../../model';
import {$isDialogOpen} from '@widgets/product-dialog/model';
import {orderUpdated} from '@features/live-orders-update';

const DELAY_PER_SEARCH_MS = 500;
const DELAY_TAB_CLICK = 500;

export const fetchStoreMenuApiFx = createEffect(fetchMenu);
export const fetchSubMenuApiFx = createEffect(fetchMenu);
export const fetchSearchMenuApiFx = createEffect(fetchMenu);
export const fetchIncidentOrderApiFx = createEffect(fetchOrder);
export const fetchProductDetailsApiFx = createEffect(fetchProductDetails);
export const sendIncidentProductsApiFx = createEffect(sendIncidentProducts);

export const incidentOrderModified = createEvent<UpdateOrderParams>();
export const pageMounted = createEvent<number>();
export const pageUnmounted = createEvent();
export const productsOrderModified = createEvent<ModifyProductsOrderParams>();
export const drawerOpened = createEvent<StoreMenuParams>();
export const subMenuItemClicked = createEvent<SubMenuParams>();
export const drawerClosed = createEvent();
export const goneToSubMenu = createEvent();
export const searchProductInputUpdated = createEvent<StoreMenuParams>();
export const productForReplacementAdded = createEvent<ProductForReplacement>();
export const productForReplacementDeleted = createEvent<number>();
export const counterChangedProductForReplacement = createEvent<CounterProductForReplacement>();
export const searchProductUnmounted = createEvent();
export const productCardClicked = createEvent<ProductDetailsParams>();
export const activeMenuTypeUpdated = createEvent<string>();
export const incidentChangesConfirmed = createEvent<SendIncidentProductsParams>();
export const orderCanceledAlertClicked = createEvent();
export const addProductClicked = createEvent<ProductForReplacement>();

export const $incidentOrder = createStore<OrderContract | null>(null);
export const $productsOrder = createStore<FilteredOrderProducts | null>(null);
export const $modifiedProductsOrder = createStore<ModifiedProductOrders | null>(null);
export const $isDrawerOpened = createStore<boolean>(false);
export const $storeMenu = createStore<MenuContract | null>(null);
export const $subMenu = createStore<MenuContract | null>(null);
export const $searchProductInput = createStore<StoreMenuParams | null>(null);
export const $searchProductData = createStore<MenuContract | null>(null);
export const $productsForReplacement = createStore<ProductsForReplacement | null>(null);
export const $activeMenuType = createStore('');
export const $incidentStoreParams = createStore<IncidentStoreParams | null>(null);
export const $isAlertCanceledOpen = createStore<boolean>(false);
export const $productCombinations = createStore<ProductCombination[] | null>(null);

export const fetchStoreMenuFx = attachSession({
  effect: fetchStoreMenuApiFx,
  mapParams: ({params, storeId}: StoreMenuParams, authorization) => ({
    params: {
      branchId: params.branchId,
      search: params.search,
      isAvailable: true,
    },
    path: {
      storeId,
    },
    headers: {
      authorization,
    },
  }),
});

export const fetchSubMenuFx = attachSession({
  effect: fetchSubMenuApiFx,
  mapParams: ({params, storeId}: SubMenuParams, authorization) => ({
    params: {
      branchId: params.branchId,
      parentId: params.parentId,
      search: params.search,
      isAvailable: true,
    },
    path: {
      storeId,
    },
    headers: {
      authorization,
    },
  }),
});

export const fetchSearchMenuFx = attachSession({
  effect: fetchSearchMenuApiFx,
  mapParams: ({params, storeId}: StoreMenuParams, authorization) => ({
    params: {
      branchId: params.branchId,
      search: params.search,
    },
    path: {
      storeId,
    },
    headers: {
      authorization,
    },
  }),
});

export const fetchIncidentOrderFx = attachSession({
  effect: fetchIncidentOrderApiFx,
  mapParams: (orderId: number, authorization) => ({
    params: {
      orderId: orderId,
    },
    headers: {
      authorization,
    },
  }),
});

export const fetchProductDetailsFx = attachSession({
  effect: fetchProductDetailsApiFx,
  mapParams: ({productId, storeId, branchId, byButton}: ProductDetailsParams, authorization) => ({
    path: {
      storeId,
      productId,
    },
    params: {branchId, byButton},
    headers: {
      authorization,
    },
  }),
});

export const sendIncidentProductsFx = attachSession({
  effect: sendIncidentProductsApiFx,
  mapParams: ({orderId, body}: SendIncidentProductsParams, authorization) => ({
    orderId,
    body,
    headers: {
      authorization,
    },
  }),
});

throttle({
  source: activeMenuTypeUpdated,
  timeout: DELAY_TAB_CLICK,
  target: $activeMenuType,
});

sample({
  clock: drawerOpened,
  target: fetchStoreMenuFx,
});

sample({
  clock: subMenuItemClicked,
  target: fetchSubMenuFx,
});

sample({
  clock: debounce({
    source: searchProductInputUpdated,
    timeout: DELAY_PER_SEARCH_MS,
  }),
  filter: (config) => {
    if (!config.params.search) return false;

    return !!config.params.search.length;
  },
  target: fetchSearchMenuFx,
});

sample({
  clock: pageMounted,
  target: fetchIncidentOrderFx,
});

sample({
  clock: productCardClicked,
  target: fetchProductDetailsFx,
});

sample({
  clock: fetchIncidentOrderFx.doneData,
  fn: ({data}) => {
    return {
      storeId: data.store.id,
      branchId: data.store.branch.id,
    };
  },
  target: $incidentStoreParams,
});

sample({
  clock: fetchProductDetailsFx.doneData,
  filter: ({config, data}) => {
    return !config.params.byButton || data.combinations.length > 0;
  },
  fn: ({data}) => data,
  target: productDialogModel.productChanged,
});

sample({
  clock: fetchProductDetailsFx.doneData,
  filter: ({config, data}) => config.params.byButton && data.combinations.length === 0,
  fn: ({data}) => ({...data, count: 1}),
  target: productForReplacementAdded,
});

sample({
  clock: fetchProductDetailsFx.doneData,
  filter: ({config, data}) => config.params.byButton && data.combinations.length === 0,
  fn: ({data}) => ({id: data.id, quantity: 1}),
  target: productsOrderModified,
});

sample({
  clock: fetchProductDetailsFx.doneData,
  fn: ({data}) => {
    const combinations: ProductCombination[] = [];

    data.combinations.forEach((combination: ProductDetailsCombination) => {
      const properties = {};

      combination.properties.forEach((property) => {
        properties[property.propertyId] = property.optionId;
      });

      combinations.push(properties);
    });

    return combinations;
  },
  target: $productCombinations,
});

sample({
  clock: incidentChangesConfirmed,
  target: sendIncidentProductsFx,
});

sample({
  clock: $incidentOrder,
  filter: (incidentOrder) => Boolean(incidentOrder && incidentOrder.status.code === 'canceled'),
  fn: () => true,
  target: $isAlertCanceledOpen,
});

sample({
  clock: [sendIncidentProductsFx.doneData, orderCanceledAlertClicked],
  target: goToOrdersPageFx,
});

sample({
  clock: productsOrderModified,
  target: productDialogModel.dialogClosed,
});

sample({
  clock: addProductClicked,
  target: productForReplacementAdded,
});

sample({
  clock: [addProductClicked, counterChangedProductForReplacement],
  fn: (productData) => ({
    id: productData.id,
    quantity: productData.count,
    initialQuantity: 0,
  }),
  target: productsOrderModified,
});

sample({
  clock: [addProductClicked, counterChangedProductForReplacement],
  source: $isDialogOpen,
  filter: (isDialogOpen) => isDialogOpen,
  target: productDialogModel.dialogClosed,
});

$incidentOrder
  .on(fetchIncidentOrderFx.doneData, (_, {data}) => data)
  .on(incidentOrderModified, (prevModifiedOrder, {productId, quantity}) => {
    if (!prevModifiedOrder) return;

    const modifiedProducts = prevModifiedOrder.products.map((product) => {
      if (product.id === productId) {
        return {
          ...product,
          quantity,
        };
      } else {
        return product;
      }
    });

    return {
      ...prevModifiedOrder,
      products: modifiedProducts,
    };
  })
  .on(orderUpdated, (selectedOrder, updatedOrder) => {
    if (!selectedOrder) return null;
    if (selectedOrder.id !== updatedOrder.data.order.id) return null;
    if (updatedOrder.data.event === 'del') return null;

    return updatedOrder.data.order;
  })
  .reset(pageUnmounted);

$productsOrder
  .on(fetchIncidentOrderFx.doneData, (_, {data}) => {
    return {
      products: [...data.products],
      currency: data.payment.currency,
    };
  })
  .reset(pageUnmounted);

$modifiedProductsOrder
  .on(productsOrderModified, (prevProducts, {initialQuantity, ...newProduct}) => {
    if (!prevProducts) return [newProduct];

    const filteredProducts = prevProducts.filter((product) => product.id !== newProduct.id);

    if (newProduct.quantity === initialQuantity) return filteredProducts;

    filteredProducts.push(newProduct);

    return filteredProducts;
  })
  .on(productForReplacementDeleted, (prevProducts, productId) => {
    if (!prevProducts) return;

    return prevProducts.filter((item) => item.id !== productId);
  })
  .reset(pageUnmounted);

$isDrawerOpened
  .on(drawerOpened, () => true)
  .on(drawerClosed, () => false)
  .reset(pageUnmounted);

$storeMenu.on(fetchStoreMenuApiFx.doneData, (_, {data}) => data);
$subMenu.on(fetchSubMenuApiFx.doneData, (_, {data}) => data).reset(goneToSubMenu);

$searchProductInput.on(searchProductInputUpdated, (_, newValue) => newValue).reset(searchProductUnmounted);
$searchProductData.on(fetchSearchMenuApiFx.doneData, (_, {data}) => data).reset(searchProductUnmounted);

$productsForReplacement
  .on(productForReplacementAdded, (products, newProduct) => {
    if (!products) return [newProduct];

    return [...products, newProduct];
  })
  .on(counterChangedProductForReplacement, (products, productData) => {
    if (!products) return;

    const currentProduct = products.find((item) => item.id === productData.id);

    if (currentProduct) {
      const filteredProducts = products.filter((item) => item.id !== productData.id);
      if (!productData.count) return filteredProducts;

      return [...filteredProducts, {...currentProduct, count: productData.count}];
    }
  })
  .on(productForReplacementDeleted, (products, productId) => {
    if (!products) return;

    return products.filter((item) => item.id !== productId);
  })
  .reset(pageUnmounted);

$activeMenuType.on($storeMenu, (_, menu) => {
  if (!menu) return '';

  return String(menu.sections[0].id);
});

export const $isConfirmChangesBtnDisabled = combine(
  $productsOrder,
  $incidentOrder,
  fetchIncidentOrderFx.pending,
  (productsOrder, incidentOrder, isIncidentOrderPending) => {
    if (!productsOrder) return true;

    const areOrdersChanged = JSON.stringify(productsOrder.products) !== JSON.stringify(incidentOrder?.products);

    if (!areOrdersChanged) return true;
    if (isIncidentOrderPending) return true;

    return false;
  },
);

export const $sourceMenuData = combine($storeMenu, $subMenu, (storeMenu, subMenu) => {
  return subMenu || storeMenu;
});

$isAlertCanceledOpen.reset(pageUnmounted);
